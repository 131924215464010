import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { Link } from "react-router-dom"
import logo from "../../assets/images/logos.png"
import fav from "../../assets/images/fav.png"

const Sidebar = props => {
  return (
    <React.Fragment>
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link className="logo logo-dark">
          <span className="logo-sm">
            <img src={fav} alt="" />
            {/* <b style={{color:'black'}}>MEP</b> */}
          </span>
          <span className="logo-lg">
            <img src={logo} alt="" />

            {/* <b style={{color:'black'}}>MAVITECH</b> */}
          </span>
        </Link>

        <Link className="logo logo-light">
          <span className="logo-sm">
            <img src={fav} alt="" />
            {/* <b style={{color:'black'}}>MEP</b> */}
          </span>
          <span className="logo-lg">
            <img src={logo} alt="" />
            {/* <b style={{color:'black'}}>MAVITECH</b> */}
          </span>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
      </div>
      <div className="sidebar-background"></div>
    </div>
  </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
