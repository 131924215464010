import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  CardFooter,
  Form,
  Nav,
  Table,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import adobephotoshop from "../../assets/images/users/avatar-7.jpg"
import ls from '../../assets/images/med.png'
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import axios from "axios"
import { imgUrl, baseurl } from "Baseurls"
import ReactPaginate from "react-paginate"

function DoctorView() {
  const history = useHistory()
  const [tokenscount, settokenscount] = useState([])
  const [doctor, setdoctor] = useState([])
  const [doctorsub, setdoctorsub] = useState([])
  const [doctorconslt, setdoctorconslt] = useState([])
  const [activeTab1, setactiveTab1] = useState("1")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const doctorID = sessionStorage.getItem('docid')

  const GetAllDoctors = () => {
    var token = datas
    const bodydata = {
      id: doctorID
    }
    axios
      .post(baseurl + 'doctor/getdoctorbyid', bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.doctor)
        setdoctorsub(res.data.subscribes)
        setdoctorconslt(res.data.booking)
      })
  }
  const GetAllDoctortokens = () => {
    var token = datas
    const bodydata = {
      id: doctorID
    }
    axios
      .post(baseurl + 'getsearchcountbydoctorid', bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        settokenscount(res.data.docotorTokens)
      })
  }

  useEffect(() => {
    GetAllDoctors()
    GetAllDoctortokens()
  }, [])

  const [locationName, setLocationName] = useState('');

  useEffect(() => {
    const apiKey = 'AIzaSyBAgjZGzhUBDznc-wI5eGRHyjVTfENnLSs';
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${doctor.latitude},${doctor.longitude}&key=${apiKey}`;

    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data.results.length > 0) {
          setLocationName(response.data.results[0].formatted_address);
        } else {
          setLocationName('Location not found');
        }
      })
      .catch((error) => {
        console.error('Error fetching location:', error);
        setLocationName('Error fetching location');
      });
  }, [doctor]);

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = doctorsub.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(doctorsub.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [listPerPage1] = useState(5)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = doctorconslt.slice(pagesVisited1, pagesVisited1 + listPerPage1)
  const pageCount1 = Math.ceil(doctorconslt.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const redirectdata1 = (data) => {
    sessionStorage.setItem('subid', data.planId)
    history.push('/ViewSubscription')
  }
  const Bookingrdct = (data) => {
    sessionStorage.setItem('bookingid', data._id)
    history.push("/ViewBooking")
}


  return (
    <div>
      {" "}
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Allude" breadcrumbItem="Doctor Profile" />
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>{" "}
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="12">
                        <div className="text-primary p-3 mb-5">
                          {/* <h5 className="text-primary">Welcome Back !</h5>
                <p>CA Marketing Dashboard</p> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="12">
                        <div className="profile-user-wid">
                          <div className="text-center">
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={imgUrl + doctor.avatar}
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <h5 className="font-size-15 text-center mt-1">
                          #{doctor.doctorId}
                        </h5>

                        <Row className="mb-3 mt-3">
                          <div className="col col-5">
                            <span className="">Full name</span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor.name}</span>
                          </div>
                        </Row>

                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Email</span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor.email}</span>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Phone number</span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor.countryCode} {doctor.phone}</span>
                            <br />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Join Date</span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor.date}</span>
                            <br />
                          </div>
                        </Row>

                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Gender </span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor.gender}</span>
                            <br />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Experience </span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor.experience}</span>
                            <br />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Wallet </span>
                          </div>
                          <div className="col col-7">
                            <span>:  {doctor.wallet}</span>
                            <br />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Used Tokens </span>
                          </div>
                          <div className="col col-7">
                            <span>: {tokenscount?.total_tokens}</span>
                            <br />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Address </span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor?.address}</span>
                            <br />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Status </span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor.status == true ? "Active" : "Inactive"}</span>
                            <br />
                          </div>
                        </Row>
                        {/* <Row className="mb-3">
                          <div className="col col-5">
                            <span className="">Device Id </span>
                          </div>
                          <div className="col col-7">
                            <span>: {doctor.deviceId}</span>
                            <br />
                          </div>
                        </Row> */}



                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="8">
                <Card>
                  <CardBody>
                    <div className="mt-3">
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem className="border border-primary rounded p-1 m-1">
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "1",
                            })}
                            onClick={() => {
                              toggle1("1")
                            }}
                          >
                            HCE & Clinic Details
                          </NavLink>
                        </NavItem>
                        {/* <NavItem className="border border-primary rounded p-1 m-1">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "2",
                                })}
                                onClick={() => {
                                  toggle1("2")
                                }}
                              >
                               Clinic Details
                              </NavLink>
                            </NavItem> */}
                        <NavItem className="border border-primary rounded p-1 m-1">
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "3",
                            })}
                            onClick={() => {
                              toggle1("3")
                            }}
                          >
                            Subscriptions
                          </NavLink>
                        </NavItem>
                        <NavItem className="border border-primary rounded p-1 m-1">
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "4",
                            })}
                            onClick={() => {
                              toggle1("4")
                            }}
                          >
                            Consultations
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={activeTab1} className="p-3 text-muted">
                      <TabPane tabId="1">

                        <Row>
                          {/* <Col md="6">
                                <b className="font-size-14 ">State / Mci Reg No:</b>
                                <p className="text-muted">Sta11223344</p>
                              </Col> */}
                          <Col md="6">
                            <b className="font-size-14 ">Speciality:</b>
                            <p className="text-muted">{doctor.specialisation}</p>
                          </Col>
                          <Col md="6">
                            <b className="font-size-14 ">Qulification:</b>
                            <p className="text-muted">{doctor.qualification}</p>
                          </Col>
                          {/* <Col md="6">
                                <b className="font-size-14 ">Field of Medicine:</b>
                                <p className="text-muted">Homeopathi</p>
                              </Col> */}
                          {/* <Col md="6">
                            <b className="font-size-14 ">Subscription:</b>
                            <p className="text-muted">{doctor.qualification}</p>
                          </Col> */}
                          {/* <Col md="6">
                                <b className="font-size-14 ">Weather Show:</b>
                                <p className="text-muted">Yes</p>
                              </Col> */}
                          <Col md="6">
                            <b className="font-size-14 ">Location:</b>
                            <p className="text-muted">
                            {doctor.location}
                              {/* <a href={`https://www.google.com/maps?q=${doctor.latitude},${doctor.longitude}`} target="blank">
                                {locationName}
                              </a> */}
                            </p>
                          </Col>
                          <Col md="6">
                            <b className="font-size-14 ">Signature:</b>
                            <br />
                            <img src={imgUrl + doctor.signature} width="70%" height="100px" />
                          </Col>
                          <Col md="12">
                            <b className="font-size-14">Description:</b>
                            <p className="text-muted">{doctor.description}</p>
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col md="6">
                                <b className="font-size-14 ">State Registation Certificate:</b>
                                <br/>
                                <img src={ls} width="80px" />
                              </Col> */}

                        </Row>
                        <h5 className="mt-2 mb-3">Clinic Details</h5><hr />
                        <Row>
                          <Col md="3">
                            <b className="font-size-14 ">Clinic Name:</b>
                          </Col>
                          <Col md="9">
                            <p className="text-muted">{doctor.clinicName}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <b className="font-size-14 ">Clinic Location:</b>

                          </Col>
                          <Col md="9">
                            <p className="text-muted">
                              {doctor.location}
                              {/* <a href={`https://www.google.com/maps?q=${doctor.latitude},${doctor.longitude}`} target="blank">
                                {locationName}
                              </a> */}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3 mt-3">
                            <b className="font-size-14 mt-3">Clinic Logo:</b>
                          </Col>
                          <Col md="9">

                            <img src={imgUrl + doctor.clinicLogo} width="100px" />
                          </Col>
                        </Row>


                      </TabPane>

                      <TabPane tabId="3">
                        <Table responsive bordered hover >
                          <thead>
                            <tr>
                              <th>SlNo</th>
                              <th>Date</th>
                              <th>Expire Date</th>
                              <th>Plan Name</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key}>
                                <td>{(pageNumber - 1) * 5 + key + 6}</td>
                                <td>{data.startDate}</td>
                                <td>{data.endDate}</td>
                                <td>{data.planName}</td>
                                <td>{data.price}</td>
                                <td>
                                  {/* <Link to="/ViewSubscription"> */}
                                  <Button
                                    onClick={() => { redirectdata1(data) }}
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="success"
                                    outline
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Button>
                                  {/* </Link> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </TabPane>
                      <TabPane tabId="4">
                        <Table responsive bordered hover >
                          <thead>
                            <tr>
                              <th>SlNo</th>
                              <th>Date</th>
                              <th>Booking No</th>
                              <th>User Name</th>
                              <th>Type</th>
                              <th>Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists1.map((data, key) => (
                              <tr key={key}>
                                <td>{(pageNumber1 - 1) * 5 + key + 6}</td>
                                <td>{data.bookingDate}</td>
                                <td>{data.bookingNo}</td>
                                <td>{data.userName}</td>
                                <td>{data.consultationType}</td>
                                <td>{data.totalAmount}</td>
                                <td>
                                  {/* <Link to="/ViewSubscription"> */}
                                  <Button
                                    onClick={() => { Bookingrdct(data) }}
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="success"
                                    outline 
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Button>
                                  {/* </Link> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount1}
                            onPageChange={changePage1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists1.length}
                          />
                        </div>

                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default DoctorView
