import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { Link, useHistory } from "react-router-dom"
import { baseurl } from "Baseurls"

function Departments() {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  const [banner, setbanner] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)

  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    Getalldep()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Deletedep = (data) => {
    var token = datas
    var remid = data._id
    axios
      .delete(baseurl + 'role/deleterole/' + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            Getalldep()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Deletedep(data)
    }
  }


  const Getalldep = () => {
    var token = datas
    axios
      .post(baseurl + 'role/getAll', {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setbanner(res.data.roles)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }


  // const handlechange = (e) => {
  //   var token = datas
  //   axios
  //     .post(baseurl + 'role/getAll?searchQuery=', + `${e.target.value}`,{},
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     )
  //     .then(res => {
  //       setbanner(res.data.roles)
  //     })
  // }

  const handlechange = (e) => {
    const token = datas
    console.log(token)
    axios
      .post(baseurl + 'role/getAll?searchQuery=' + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setbanner(res.data.roles)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }


  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  // const roles = data.rolesAndPermissions
  // const roles1 = data.user.role

  const getpopup = (data) => {
    history.push('/RolesPremissions')
    sessionStorage.setItem('rolid', data._id)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude" breadcrumbItem="Roles & Permissions" />

          <Row>

            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Link to="/addrole"><Button className="m-1" color="primary" type="button"  title="Add Role">
                        Add Role <i className="bx bx-plus-circle"></i>
                      </Button></Link>
                    </Col>
                    <Col md={6}>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          onChange={(e) => { handlechange(e) }}
                          className="form-control"
                          placeholder="Search.."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-2 mt-3">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Role Name</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 10 + key + 11}</th>

                              <td>{data.role}</td>
                              <td>{data.status == true ? "Active" : "Inactive"}</td>
                              <td>{data?.logCreatedDate?.slice(0, 10)}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="success"
                                  outline
                                  title="Edit"
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                                {/* </>
                                ) : (
                                  ""
                                )} */}

                                {/* {roles.staffDelete === true ||
                                roles1 === "superadmin" ? (
                                  <> */}
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="danger"
                                  outline
                                  title="Delete"
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>{" "}
                                {/* </>
                                ) : (
                                  ""
                                )} */}
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>



        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Departments
