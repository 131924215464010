import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { format } from "date-fns"
import axios from "axios"
import { baseurl } from "Baseurls"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { useHistory } from "react-router-dom"
function Payment() {
  const history = useHistory()
  const [subdoc, setsubdoc] = useState([])
  const [form2, setform2] = useState([])
  const [show2, setshow2] = useState(false)

  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const handlechange = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getSubDoc()
  }, [])

  const getSubDoc = () => {
    var token = datas
    axios
      .post(
        baseurl + "payments/getdoctorpaidwallet",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc(res.data.wallet)
      })
  }
  const handlechange1 = e => {
    var token = datas
    axios
      .post(
        baseurl +
          "payments/getdoctorpaidwallet?searchQuery=" +
          `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc(res.data.wallet)
      })
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const pagesVisited = pageNumber * listPerPage
  const lists = subdoc.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(subdoc.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const redirectdata = data => {
    sessionStorage.setItem("docid", data.doctorId)
    history.push("/ViewDoctor")
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Allude" breadcrumbItem="Completed Payments" />
            {show2 == true ? (
              <Card className="p-4">
                <Form
                  onSubmit={e => {
                    filterCustomer(e)
                  }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-4">
                        <Label>Date Range</Label>

                        <Flatpickr
                          required
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                          name="date"
                          // value={selectedDate}
                          onChange={e => {
                            handleDateChange(e)
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="text-end mt-4">
                        <Button type="submit" color="success m-1" outline>
                          Submit <i className="bx bx-check-circle"></i>
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            setshow2(!show2)
                          }}
                          color="danger m-1"
                          outline
                        >
                          Cancel <i className="bx bx-x-circle"></i>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
            ) : (
              ""
            )}

            <Card>
              <CardBody>
                <Row>
                  <Col md="9">
                    {/* <Button type='button' color='primary'  >withdraw request  <i className="bx bx-wallet-alt"></i></Button> */}
                    {/* <Button style={{ marginLeft: "10px" }} onClick={() => { setshow2(!show2) }} color="warning">
                                            Filter <i className="bx bx-filter-alt"></i>
                                        </Button> */}
                  </Col>
                  <Col md="3" className="text-end">
                    <Input
                      name="search"
                      onChange={e => {
                        handlechange1(e)
                      }}
                      type="search"
                      placeholder="Search..."
                    />
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-bordered mb-2 mt-3">
                    <thead>
                      <tr>
                        <th>SlNo</th>
                        <th>Date</th>
                        <th>Doctor Name </th>
                        <th>Amount</th>
                        <th>Transaction Id</th>
                        <th>Message</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lists.length == 0 ? (
                        <tr className="text-center">
                          <td colSpan="7">No Data...</td>
                        </tr>
                      ) : (
                        <>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <td>{(pageNumber - 1) * 10 + key + 11}</td>
                              <td>{data.date}</td>
                              <td>
                                <a
                                  onClick={() => {
                                    redirectdata(data)
                                  }}
                                  className="text-primary"
                                >
                                  {data.doctorName}{" "}
                                </a>
                              </td>
                              <td> {data.amount}</td>
                              <td>{data.transactionId}</td>
                              <td>{data.description}</td>
                              <th>
                                <Button
                                  size="sm"
                                  outline
                                  color="success"
                                  className="rounded-pill"
                                  title="Success"
                                >
                                  Success
                                </Button>
                              </th>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex mt-3 mb-1" style={{ float: "right" }}>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"pagination"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                      total={lists.length}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
          <ToastContainer />
        </div>
      </React.Fragment>
    </div>
  )
}

export default Payment
