const Url = "http://103.186.185.77:5040/"

export const URLS = {
  Base: Url,

  //profile
  getProfile: Url + "v1/kanavneelapi/vendor/auth/getprofile",
  UpdateProfile: Url + "v1/kanavneelapi/vendor/auth/editprofile",
  UpdateImage: Url + "v1/kanavneelapi/vendor/auth/editcompanylogo",
  ChangePass: Url + "v1/kanavneelapi/vendor/auth/changepassword",

  //serviceman
  Addserviceman: Url + "v1/kanavneelapi/vendor/serviceman/adduser",
  Getserviceman: Url + "v1/kanavneelapi/vendor/serviceman/getall",
  Updateserviceman: Url + "v1/kanavneelapi/vendor/serviceman/edit/",
  Deleteserviceman: Url + "v1/kanavneelapi/vendor/plan/editplanstatus/",
  getservicemanid: Url + "v1/kanavneelapi/vendor/serviceman/getdetails",
  getservicemanidsearch:
    Url + "v1/kanavneelapi/vendor/serviceman/getall?searchQuery=",

  //States
  GetActiveStates: Url + "v1/kanavneelapi/admin/state/getallfordropdown",
  GetActiveDistrict: Url + "v1/kanavneelapi/admin/district/getallbystateid",
  GetActiveCity: Url + "v1/kanavneelapi/admin/city/getallbydistid",

  //Customers
  AddCustomers: Url + "v1/kanavneelapi/vendor/customer/addcustomer",
  GetCustomers: Url + "v1/kanavneelapi/vendor/customer/getall",
  UpdateCustomers: Url + "v1/kanavneelapi/vendor/customer/edit/",
  DeleteCustomers: Url + "v1/kanavneelapi/vendor/customer/delete/",
  getCustomersid: Url + "v1/kanavneelapi/vendor/customer/getdetails",
  getCustomersidsearch:
    Url + "v1/kanavneelapi/vendor/customer/getall?searchQuery=",

  //serviceman
  GetServiceman: Url + "v1/kanavneelapi/vendor/serviceman/getall",
  GetServicemanSearch:
    Url + "v1/kanavneelapi/vendor/serviceman/getall?searchQuery=",

  //Bookings
  GetpendingBooking: Url + "v1/kanavneelapi/vendor/booking/getallpending",
  GetpendingBookingSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallpending?searchQuery=",

  GetacceptedBooking: Url + "v1/kanavneelapi/admin/booking/getallaccepted",
  GetacceptedSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallaccepted?searchQuery=",

  Getgetallassigned: Url + "v1/kanavneelapi/vendor/booking/getallassigned",
  GetgetallassignedSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallassigned?searchQuery=",

  GetgetallinprogressBooking:
    Url + "v1/kanavneelapi/vendor/booking/getallinprogress",
  GetgetallinprogressBookingSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallinprogress?searchQuery=",

  Getgetallcompleted: Url + "v1/kanavneelapi/vendor/booking/getallcompleted",
  GetgetallcompletedSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallcompleted?searchQuery=",

  GetcanceledBooking: Url + "v1/kanavneelapi/vendor/booking/getallcanceled",
  GetcanceledBookingSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallcanceled?searchQuery=",

  //Services
  GetcompletedServices: Url + "v1/kanavneelapi/vendor/service/getallcompleted",
  GetcompletedServicesSearch:
    Url + "v1/kanavneelapi/vendor/service/getallcompleted?searchQuery=",

  GetpendingServices: Url + "v1/kanavneelapi/vendor/service/getallpending",
  GetpendingServicesSearch:
    Url + "v1/kanavneelapi/vendor/service/getallpending?searchQuery=",

  //Complaints
  Getcomplaint: Url + "v1/kanavneelapi/vendor/complaintbox/getall",
  GetcomplaintSearch:
    Url + "v1/kanavneelapi/vendor/complaintbox/getall?searchQuery=",
  Getcomplaintid: Url + "v1/kanavneelapi/vendor/complaintbox/getdetails",

  GetFAQ: Url + "v1/kanavneelapi/vendor/faq/getall",
  GetSetting: Url + "v1/kanavneelapi/vendor/setting/getsetting",

  //Reports
  servicemanReport: Url + "v1/kanavneelapi/vendor/report/servicemanreport",
  customerReport: Url + "v1/kanavneelapi/vendor/report/customerreport",
  franchiseReport: Url + "v1/kanavneelapi/vendor/report/franchisereport",
  serviceReport: Url + "v1/kanavneelapi/vendor/report/servicereport",
  BookingReport: Url + "v1/kanavneelapi/vendor/report/bookingreport",
  ExpireReport: Url + "v1/kanavneelapi/vendor/report/bookingexpiringreport",
  ExpiredReport: Url + "v1/kanavneelapi/vendor/report/bookingexpiredreport",

  //Dashboard
  GetDashboard: Url + "v1/kanavneelapi/vendor/auth/getdashboard",
}
