import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Otp from "pages/Authentication/Otp"
import Setpwd from "pages/Authentication/Setpwd"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Settings
import State from "pages/Settings/State"
import Country from "pages/Settings/Country"
import District from "pages/Settings/District"
import City from "pages/Settings/City"
import Faqs from "pages/Settings/Faqs"
import RefundPolicy from "pages/Settings/RefundPolicy"
import Terms from "pages/Settings/Terms"
import About from "pages/Settings/About"
import PrivacyPolicy from "pages/Settings/PrivacyPolicy"
import Notifications from "pages/Settings/Notifications"
import Meditions from "pages/Settings/Meditions"
import Sendmail from "pages/Settings/Sendmail" 
import Mailslist from "pages/Settings/Mailslist" 
import Viewmail from "pages/Settings/Viewmail" 
import Support from "pages/Settings/Support" 
import Releaserequest from "pages/Settings/Releaserequest" 
import Drugdosage from "pages/Settings/Drugdosage" 
import Devices from "pages/Settings/Devices" 
import Prompts from "pages/Settings/Prompts"

//Staff
import Departments from "pages/Staff/Departments"
import Roles from "pages/Staff/RolesPremissions"
import Addrole from "pages/Staff/Addrole"
import Staff from "pages/Staff/Staff"

//Doctors
import AddDoctors from "pages/Doctors/AddDoctors"
import Doctors from "pages/Doctors/Doctors"
import ViewDoctor from "pages/Doctors/ViewDoctor"
import Holidays from "pages/Doctors/Holidays"
import Addtimeslote from "pages/Doctors/Addtimeslote"
import Blockeddoctors from "pages/Doctors/Blockeddoctors"
import Wellat from "pages/Doctors/Wellat"

//Users
import AddUser from "pages/Users/AddUser"
import User from "pages/Users/User"
import ViewUser from "pages/Users/ViewUser"
import Blockedusers from "pages/Users/Blockedusers"
import Deleteuser from "pages/Users/Deleteuser"

//Bookinglist
import BookingList from "pages/Booking/Bookinglist"
import Videobooking from "pages/Booking/Videobooking"
import Directbookings from "pages/Booking/Directbookings"
import Rejectedbookings from "pages/Booking/Rejectedbookings"
import ViewBooking from "pages/Booking/ViewBooking"
import Bookingprescription from "pages/Booking/Bookingprescription"
import Consulted from "pages/Booking/Consulted"
import Pendingops from "pages/Booking/Pendingops"

//Subscription
import AddSubscription from "pages/Subscription/AddSubscription"
import Subscription from "pages/Subscription/Subscription"
import EditSubscription from "pages/Subscription/EditSubscription"
import ViewSubscription from "pages/Subscription/ViewSubscription"
import Viewsubcribed from "pages/Subscription/Viewsubcribed"
import Subscribed from "pages/Subscription/Subscribed"
import Expirysubscribers from "pages/Subscription/Expirysubscribers"



import Banners from "pages/Banners/Banners"

import Blog from "pages/Blog/Blog"
import Blogdetails from "pages/Blog/Blogdetails"
import Viewblog from "pages/Blog/Viewblog"

import Suggestions from "pages/Suggestions/Suggestions"

import Categories from "pages/Categories/Categories"

import Specialization from "pages/Settings/Specialization"
import Tax from "pages/Settings/Tax"

import Coupons from "pages/coupons/Coupons"
//report

import SubscriptionReport from "pages/Report/SubscriptionReport"
import PaymentReport from "pages/Report/PaymentReport"

import Addpayment from "pages/Payment/Addpayment"
import Consulationcharges from "pages/Payment/Consulationcharges"
import Releasepayments from "pages/Payment/Releasepayments"
import Transactions from "pages/Payment/Transactions"

import Contactus from "../pages/Settings/Contactus"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/Contactus", component: Contactus },

  { path: "/Coupons", component: Coupons },

  { path: "/Specialization", component: Specialization },

  { path: "/Suggestions", component: Suggestions },

  { path: "/Categories", component: Categories },

  { path: "/Prompts", component: Prompts },


  //profile
  { path: "/profile", component: UserProfile },

  { path: "/Banners", component: Banners },

  { path: "/Blog", component: Blog },
  { path: "/blogdetails", component: Blogdetails },
  { path: "/Viewblog", component: Viewblog },

  //Doctors
  { path: "/AddDoctors", component: AddDoctors },
  { path: "/Doctors", component: Doctors },
  { path: "/ViewDoctor", component: ViewDoctor },
  { path: "/timeslots", component: Holidays },
  { path: "/addtimeslot", component: Addtimeslote },
  { path: "/blockeddoctors", component: Blockeddoctors },
  { path: "/wallet", component: Wellat },

  //Subscription
  { path: "/AddSubscription", component: AddSubscription },
  { path: "/Subscription", component: Subscription },
  { path: "/ViewSubscription", component: ViewSubscription},
  { path: "/viewsub", component: Viewsubcribed},
  { path: "/subscribed", component: Subscribed},
  { path: "/expiringsubscribers", component: Expirysubscribers},
  
  { path: "/EditSubscription", component: EditSubscription },

  //Report
  { path: "/SubscriptionReport", component: SubscriptionReport },
  { path: "/PaymentReport", component: PaymentReport },

  //Users
  { path: "/AddUser", component: AddUser },
  { path: "/User", component: User },
  { path: "/ViewUser", component: ViewUser },
  { path: "/blockedusers", component: Blockedusers },

  //Bookinglist
  { path: "/ViewBooking", component: ViewBooking },
  { path: "/bookingprescription", component: Bookingprescription },
  { path: "/BookingList", component: BookingList },
  { path: "/onlinebooking", component: Videobooking },
  { path: "/directbooking", component: Directbookings },
  { path: "/rejectedbookings", component: Rejectedbookings },
  { path: "/consulted", component: Consulted },
  { path: "/pendingops", component: Pendingops },

  //Staff
  { path: "/Departments", component: Departments },
  { path: "/RolesPremissions", component: Roles },
  { path: "/addrole", component: Addrole },
  { path: "/Staff", component: Staff },


  // Settings
  { path: "/Notifications", component: Notifications },
  { path: "/sendmail", component: Mailslist },
  { path: "/sendmailsall", component: Sendmail },
  { path: "/viewmail", component: Viewmail },
  { path: "/medicine", component: Meditions },
  { path: "/State", component: State },
  { path: "/Country", component: Country },
  { path: "/languages", component: District },
  { path: "/City", component: City },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Terms", component: Terms },
  { path: "/About", component: About },
  { path: "/Faqs", component: Faqs },
  { path: "/PrivacyPolicy", component: PrivacyPolicy },
  { path: "/tax", component: Tax },
  { path: "/support", component: Support },
  { path: "/releaserequest", component: Releaserequest },
  { path: "/drugdosage", component: Drugdosage },
  { path: "/devices", component: Devices },


  { path: "/userpayments", component: Consulationcharges },
  { path: "/doctorpayments", component: Addpayment },
  { path: "/releasepayments", component: Releasepayments },
  { path: "/transactions", component: Transactions },

  // this route should be at the end of all other routesside
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/otp", component: Otp },
  { path: "/setpassword", component: Setpwd },
  { path: "/register", component: Register },
  { path: "/deleteuser", component: Deleteuser },
]

export { publicRoutes, authProtectedRoutes }
