import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Button,
    Nav,
    Table,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    TabContent,
    TabPane,
    Spinner,
    Label,
} from "reactstrap"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { baseurl } from "Baseurls"


function Ventures() {

    const [show3, setshow3] = useState(false)
    const toggle = () => setshow3(!show3)

    const [activeTab1, setactiveTab1] = useState("1")
    const [show, setShow] = useState(false)
    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)


    const [subdoc, setsubdoc] = useState([])
    const history = useHistory()

    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token


    useEffect(() => {
        getSubDoc()
    }, [])

    const getSubDoc = () => {
        var token = datas
        axios
            .post(baseurl + 'payments/getuserbookingpayments', {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setsubdoc(res.data.payments)
            })
    }

    const handleChange = (e) => {
        var token = datas
        axios
            .post(baseurl + 'payments/getuserbookingpayments?searchQuery=' + `${e.target.value}`, {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setsubdoc(res.data.payments)
            })
    }

    const [listPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0)
    const pagesVisited = pageNumber * listPerPage
    const lists = subdoc.slice(pagesVisited, pagesVisited + listPerPage)
    const pageCount = Math.ceil(subdoc.length / listPerPage)
    const changePage = ({ selected }) => {
        setPageNumber(selected)
    }


    const redirectdata = (data) => {
        sessionStorage.setItem('bookingid', data._id)
        history.push("/ViewBooking")
    }
    const redirectdata1 = (data) => {
        sessionStorage.setItem('docid', data.doctorId)
        history.push('/ViewDoctor')
    }
    const redirectdata2 = data => {
        sessionStorage.setItem("userid", data.userId)
        history.push("/ViewUser")
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Allude" breadcrumbItem="Booking Transaction's" />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="9"/>
                                        <Col md="3" className="mb-2 text-end">
                                            <div style={{ float: "right" }}>

                                                <Input
                                                    name="search"
                                                    onChange={(e) => { handleChange(e) }}
                                                    type="search"
                                                    placeholder="Search..."
                                                />
                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-rep-plugin table-responsive">
                                        <Table hover className="table table-bordered mb-4">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>SlNo</th>
                                                    <th>Date</th>
                                                    <th>Booking Id</th>
                                                    {/* <th>Doc Id</th> */}
                                                    <th>Doc Name</th>
                                                    {/* <th>User Id</th> */}
                                                    <th>User Name</th>
                                                    <th>Consultation</th>
                                                    <th>Problem</th>
                                                    <th>Transactions Id</th>
                                                    <th>Amount</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lists.map((data, key) => (
                                                    <tr key={key} className="text-center">
                                                        <td>{(pageNumber - 1) * 10 + key + 11}</td>
                                                        <th>{data.date}</th>
                                                        <th><a className="text-primary" onClick={() => { redirectdata(data) }} >#{data.bookingNo} </a></th>
                                                        <th><a className="text-primary" onClick={() => { redirectdata1(data) }} >{data.doctorName} </a></th>
                                                        <th><a className="text-primary" onClick={() => { redirectdata2(data) }} >{data.userName} </a></th>
                                                        <th>{data.consultationType}</th>
                                                        <td>{data.problem}</td>
                                                        <td>{data.transactionId}</td>
                                                        <td> {data.totalAmount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div
                                            className="d-flex mt-3 mb-1"
                                            style={{ float: "right" }}
                                        >
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                total={lists.length}
                                            />
                                        </div>
                                    </div>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer />
                </div>


            </div>
        </React.Fragment>
    )
}

export default Ventures
