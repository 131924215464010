import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    Form,
    Label,
    Input,
} from "reactstrap"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { Link, useHistory } from "react-router-dom"
import { baseurl } from "Baseurls"

const Roles = () => {
    const history = useHistory()
    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token

    const check = {
        DashView: false,
        roleView: false,
        roleAdd: false,
        roleEdit: false,
        roleDelete: false,
        staffView: false,
        staffAdd: false,
        staffEdit: false,
        staffDelete: false,
        SpecializationAdd: false,
        SpecializationEdit: false,
        SpecializationView: false,
        SpecializationDelete: false,
        doctorsView: false,
        doctorsEdit: false,
        doctorsDelete: false,
        doctorsAdd: false,
        blogsView: false,
        blogsEdit: false,
        blogsDelete: false,
        userEdit: false,
        userView: false,
        userDelete: false,
        scheduledopsAdd: false,
        consultedops: false,
        pendingops: false,
        directops: false,
        cancelops: false,
        SubscriptionAdd: false,
        SubscriptionEdit: false,
        SubscriptionView: false,
        SubscriptionDelete: false,
        SubscribedAdd: false,
        Subscribedexp: false,
        subpayments: false,
        dcpayments: false,
        relesepayments: false,
        paytran: false,
        wallet: false,
        sendmsg: false,
        opsblock: false,
        docblock: false,
        releaserequest: false,
        reports: false,
        banneradd: false,
        bannerEdit: false,
        bannerView: false,
        bannerDelete: false,
        couponAdd: false,
        couponEdit: false,
        couponView: false,
        language: false,
        couponDelete: false,
        notificationadd: false,
        notificationView: false,
        notificationDelete: false,
        faqAdd: false,
        faqEdit: false,
        faqView: false,
        faqDelete: false,
        About: false,
        PrivacyPolicy: false,
        TermsAndCondition: false,
        refundpolicy: false,
        tax: false,
        Support: false,
        MedicineView: false,
        dosageview: false,
        devicesview: false,

    }
    console.log(check)

    const [ras, setras] = useState([check])
    console.log(ras)
    const [form, setform] = useState([])
    console.log(form)

    const handleChange = (e) => {
        const myUser = { ...form }
        myUser[e.target.name] = (e.target.value)
        setform(myUser)
    }

    const handleChange1s = (e) => {
        const myUser = { ...ras }
        myUser[e.target.name] = (e.target.checked)
        setras(myUser)
        console.log(myUser)
    }

    const roleid = sessionStorage.getItem('rolid')

    const Addrole = () => {
        const token = datas
        const bodydata = {
            role: form.role,
            status: form.status,
            permissions: [ras]
        }
        axios
            .put(baseurl + 'role/editrole/' + roleid, bodydata,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(
                res => {
                    if (res.status === 200) {
                        toast.success(res.data.message)
                        setTimeout(() => {
                            history.push('/Departments')
                        }, 2000)
                    }
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.message)
                    }
                }
            )
    }

    const handleSubmit = e => {
        e.preventDefault()
        Addrole()
    }

    const GetAllRoles = () => {
        var token = datas
        const bodydata = {
            _id: roleid
        }
        axios
            .post(baseurl + 'role/getrole', bodydata,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setras(res?.data?.data?.permissions[0])
                setform(res?.data?.data)
            })
    }

    useEffect(() => {
        GetAllRoles()
    }, [])



    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    // const roles = data.rolesAndPermissions
    // const roles1 = data.user.role


    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <Breadcrumbs title="Allude" breadcrumbItem="Edit Role & Permissions" />

                    <Row>
                        <Col md={12}>
                            <Card>

                                <CardBody>
                                    <Form
                                        onSubmit={e => {
                                            handleSubmit(e)
                                        }}
                                    >
                                        <Row className="mb-5">
                                            <Col md={4}>
                                                <Label> Role  <span className="text-danger">*</span></Label>{" "}
                                                <Input
                                                    value={form.role}
                                                    name="role"
                                                    required
                                                    onChange={e => {
                                                        handleChange(e)
                                                    }}
                                                    type="text" placeholder="Enter role name" />

                                            </Col>
                                            <Col md={4}>
                                                <Label> Status  <span className="text-danger">*</span></Label>{" "}
                                                <select
                                                    value={form.status}
                                                    name="status"
                                                    onChange={e => {
                                                        handleChange(e)
                                                    }}
                                                    className="form-select"
                                                >
                                                    <option value="">Select</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>Inactive</option>
                                                    {/* {rol.map((data, key) => {
                                                        return (
                                                            <option key={key} value={data._id}>
                                                                {data.departmentName}
                                                            </option>
                                                        )
                                                    })} */}
                                                </select>
                                            </Col>
                                        </Row>

                                        <Row className=" mt-3">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Dashboard: </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="DashView"
                                                        defaultChecked={ras.DashView}
                                                        value={ras.DashView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="DashView"
                                                    />
                                                    <Label className="form-check-label" for="DashView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className=" mt-3">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Roles & Premissions: </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="roleView"
                                                        defaultChecked={ras.roleView}
                                                        value={ras.roleView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="roleView"
                                                    />
                                                    <Label className="form-check-label" for="roleView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="roleAdd"
                                                        defaultChecked={ras.roleAdd}
                                                        value={ras.roleAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="roleAdd"
                                                    />
                                                    <Label className="form-check-label" for="roleAdd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="roleEdit"
                                                        defaultChecked={ras.roleEdit}
                                                        value={ras.roleEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="roleEdit"
                                                    />
                                                    <Label className="form-check-label" for="roleEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="roleDelete"
                                                        defaultChecked={ras.roleDelete}
                                                        value={ras.roleDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="roleDelete"
                                                    />
                                                    <Label className="form-check-label" for="roleDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Staff : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="staffView"
                                                        defaultChecked={ras.staffView}
                                                        value={ras.staffView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="staffView"
                                                    />
                                                    <Label
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        className="form-check-label"
                                                        for="staffView"
                                                    >
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="staffAdd"
                                                        defaultChecked={ras.staffAdd}
                                                        value={ras.staffAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="staffAdd"
                                                    />
                                                    <Label className="form-check-label" for="staffAdd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="staffEdit"
                                                        defaultChecked={ras.staffEdit}
                                                        value={ras.staffEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="staffEdit"
                                                    />
                                                    <Label className="form-check-label" for="staffEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="staffDelete"
                                                        defaultChecked={ras.staffDelete}
                                                        value={ras.staffDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="staffDelete"
                                                    />
                                                    <Label className="form-check-label" for="staffDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Specialization : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SpecializationView"
                                                        defaultChecked={ras.SpecializationView}
                                                        value={ras.SpecializationView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SpecializationView"
                                                    />
                                                    <Label className="form-check-label" for="SpecializationView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SpecializationAdd"
                                                        defaultChecked={ras.SpecializationAdd}
                                                        value={ras.SpecializationAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SpecializationAdd"
                                                    />
                                                    <Label className="form-check-label" for="SpecializationAdd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SpecializationEdit"
                                                        defaultChecked={ras.SpecializationEdit}
                                                        value={ras.SpecializationEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SpecializationEdit"
                                                    />
                                                    <Label className="form-check-label" for="SpecializationEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SpecializationDelete"
                                                        defaultChecked={ras.SpecializationDelete}
                                                        value={ras.SpecializationDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SpecializationDelete"
                                                    />
                                                    <Label className="form-check-label" for="SpecializationDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className=" mt-3">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Doctors: </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="doctorsView"
                                                        defaultChecked={ras.doctorsView}
                                                        value={ras.doctorsView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="doctorsView"
                                                    />
                                                    <Label className="form-check-label" for="doctorsView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="doctorsAdd"
                                                        defaultChecked={ras.doctorsAdd}
                                                        value={ras.doctorsAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="doctorsAdd"
                                                    />
                                                    <Label className="form-check-label" for="doctorsAdd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="doctorsEdit"
                                                        defaultChecked={ras.doctorsEdit}
                                                        value={ras.doctorsEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="doctorsEdit"
                                                    />
                                                    <Label className="form-check-label" for="doctorsEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="doctorsDelete"
                                                        defaultChecked={ras.doctorsDelete}
                                                        value={ras.doctorsDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="doctorsDelete"
                                                    />
                                                    <Label className="form-check-label" for="doctorsDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className=" mt-3">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Blogs : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="blogsView"
                                                        defaultChecked={ras.blogsView}
                                                        value={ras.blogsView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="blogsView"
                                                    />
                                                    <Label className="form-check-label" for="blogsView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="blogsEdit"
                                                        defaultChecked={ras.blogsEdit}
                                                        value={ras.blogsEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="blogsEdit"
                                                    />
                                                    <Label className="form-check-label" for="blogsEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check me-3 me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="blogsDelete"
                                                        defaultChecked={ras.blogsDelete}
                                                        value={ras.blogsDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="blogsDelete"
                                                    />
                                                    <Label className="form-check-label" for="blogsDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Users : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="userView"
                                                        defaultChecked={ras.userView}
                                                        value={ras.userView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="userView"
                                                    />
                                                    <Label className="form-check-label" for="userView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="userEdit"
                                                        defaultChecked={ras.userEdit}
                                                        value={ras.userEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="userEdit"
                                                    />
                                                    <Label className="form-check-label" for="userEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="userDelete"
                                                        defaultChecked={ras.userDelete}
                                                        value={ras.userDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="userDelete"
                                                    />
                                                    <Label className="form-check-label" for="userDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Pending Op's : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="pendingops"
                                                        defaultChecked={ras.pendingops}
                                                        value={ras.pendingops}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="pendingops"
                                                    />
                                                    <Label className="form-check-label" for="pendingops">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Scheduled Op's : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="scheduledopsAdd"
                                                        defaultChecked={ras.scheduledopsAdd}
                                                        value={ras.scheduledopsAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="scheduledopsAdd"
                                                    />
                                                    <Label className="form-check-label" for="scheduledopsAdd">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Consulted Op's : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="consultedops"
                                                        defaultChecked={ras.consultedops}
                                                        value={ras.consultedops}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="consultedops"
                                                    />
                                                    <Label className="form-check-label" for="consultedops">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Cancel Op's : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="cancelops"
                                                        defaultChecked={ras.cancelops}
                                                        value={ras.cancelops}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="cancelops"
                                                    />
                                                    <Label className="form-check-label" for="cancelops">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Subscription : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SubscriptionView"
                                                        defaultChecked={ras.SubscriptionView}
                                                        value={ras.SubscriptionView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SubscriptionView"
                                                    />
                                                    <Label className="form-check-label" for="SubscriptionView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SubscriptionAdd"
                                                        defaultChecked={ras.SubscriptionAdd}
                                                        value={ras.SubscriptionAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SubscriptionAdd"
                                                    />
                                                    <Label className="form-check-label" for="SubscriptionAdd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SubscriptionEdit"
                                                        defaultChecked={ras.SubscriptionEdit}
                                                        value={ras.SubscriptionEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SubscriptionEdit"
                                                    />
                                                    <Label className="form-check-label" for="SubscriptionEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SubscriptionDelete"
                                                        defaultChecked={ras.SubscriptionDelete}
                                                        value={ras.SubscriptionDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SubscriptionDelete"
                                                    />
                                                    <Label className="form-check-label" for="SubscriptionDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Subscribed : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="SubscribedAdd"
                                                        defaultChecked={ras.SubscribedAdd}
                                                        value={ras.SubscribedAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="SubscribedAdd"
                                                    />
                                                    <Label className="form-check-label" for="SubscribedAdd">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Expiring Subscribers : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Subscribedexp"
                                                        defaultChecked={ras.Subscribedexp}
                                                        value={ras.Subscribedexp}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="Subscribedexp"
                                                    />
                                                    <Label className="form-check-label" for="Subscribedexp">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Subscription Payments : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="subpayments"
                                                        defaultChecked={ras.subpayments}
                                                        value={ras.subpayments}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="subpayments"
                                                    />
                                                    <Label className="form-check-label" for="subpayments">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Doctor's Payments : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="dcpayments"
                                                        defaultChecked={ras.dcpayments}
                                                        value={ras.dcpayments}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="dcpayments"
                                                    />
                                                    <Label className="form-check-label" for="dcpayments">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Release Payments : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="relesepayments"
                                                        defaultChecked={ras.relesepayments}
                                                        value={ras.relesepayments}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="relesepayments"
                                                    />
                                                    <Label className="form-check-label" for="relesepayments">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Payment Transactions: </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="paytran"
                                                        defaultChecked={ras.paytran}
                                                        value={ras.paytran}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="paytran"
                                                    />
                                                    <Label className="form-check-label" for="paytran">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Wallet : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="wallet"
                                                        defaultChecked={ras.wallet}
                                                        value={ras.wallet}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="wallet"
                                                    />
                                                    <Label className="form-check-label" for="wallet">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Send Message : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="sendmsg"
                                                        defaultChecked={ras.sendmsg}
                                                        value={ras.sendmsg}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="sendmsg"
                                                    />
                                                    <Label className="form-check-label" for="sendmsg">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">OP's Block : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="opsblock"
                                                        defaultChecked={ras.opsblock}
                                                        value={ras.opsblock}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="opsblock"
                                                    />
                                                    <Label className="form-check-label" for="opsblock">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Doctor's Block : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="docblock"
                                                        defaultChecked={ras.docblock}
                                                        value={ras.docblock}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="docblock"
                                                    />
                                                    <Label className="form-check-label" for="docblock">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Release Request : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="releaserequest"
                                                        defaultChecked={ras.releaserequest}
                                                        value={ras.releaserequest}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="releaserequest"
                                                    />
                                                    <Label className="form-check-label" for="releaserequest">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>



                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Banners : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="bannerView"
                                                        defaultChecked={ras.bannerView}
                                                        value={ras.bannerView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="bannerView"
                                                    />
                                                    <Label className="form-check-label" for="bannerView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="banneradd"
                                                        defaultChecked={ras.banneradd}
                                                        value={ras.banneradd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="banneradd"
                                                    />
                                                    <Label className="form-check-label" for="banneradd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="bannerEdit"
                                                        defaultChecked={ras.bannerEdit}
                                                        value={ras.bannerEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="bannerEdit"
                                                    />
                                                    <Label className="form-check-label" for="bannerEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="bannerDelete"
                                                        defaultChecked={ras.bannerDelete}
                                                        value={ras.bannerDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="bannerDelete"
                                                    />
                                                    <Label className="form-check-label" for="bannerDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Coupons : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="couponView"
                                                        defaultChecked={ras.couponView}
                                                        value={ras.couponView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="couponView"
                                                    />
                                                    <Label className="form-check-label" for="couponView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="couponAdd"
                                                        defaultChecked={ras.couponAdd}
                                                        value={ras.couponAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="couponAdd"
                                                    />
                                                    <Label className="form-check-label" for="couponAdd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="couponEdit"
                                                        defaultChecked={ras.couponEdit}
                                                        value={ras.couponEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="couponEdit"
                                                    />
                                                    <Label className="form-check-label" for="couponEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="couponDelete"
                                                        defaultChecked={ras.couponDelete}
                                                        value={ras.couponDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="couponDelete"
                                                    />
                                                    <Label className="form-check-label" for="couponDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Language : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="language"
                                                        defaultChecked={ras.language}
                                                        value={ras.language}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="language"
                                                    />
                                                    <Label className="form-check-label" for="language">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Notifications : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="notificationView"
                                                        defaultChecked={ras.notificationView}
                                                        value={ras.notificationView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="notificationView"
                                                    />
                                                    <Label className="form-check-label" for="notificationView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="notificationadd"
                                                        defaultChecked={ras.notificationadd}
                                                        value={ras.notificationadd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="notificationadd"
                                                    />
                                                    <Label className="form-check-label" for="notificationadd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="notificationDelete"
                                                        defaultChecked={ras.notificationDelete}
                                                        value={ras.notificationDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="notificationDelete"
                                                    />
                                                    <Label className="form-check-label" for="notificationDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Medicine : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="MedicineView"
                                                        defaultChecked={ras.MedicineView}
                                                        value={ras.MedicineView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="MedicineView"
                                                    />
                                                    <Label className="form-check-label" for="MedicineView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Medicineadd"
                                                        defaultChecked={ras.Medicineadd}
                                                        value={ras.Medicineadd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="Medicineadd"
                                                    />
                                                    <Label className="form-check-label" for="Medicineadd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="MedicineEdit"
                                                        defaultChecked={ras.MedicineEdit}
                                                        value={ras.MedicineEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="MedicineEdit"
                                                    />
                                                    <Label className="form-check-label" for="MedicineEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="MedicineDelete"
                                                        defaultChecked={ras.MedicineDelete}
                                                        value={ras.MedicineDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="MedicineDelete"
                                                    />
                                                    <Label className="form-check-label" for="MedicineDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">FAQ : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="faqView"
                                                        defaultChecked={ras.faqView}
                                                        value={ras.faqView}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="faqView"
                                                    />
                                                    <Label className="form-check-label" for="faqView">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            {/* <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="faqAdd"
                                                        defaultChecked={ras.faqAdd}
                                                        value={ras.faqAdd}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="faqAdd"
                                                    />
                                                    <Label className="form-check-label" for="faqAdd">
                                                        Add
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="faqEdit"
                                                        defaultChecked={ras.faqEdit}
                                                        value={ras.faqEdit}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="faqEdit"
                                                    />
                                                    <Label className="form-check-label" for="faqEdit">
                                                        Edit
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="faqDelete"
                                                        defaultChecked={ras.faqDelete}
                                                        value={ras.faqDelete}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="faqDelete"
                                                    />
                                                    <Label className="form-check-label" for="faqDelete">
                                                        Delete
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={1}></Col> */}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">About : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="About"
                                                        defaultChecked={ras.About}
                                                        value={ras.About}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="About"
                                                    />
                                                    <Label className="form-check-label" for="About">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Privacy Policy : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="PrivacyPolicy"
                                                        defaultChecked={ras.PrivacyPolicy}
                                                        value={ras.PrivacyPolicy}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="PrivacyPolicy"
                                                    />
                                                    <Label className="form-check-label" for="PrivacyPolicy">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Terms And Condition : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="TermsAndCondition"
                                                        defaultChecked={ras.TermsAndCondition}
                                                        value={ras.TermsAndCondition}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="TermsAndCondition"
                                                    />
                                                    <Label className="form-check-label" for="TermsAndCondition">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Refund Policy : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="refundpolicy"
                                                        defaultChecked={ras.refundpolicy}
                                                        value={ras.refundpolicy}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="refundpolicy"
                                                    />
                                                    <Label className="form-check-label" for="refundpolicy">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Tax : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="tax"
                                                        defaultChecked={ras.tax}
                                                        value={ras.tax}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="tax"
                                                    />
                                                    <Label className="form-check-label" for="tax">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Support : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Support"
                                                        defaultChecked={ras.Support}
                                                        value={ras.Support}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="Support"
                                                    />
                                                    <Label className="form-check-label" for="Support">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Reports : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="reports"
                                                        defaultChecked={ras.reports}
                                                        value={ras.reports}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="reports"
                                                    />
                                                    <Label className="form-check-label" for="reports">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Dosage : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="dosageview"
                                                        defaultChecked={ras.dosageview}
                                                        value={ras.dosageview}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="dosageview"
                                                    />
                                                    <Label className="form-check-label" for="dosageview">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Devices : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={2}>
                                                <div className="form-check  me-lg-5">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="devicesview"
                                                        defaultChecked={ras.devicesview}
                                                        value={ras.devicesview}
                                                        onClick={e => {
                                                            handleChange1s(e)
                                                        }}
                                                        id="devicesview"
                                                    />
                                                    <Label className="form-check-label" for="devicesview">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>

                                            <Col md={1}></Col>
                                        </Row>

                                        <div className="mt-3" style={{ float: "right" }}>
                                            <Link to="/Departments"><button
                                                type="button"
                                                style={{ width: "120px" }}
                                                className="btn btn-danger m-1"
                                            >
                                                Cancel{" "}
                                                <i
                                                    className="fa fa-check-circle-o"
                                                    aria-hidden="true"
                                                ></i>
                                            </button></Link>
                                            <button
                                                type="submit"
                                                style={{ width: "120px" }}
                                                className="btn btn-success m-1"
                                            >
                                                Submit{" "}
                                                <i
                                                    className="fa fa-check-circle-o"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </div>{" "}
                                        {/* </>
                    ) : (
                      ""
                    )} */}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

export default Roles
