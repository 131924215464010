import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Nav,
  Table,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { baseurl } from "Baseurls"
import { CSVLink } from "react-csv"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"

function Ventures() {
  const [activeTab1, setactiveTab1] = useState("1")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
      getReportData12()
      getReportData1()
    }
  }

  const [subdoc, setsubdoc] = useState([])
  const [subdoc1, setsubdoc1] = useState([])

  const [subdocex, setsubdocex] = useState([])
  const [subdoc1ex, setsubdoc1ex] = useState([])
  const history = useHistory()
  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const [listPerPage1] = useState(10)
  const [pageNumber1, setPageNumber1] = useState(0)

  useEffect(() => {
    getSubDoc()
    getSubUser()
  }, [])

  const getSubDoc = () => {
    var token = datas
    axios
      .post(
        baseurl + "subscribeAdmin/getdoctorpayments",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc(res.data.subscribeResult)
        setsubdocex(
          res.data.subscribeResult.map((data, index) => ({
            "S No": index + 1,
            Date: data.date,
            "Expiry Date": data.endDate,
            "Doctor Id": data.subscriberNo,
            "Doctor Name": data.subscriberName,
            "Plan Name": data.planName,
            Duration: data.duration,
            Price: data.price,
            "Convenience Fee": data.tax,
            Date: data.date,
            Total: data.total,
            "Transaction Id": data.transactionId,
          }))
        )
      })
  }
  const getSubUser = () => {
    var token = datas
    axios
      .post(
        baseurl + "subscribeAdmin/getuserpayments",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc1(res.data.subscribeResult)
        setsubdoc1ex(
          res.data.subscribeResult.map((data, index) => ({
            "S No": index + 1,
            Date: data.date,
            "Expiry Date": data.endDate,
            "User Id": data.subscriberNo,
            "User Name": data.subscriberName,
            "Plan Name": data.planName,
            Duration: data.duration,
            Price: data.price,
            "Convenience Fee": data.tax,
            Date: data.date,
            Total: data.total,
            "Transaction Id": data.transactionId,
          }))
        )
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = subdoc.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(subdoc.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const pagesVisited1 = pageNumber * listPerPage1
  const lists1 = subdoc1.slice(pagesVisited1, pagesVisited1 + listPerPage1)
  const pageCount1 = Math.ceil(subdoc1.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    console.log(token)

    axios
      .post(
        baseurl +
          "subscribeAdmin/getdoctorpayments?searchQuery=" +
          `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setsubdoc(res.data.subscribeResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Search1 = e => {
    const token = datas
    axios
      .post(
        baseurl +
          "subscribeAdmin/getuserpayments?searchQuery=" +
          `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setsubdoc1(res.data.subscribeResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const redirectdata1 = data => {
    sessionStorage.setItem("subid", data._id)
    history.push("/viewsub")
  }

  const redirectdata = data => {
    sessionStorage.setItem("docid", data.subscriberId)
    history.push("/ViewDoctor")
  }

  const Agentid1 = data => {
    sessionStorage.setItem("userid", data.subscriberId)
    history.push("/ViewUser")
  }

  const csvReport = {
    filename: "DoctorReport.csv",
    data: subdocex,
  }
  const csvReport1 = {
    filename: "UserReport.csv",
    data: subdoc1ex,
  }

  const [dates, setDates] = useState([])
  const [dates1, setDates1] = useState([])

  const handleDateChange = async NewDate => {
    if (NewDate.length === 0) {
    } else {
      const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd")
      const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd")
      // const newDates = [date1, date2];
      const bodydate = {
        dates: [date1, date2],
      }
      let urlpath =
        activeTab1 == "2"
          ? "subscribeAdmin/getuserpayments"
          : "subscribeAdmin/getdoctorpayments"
      var token = datas
      setDates(NewDate)
      axios
        .post(baseurl + urlpath, bodydate, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => {
          setsubdoc(res.data.subscribeResult)
          setsubdocex(
            res.data.subscribeResult.map((data, index) => ({
              "S No": index + 1,
              Date: data.date,
              "Expiry Date": data.endDate,
              "Doctor Id": data.subscriberNo,
              "Doctor Name": data.subscriberName,
              "Plan Name": data.planName,
              Duration: data.duration,
              Price: data.price,
              "Convenience Fee": data.tax,
              Date: data.date,
              Total: data.total,
              "Transaction Id": data.transactionId,
            }))
          )
          setsubdoc1(res.data.subscribeResult)
          setsubdoc1ex(
            res.data.subscribeResult.map((data, index) => ({
              "S No": index + 1,
              Date: data.date,
              "Expiry Date": data.endDate,
              "User Id": data.subscriberNo,
              "User Name": data.subscriberName,
              "Plan Name": data.planName,
              Duration: data.duration,
              Price: data.price,
              "Convenience Fee": data.tax,
              Date: data.date,
              Total: data.total,
              "Transaction Id": data.transactionId,
            }))
          )
        })
    }
  }

  const handleDateChange21 = async NewDate1 => {
    const date12 = format(new Date(NewDate1[0]), "yyyy-MM-dd")
    const date21 = format(new Date(NewDate1[1]), "yyyy-MM-dd")
    // const newDates = [date1, date2];1
    const bodydate = {
      dates: [date12, date21],
    }
    var token = datas
    axios
      .post(baseurl + "subscribeAdmin/getuserpayments", bodydate, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setsubdoc1(res.data.subscribeResult)
        setsubdoc1ex(
          res.data.subscribeResult.map((data, index) => ({
            "S No": index + 1,
            Date: data.date,
            "Expiry Date": data.endDate,
            "User Id": data.subscriberNo,
            "User Name": data.subscriberName,
            "Plan Name": data.planName,
            Duration: data.duration,
            Price: data.price,
            "Convenience Fee": data.tax,
            Date: data.date,
            Total: data.total,
            "Transaction Id": data.transactionId,
          }))
        )

        // setDates1([])
      })
  }

  const getReportData1 = async () => {
    const bodyData = {
      dates: [],
    }
    var token = datas
    axios
      .post(baseurl + "subscribeAdmin/getdoctorpayments", bodyData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setsubdoc(res.data.subscribeResult)
        setsubdocex(
          res.data.subscribeResult.map((data, index) => ({
            "S No": index + 1,
            Date: data.date,
            "Expiry Date": data.endDate,
            "Doctor Id": data.subscriberNo,
            "Doctor Name": data.subscriberName,
            "Plan Name": data.planName,
            Duration: data.duration,
            Price: data.price,
            "Convenience Fee": data.tax,
            Date: data.date,
            Total: data.total,
            "Transaction Id": data.transactionId,
          }))
        )
        setDates([])
      })
  }

  const getReportData12 = async () => {
    const bodyData = {
      dates: [],
    }
    var token = datas
    axios
      .post(baseurl + "subscribeAdmin/getuserpayments", bodyData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setsubdoc1(res.data.subscribeResult)
        setsubdoc1ex(
          res.data.subscribeResult.map((data, index) => ({
            "S No": index + 1,
            Date: data.date,
            "Expiry Date": data.endDate,
            "User Id": data.subscriberNo,
            "User Name": data.subscriberName,
            "Plan Name": data.planName,
            Duration: data.duration,
            Price: data.price,
            "Convenience Fee": data.tax,
            Date: data.date,
            Total: data.total,
            "Transaction Id": data.transactionId,
          }))
        )
        setDates1([])
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Subscription Report" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <Row>
                        <Col md="12">
                          <div className="mt-2">
                            <Nav pills className="navtab-bg nav-justified">
                              <NavItem className="border border-primary rounded m-1">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab1 === "1",
                                  })}
                                  onClick={() => {
                                    toggle1("1")
                                  }}
                                >
                                  Doctor's
                                </NavLink>
                              </NavItem>
                              <NavItem className="border border-primary rounded m-1">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab1 === "2",
                                  })}
                                  onClick={() => {
                                    toggle1("2")
                                  }}
                                >
                                  User's
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="8" className="mt-2 text-end">
                      {activeTab1 == "2" ? (
                        <Row>
                          <Col md="1" />

                          <Col style={{ padding: "0px" }} md="5">
                            <div>
                              <Flatpickr
                                placeholder="Select date"
                                className="form-control"
                                name="dates1"
                                value={dates1}
                                onChange={e => {
                                  handleDateChange(e)
                                }}
                                options={{
                                  mode: "range",
                                  dateFormat: "d M, Y",
                                }}
                              />
                            </div>
                          </Col>
                          <Col style={{ padding: "0px" }} md="1">
                            <div>
                              <Button
                                style={{ height: "33px" }}
                                onClick={getReportData12}
                                size="sm"
                                outline
                                color="danger"
                                title="Clear"
                              >
                                Clear
                              </Button>
                            </div>
                          </Col>
                          <Col style={{ padding: "0px" }} md="5">
                            <div>
                              <div style={{ float: "right" }}>
                                <Input
                                  name="search"
                                  onChange={e => {
                                    Search1(e)
                                  }}
                                  type="search"
                                  placeholder="Search..."
                                />
                              </div>
                              <CSVLink {...csvReport1}>
                                <Button type="button" color="success" outline  title="Excel" style={{marginRight:"10px"}}>
                                  Excel <i className="fas fa-file-excel"></i>
                                </Button>
                              </CSVLink>{" "}
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col md="1" />

                          <Col style={{ padding: "0px" }} md="5">
                            <div>
                              <Flatpickr
                                placeholder="Select date"
                                className="form-control"
                                name="dates"
                                value={dates}
                                onChange={e => {
                                  handleDateChange(e)
                                }}
                                options={{
                                  mode: "range",
                                  dateFormat: "d M, Y",
                                }}
                              />
                            </div>
                          </Col>
                          <Col style={{ padding: "0px" }} md="1">
                            <div>
                              <Button
                                style={{ height: "33px" }}
                                onClick={getReportData1}
                                size="sm"
                                outline
                                color="danger"
                                title="Clear"
                              >
                                Clear
                              </Button>
                            </div>
                          </Col>
                          <Col style={{ padding: "0px" }} md="5">
                            <div>
                              <div style={{ float: "right" }}>
                                <Input
                                  name="search"
                                  value={form1.search}
                                  onChange={Search}
                                  type="search"
                                  placeholder="Search..."
                                />
                              </div>
                              <CSVLink {...csvReport}>
                                <Button type="button" color="success" outline  title="Excel" style={{marginRight:"10px"}}>
                                  Excel <i className="fas fa-file-excel"></i>
                                </Button>
                              </CSVLink>{" "}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <div className="table-rep-plugin table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Date</th>
                              <th>Ex Date</th>
                              <th>Doc Id</th>
                              <th>Doc Name</th>
                              <th>Plan Name</th>
                              <th>Price</th>
                              <th>Convenience fee</th>
                              <th>Total</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                <th>{data.startDate}</th>
                                <th>{data.endDate}</th>
                                <th>
                                  <a
                                    onClick={() => {
                                      redirectdata(data)
                                    }}
                                  >
                                    #{data.subscriberNo}
                                  </a>
                                </th>
                                <th>{data.subscriberName}</th>
                                <td>{data.planName}</td>
                                <td> {data.price}</td>
                                <td> {data.tax}</td>
                                <td> {data.total}</td>
                                <td>
                                  {data.status == true ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    className="m-1"
                                    color="warning"
                                    outline
                                    onClick={() => {
                                      redirectdata1(data)
                                    }}
                                    title="View"
                                  >
                                    <i className="fas fa-eye"></i> View
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="table-rep-plugin table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Date</th>
                              <th>Expiry Date</th>
                              <th>User Id</th>
                              <th>User Name</th>
                              <th>Plan Name</th>
                              <th>Price</th>
                              <th>Coupon </th>
                              <th>Convenience fee </th>
                              <th>Total </th>
                              <th>Status</th>
                              <th style={{ width: "120px" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists1.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <th>{(pageNumber1 - 1) * 10 + key + 10}</th>
                                <th>{data.startDate}</th>
                                <th>{data.endDate}</th>
                                <th>
                                  <a
                                    onClick={() => {
                                      Agentid1(data)
                                    }}
                                  >
                                    #{data.subscriberNo}
                                  </a>
                                </th>
                                <th>{data.subscriberName}</th>
                                <td>{data.planName}</td>
                                <td> {data.price}</td>
                                <td> {data.couponAmount}</td>
                                <td> {data.tax}</td>
                                <td> {data.total}</td>
                                <td>
                                  {data.status == true ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    className="m-1"
                                    color="warning"
                                    outline
                                    onClick={() => {
                                      redirectdata1(data)
                                    }}
                                    title="View"
                                  >
                                    <i className="fas fa-eye"></i>
                                    View
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount1}
                            onPageChange={changePage1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists1.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
