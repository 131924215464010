
import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { baseurl } from "Baseurls"

function Country() {
    const [modal_small, setmodal_small] = useState(false)
    const [banner, setbanner] = useState([])
    const [form, setform] = useState([])
    const [form1, setform1] = useState([])

    const handleChange = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    useEffect(() => {
        Getalldep()
        GetMaintenance()
    }, [])

    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token

    const Getalldep = () => {
        var token = datas
        axios
            .post(baseurl + 'tax/getTax', {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(
                res => {
                    setform(res.data.tax)
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast(error.response.data.message)
                    }
                }
            )
    }

    const formSubmit = (e) => {
        e.preventDefault()
        var token = datas
        const dataArray = new FormData()
        dataArray.append("charges", form.charges)
        dataArray.append("chargesUS", form.chargesUS)
        dataArray.append("chargesAus", form.chargesAus)

        axios
            .put(baseurl + 'tax/updateTax', dataArray, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(
                res => {
                    if (res.status === 200) {
                        toast.success(res.data.message)
                        Getalldep()
                    }
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.message)
                    }
                }
            )
    }

    const GetMaintenance = () => {
        var token = datas
        axios
            .post(baseurl + 'maintenance/getmaintenance', {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setform1(res.data.maintenance)
            })
    }

    const handleChange123 = (e) => {
        const myData = { ...form1 };
        myData[e.target.name] = e.target.value;
        setform1(myData)

    }
    const formSubmit123 = (e) => {
        e.preventDefault()
        var token = datas
        const bodydata = {
            AUSCoinValue: form1.AUSCoinValue,
            INRCoinValue: form1.INRCoinValue,
            USCoinValue: form1.USCoinValue,
        }
        axios
            .put(baseurl + 'maintenance/editmaintenance', bodydata, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(
                res => {
                    if (res.status === 200) {
                        toast.success(res.data.message)
                        GetMaintenance()
                    }
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.message)
                    }
                }
            )
    }

    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    // const roles = data.rolesAndPermissions
    // const roles1 = data.user.role

    return (
        <div>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Allude" breadcrumbItem="Convenience Fee" />

                        <Row>

                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Form
                                            onSubmit={e => {
                                                formSubmit(e)
                                            }}
                                        >
                                            <Row>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            India Tax (₹) <span className="text-danger">*</span>
                                                        </Label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Convenience Fee"
                                                            required
                                                            name="charges"
                                                            value={form.charges}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            US Tax ($) <span className="text-danger">*</span>
                                                        </Label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Convenience Fee"
                                                            required
                                                            name="chargesUS"
                                                            value={form.chargesUS}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                        Australia Tax ($) <span className="text-danger">*</span>
                                                        </Label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Convenience Fee"
                                                            required
                                                            name="chargesAus"
                                                            value={form.chargesAus}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div style={{ float: "right" }}>
                                                        <Button className="mt-4" color="success" type="submit">
                                                            Submit <i className="fas fa-check-circle"></i>
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>

                        <Row>
                            <h5><b>Coin Prices </b></h5>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Form
                                            onSubmit={e => {
                                                formSubmit123(e)
                                            }}
                                        >
                                            <Row>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            India <span className="text-danger">*</span> <small className="text-danger"> (Ex:20 Coins =  ₹ 1) </small>
                                                        </Label>
                                                        <input
                                                            type="number" 
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Coin Value"
                                                            required
                                                            name="INRCoinValue"
                                                            value={form1.INRCoinValue}
                                                            onChange={e => {
                                                                handleChange123(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            US<span className="text-danger">*</span> <small className="text-danger"> (Ex:20 Coins = $ 1)</small>
                                                        </Label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Coin Value"
                                                            required
                                                            name="USCoinValue"
                                                            value={form1.USCoinValue}
                                                            onChange={e => {
                                                                handleChange123(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Australia <span className="text-danger">*</span> <small className="text-danger"> (Ex:20 Coins = $ 1)</small>
                                                        </Label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Coin Value"
                                                            required
                                                            name="AUSCoinValue"
                                                            value={form1.AUSCoinValue}
                                                            onChange={e => {
                                                                handleChange123(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div style={{ float: "right" }}>
                                                        <Button className="mt-4" color="success" type="submit">
                                                            Submit <i className="fas fa-check-circle"></i>
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>

                    </Container>

                    <ToastContainer />
                </div>
            </React.Fragment>
        </div>
    )
}

export default Country  