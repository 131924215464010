import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Sample from "../../assets/images/sample.csv"
import { baseurl } from "Baseurls"

function AddVendors() {
  const [form, setform] = useState([])

  const [text1, setText1] = useState([])

  const [Files, setFiles] = useState("")

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "xlsx" || type == "XLSX" || type == "CSV" || type == "csv") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()

    Adddealer()
  }

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("message", text1)
    dataArray.append("subject", form.subject)

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("file", Files[i])
    }

    axios
      .post(
        baseurl + "mails/sendMail",
        // "http://193.203.160.181:5005/v1/alludeapi/admin/mails/sendMail",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push(
              "/sendmail",
              sessionStorage.setItem(
                "tost",
                "Mail has been Sended successfully"
              )
            )
            clearForm()
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      subject: "",
    })
    setFiles({
      file: "",
    })
    setText1("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude" breadcrumbItem="Send Mail" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Subject <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Subject"
                        required
                        value={form.subject}
                        name="subject"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Users{" "}
                        <span className="text-danger">
                          (Choose Excel File)
                          <a
                            href={Sample}
                            className="btn btn-danger btn-sm "
                            id="buttonk"
                            style={{
                              float: "right",
                              fontSize: "12px",
                              marginLeft: "10px",
                            }}
                          >
                            <span
                              className="bx bx-cloud-download"
                              style={{ fontSize: "12px" }}
                            ></span>{" "}
                            Sample File
                          </a>{" "}
                        </span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter file"
                        required
                        name="file"
                        value={Files.file}
                        onChange={changeHandler}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <CKEditor
                      editor={ClassicEditor}
                      id="header"
                      data={text1}
                      onReady={editor => {
                        console.log("Editor is ready to use!", editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setText1(data)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="mb-4 mt-2" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-primary m-1"
                      >
                        Submit <i className="fas fa-check-circle"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>{" "}
          </Form>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default AddVendors
