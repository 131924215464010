import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Spinner,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import { URLS } from "../../Url";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import { imgUrl, baseurl } from "Baseurls"

function Ventures() {
  const [show2, setshow2] = useState(false)
  const [show3, setshow3] = useState(false)
  const [show4, setshow4] = useState(false)
  const toggle = () => setshow3(!show3)
  const toggle1 = () => setshow4(!show4)
  const [doctor, setdoctor] = useState([])
  const history = useHistory()

  const GetAllDoctors = () => {
    var token = datas
    axios
      .post(baseurl + 'doctor/getblockeddoctors', {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.doctors)
      })
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        baseurl + 'doctor/getblockeddoctors?searchQuery=' + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.doctors)
      })
  }

  useEffect(() => {
    GetAllDoctors()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = doctor.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(doctor.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }



  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [datastate, setDatastate] = useState([])

  let dates = [];

  const handleDateChange = (NewDate) => {
    console.log(NewDate[0]);

    const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
    const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
    dates.push(date1);
    dates.push(date2);
    setDatastate(dates);
  };

  const redirectdata = (data) => {
    sessionStorage.setItem('docid', data._id)
    history.push('/ViewDoctor')
  }

  const [formss, setformss] = useState([])
  
  const Viewpopup = (data) => {
    setformss(data)
    setshow3(true)
  }

  const BlockDoctor = (e) => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      status: true,
      blockedReason: "",
    }
    axios
      .post(baseurl + 'doctor/blockunblockdoctor/' + formss._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setshow3(false)
            GetAllDoctors()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Blocked Doctors" />
          {show2 == true ? (
            <Card className="p-4">
              <Form
                onSubmit={(e) => {
                  filterCustomer(e)
                }}
              >
                <Row>
                  <Col md="3">
                    <div className="mb-4">
                      <Label>Date Range</Label>

                      <Flatpickr
                        required
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                        name="date"
                        // value={selectedDate}
                        onChange={(e) => { handleDateChange(e) }}
                      />
                    </div>
                  </Col>


                  <Col md="3">
                    <div className="text-end mt-4">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow2(!show2)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          ) : (
            ""
          )}

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                    </Col>
                    <Col md="6">
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={search.search}
                          onChange={searchAll}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                      {/* <Button style={{ float: "right", marginRight: "10px", marginLeft: "10px" }} onClick={() => { setshow2(!show2) }} color="warning">
                        Filter <i className="bx bx-filter-alt"></i>
                      </Button>
                      <div className="text-end mt-2">
                        <b>Doctor's Count: 01</b>
                      </div> */}
                    </Col>
                  </Row>

                  <div className="table-rep-plugin table-responsive">
                    <Table hover className="table table-bordered mb-4 mt-2">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Date</th>
                          <th>Doctor's Id</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Reason</th>
                          {/* <th>Status</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">

                            <td>{(pageNumber - 1) * 10 + key + 11}</td>
                            <td>{data.date}</td>
                            <td>
                              <a className="text-primary" onClick={() => { redirectdata(data) }}>{data.doctorId}</a>
                            </td>
                            <td>{data.name}</td>
                            <td>{data.phone}</td>
                            <td>{data.blockedReason}</td>
                            <td>
                              {/* <Link to="/ViewDoctor"> */}
                              <Button
                                onClick={() => { redirectdata(data) }}
                                size="sm"
                                className="m-1"
                                outline
                                color="warning"
                                title="View"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="fas fa-eye"
                                ></i> View
                              </Button>
                              {/* </Link> */}

                              <Button
                                onClick={() => { Viewpopup(data) }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                                title="Unblock"
                              >
                                <i
                                  className="bx bx-shuffle"
                                ></i> Unblock
                              </Button>
                              {/* <Button
                              onClick={toggle}
                              size="sm"
                              className="m-1"
                              outline
                              color="success"
                            >
                              <i
                                // style={{ fontSize: " 14px" }}
                                className="bx bx-shuffle"
                              ></i> Unblock
                            </Button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                  {/* </>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={show3} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Doctor Unblock</ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  BlockDoctor(e)
                }}
              >

                <div className="text-center mt-3 mb-4">
                  <div>
                    <i style={{ fontSize: "100px" }} className="bx bx-info-circle text-warning" />
                    <h3>Do you want to unblock this doctor.</h3>
                  </div>
                </div>

                <div className="text-center">
                  <Button
                    type="button"
                    onClick={() => {
                      setshow3(!show3)
                    }}
                    style={{ width: "100px" }}
                    color="danger m-1"
                    outline
                  >
                    Cancel <i className="bx bx-x-circle"></i>
                  </Button>
                  <Button style={{ width: "100px" }} type="submit" color="success m-1" outline>
                    Yes <i className="bx bx-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
