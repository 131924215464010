import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Nav,
  Table,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { baseurl } from "Baseurls"

function Ventures() {
  const [activeTab1, setactiveTab1] = useState("1")

  const [form1] = useState([])

  const [form2] = useState([])

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [Agent, setAgent] = useState([])

  const [Agent1, setAgent1] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getSubscriptions()
    getSubscriptions1()
  }, [])

  const redirectdata = data => {
    sessionStorage.setItem("subid", data._id)
    history.push("/EditSubscription")
  }

  const redirectdata1 = data => {
    sessionStorage.setItem("subid", data._id)
    history.push("/ViewSubscription")
  }

  const getSubscriptions = () => {
    var token = datas
    axios
      .post(
        baseurl + "subscription/getAllDoctor",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.subscriptions)
      })
  }

  const getSubscriptions1 = () => {
    var token = datas
    axios
      .post(
        baseurl + "subscription/getAllUser ",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent1(res.data.subscriptions)
      })
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const pagesVisited = pageNumber * listPerPage
  const lists = Agent.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Agent.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [listPerPage1] = useState(10)
  const [pageNumber1, setPageNumber1] = useState(0)
  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = Agent1.slice(pagesVisited1, pagesVisited1 + listPerPage1)
  const pageCount1 = Math.ceil(Agent.length / listPerPage)
  const changePage1 = ({ selected1 }) => {
    setPageNumber1(selected1)
  }

  const Search = e => {
    const token = datas

    axios
      .post(
        baseurl +
          "subscription/getAllDoctor" +
          "?searchQuery=" +
          `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setAgent(res.data.subscriptions)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const Search1 = e => {
    const token = datas

    axios
      .post(
        baseurl +
          "subscription/getAllUser" +
          "?searchQuery=" +
          `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setAgent1(res.data.subscriptions)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Plans" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <div className="mt-2">
                            <Nav pills className="navtab-bg nav-justified">
                              <NavItem className="border border-primary rounded m-1">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab1 === "1",
                                  })}
                                  onClick={() => {
                                    toggle1("1")
                                  }}
                                >
                                  Doctor Subscriptions
                                </NavLink>
                              </NavItem>
                              <NavItem className="border border-primary rounded m-1">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab1 === "2",
                                  })}
                                  onClick={() => {
                                    toggle1("2")
                                  }}
                                >
                                  User Subscriptions
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6" className="mt-2">
                      <div style={{ float: "right" }}>
                        <Link to="/AddSubscription">
                          <Button
                            style={{ float: "right" }}
                            color="primary"
                            title="New"
                          >
                            New Plan <i className="bx bx-plus-circle"></i>
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row className="mb-3">
                        <Col md="12">
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={Search}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Plan Name</th>
                              <th>Price</th>
                              <th>Discount</th>
                              <th>Duration</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                <td>{data.planName}</td>
                                <td> {data.price}</td>
                                <td>{data.discount} %</td>
                                <td>{data.duration}</td>
                                <td>
                                  {data.status == true ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      redirectdata1(data)
                                    }}
                                    className="m-1"
                                    color="warning"
                                    outline
                                    title="View"
                                  >
                                    <i className="fas fa-eye"></i> View
                                  </Button>

                                  <Button
                                    size="sm"
                                    className="m-1"
                                    onClick={() => {
                                      redirectdata(data)
                                    }}
                                    color="success"
                                    outline
                                    title="Edit"
                                  >
                                    <i className="bx bx-edit "></i> Edit
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="table-rep-plugin table-responsive">
                        <Row className="mb-3">
                          <Col md="12">
                            <div style={{ float: "right" }}>
                              <Input
                                name="search"
                                value={form2.search}
                                onChange={Search1}
                                type="search"
                                placeholder="Search..."
                              />
                            </div>
                          </Col>
                        </Row>
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Plan Name</th>
                              <th>Price</th>
                              <th>Discount</th>
                              <th>Duration</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists1.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                <td>{data.planName}</td>
                                <td> {data.price}</td>
                                <td>{data.discount} %</td>
                                <td>{data.duration}</td>
                                <td>
                                  {data.status == true ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      redirectdata1(data)
                                    }}
                                    className="m-1"
                                    color="warning"
                                    outline
                                    title="View"
                                  >
                                    <i className="fas fa-eye"></i> View
                                  </Button>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      redirectdata(data)
                                    }}
                                    className="m-1"
                                    color="success"
                                    outline
                                    title="Edit"
                                  >
                                    <i className="bx bx-edit "></i> Edit
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount1}
                            onPageChange={changePage1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists1.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
