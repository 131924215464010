import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Input,
  Container,
  Table,
  Button,
  Form,
  Modal,
  Label,
  Row,
  Col,
} from "reactstrap"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useHistory } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { baseurl, imgUrl } from "Baseurls"

const List = () => {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  const [modal_small1, setmodal_small1] = useState(false)
  const [modal_small2, setmodal_small2] = useState(false)
  const [form, setform] = useState([])
  const [blogs, setblogs] = useState([])
  const [text1, setText1] = useState([])
  function tog_small() {
    setmodal_small(!modal_small)
  }
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  function tog_small2() {
    setmodal_small2(!modal_small2)
  }

  const getpopup = data => {
    setform(data)
    tog_small()
  }
  const getpopup1 = data => {
    setform(data)
    tog_small1()
  }
  const getpopup2 = data => {
    setform(data)
    setText1(data.description)
    tog_small2()
  }
  const redirectdata = data => {
    sessionStorage.setItem("blogid", data._id)
    history.push("/Viewblog")
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handlechange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        baseurl + "blog/getAll",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setblogs(res.data.blogs)
      })
  }
  useEffect(() => {
    GetAllBanners()
  }, [])

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = blogs.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(blogs.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const updateStatus = e => {
    e.preventDefault()
    var token = datas
    const bosydata = {
      status: form.status,
    }
    axios
      .put(baseurl + "blog/updatestatus/" + form._id, bosydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setmodal_small(false)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const updatePostions = e => {
    e.preventDefault()
    var token = datas
    const bosydata = {
      position: form.position,
    }
    axios
      .put(baseurl + "blog/updateblogposition/" + form._id, bosydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setmodal_small1(false)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }
  const redirectdata123 = data => {
    sessionStorage.setItem("docid", data.doctorId)
    history.push("/ViewDoctor")
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        baseurl + "blog/getAll?searchQuery=" + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setblogs(res.data.blogs)
      })
  }
  const [Files5, setFiles5] = useState("")
  const handleImage = e => {
    setFiles5(e.target.files)
  }

  const editblog = e => {
    e.preventDefault()
    var token = datas
    // var formid = form._id
    const dataArray = new FormData()
    dataArray.append("specialisation", form.specialisation)
    dataArray.append("description", text1)
    dataArray.append("heading", form.heading)
    for (let i = 0; i < Files5.length; i++) {
      dataArray.append("image", Files5[i])
    }
    axios
      .post(
        "https://api.alludeapp.com/v1/alludeapi/admin/blog/editblog/" +
          form._id,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setmodal_small(false)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(
        `https://api.alludeapp.com/v1/alludeapi/admin/blog/deleteBlog/${remid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctor Blog list" />
          <Card>
            <CardBody>
              <Row>
                <Col md="6" />
                <Col md="6">
                  <div style={{ float: "right" }}>
                    <Input
                      type="search"
                      className="form-control"
                      placeholder="Search.."
                      value={search.search}
                      onChange={searchAll}
                      name="search"
                    />
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table className="table table-bordered mb-2 mt-3">
                  <thead>
                    <tr>
                      <th>SlNo</th>
                      <th>Doctor Id </th>
                      <th>Doctor Name </th>
                      <th>Blog Name </th>
                      <th>Image</th>
                      <th>Status</th>
                      <th>Blog Position</th>
                      <th>Action</th>
                      {/* <th>Speciality </th>
                      <th>Join Date </th> */}
                      {/* <th style={{ width: "100px" }}>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {lists.map((data, key) => {
                      return (
                        <>
                          <tr key={key}>
                            <td>{(pageNumber - 1) * 10 + key + 11}</td>

                            <td>
                              <a
                                className="text-primary"
                                onClick={() => {
                                  redirectdata123(data)
                                }}
                              >
                                # {data.generatedDoctorId}
                              </a>
                            </td>
                            <td>{data.doctorName} </td>
                            <td>{data.heading} </td>
                            <td>
                              <img
                                src={imgUrl + data.image}
                                width={70}
                                height={50}
                              />{" "}
                            </td>
                            <td>{data.status} </td>
                            <td>
                              <Button
                                className="mr-2"
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="info"
                                outline
                                disabled={data.status != "approved"}
                                onClick={() => {
                                  getpopup1(data)
                                }}
                                title="Blog Position"
                              >
                                <i className="bx bx-repost "></i>
                              </Button>
                            </td>

                            <td>
                              <Button
                                className="mr-2"
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                // disabled={data.status != "pending"}
                                color="dark"
                                outline
                                onClick={() => {
                                  getpopup(data)
                                }}
                                title="Blog Status"
                              >
                                <i className="bx bx-select-multiple "></i>
                              </Button>
                              {/* <Link to="/Viewblog"> */}
                              <Button
                                className="mr-2"
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="warning"
                                outline
                                onClick={() => {
                                  redirectdata(data)
                                }}
                                title="View"
                              >
                                <i className="bx bx-show-alt"></i>
                              </Button>
                              {/* </Link> */}

                              <Button
                                className="mr-2"
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="success"
                                outline
                                onClick={() => {
                                  getpopup2(data)
                                }}
                              >
                                <i className="bx bx-edit"></i>
                              </Button>

                              <Button
                                className="mr-2"
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="danger"
                                outline
                                onClick={() => {
                                  manageDelete(data)
                                }}
                              >
                                <i className="bx bx-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </Table>
                <div className="mt-3" style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    total={lists.length}
                  />
                </div>
              </div>
            </CardBody>
          </Card>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Blog Status
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  updateStatus(e)
                }}
              >
                <div>
                  <Label>
                    Status<span className="text-danger"> *</span>
                  </Label>
                  <div>
                    <select
                      className="form-select"
                      required
                      value={form.status}
                      name="status"
                      onChange={e => {
                        handleChange(e)
                      }}
                    >
                      <option value="">Select</option>
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="reject">Reject</option>
                    </select>
                  </div>
                  <div className="mt-3">
                    <Button
                      onClick={() => {
                        setmodal_small(false)
                      }}
                      style={{ float: "right" }}
                      className="m-1"
                      color="danger"
                      type="button"
                    >
                      Cancel <i className="fas fa-times-circle"></i>
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="m-1"
                      color="success"
                      type="submit"
                    >
                      Submit <i className="fas fa-check-circle"></i>
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal
            size="md"
            isOpen={modal_small1}
            toggle={() => {
              tog_small1()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Blog Position
              </h5>
              <button
                onClick={() => {
                  setmodal_small1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  updatePostions(e)
                }}
              >
                <div>
                  <Label>
                    Status<span className="text-danger"> *</span>
                  </Label>
                  <div>
                    <select
                      className="form-select"
                      required
                      value={form.position}
                      name="position"
                      onChange={e => {
                        handleChange(e)
                      }}
                    >
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <div className=" mt-3">
                    <Button
                      onClick={() => {
                        setmodal_small1(false)
                      }}
                      style={{ float: "right" }}
                      className="m-1"
                      color="danger"
                      type="button"
                    >
                      Cancel <i className="fas fa-times-circle"></i>
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      className="m-1"
                      color="success"
                      type="submit"
                    >
                      Submit <i className="fas fa-check-circle"></i>
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal
            size="md"
            isOpen={modal_small2}
            toggle={() => {
              tog_small2()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Blog
              </h5>
              <button
                onClick={() => {
                  setmodal_small2(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  editblog(e)
                }}
              >
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Heading <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter heading"
                        required
                        value={form.heading}
                        name="heading"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Image <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        placeholder="Enter Title"
                        name="image"
                        onChange={e => {
                          handleImage(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Specialisation <span className="text-danger">*</span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter specialisation"
                        required
                        value={form.specialisation}
                        name="specialisation"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <Label> Description</Label>
                    <CKEditor
                      editor={ClassicEditor}
                      id="header"
                      data={text1}
                      onReady={editor => {
                        console.log("Editor is ready to use!", editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setText1(data)
                      }}
                    />

                    {/* <textarea
                      value={form.description}
                      name="description"
                      required
                      onChange={e => {
                        handlechange(e)
                      }}
                      className="form-control"
                      placeholder="Enter description"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    ></textarea> */}
                  </Col>
                </Row>
                <div className=" mt-3">
                  <Button
                    onClick={() => {
                      setmodal_small2(false)
                    }}
                    style={{ float: "right" }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button
                    style={{ float: "right" }}
                    className="mx-2"
                    color="success"
                    type="submit"
                    onClick={() => {
                      setmodal_small2(false)
                    }}
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default List
