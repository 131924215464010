import React, { useState, useRef } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  Label,
  Form
} from "reactstrap"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import avatar1 from "../../assets/images/users/avatar-2.jpg"

function Ventures() {
  const [form2, setform2] = useState([]);
  const [show, setShow] = useState(false);

  const [activeTab1, setactiveTab1] = useState("1")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }


  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const addUsers = () => {
    var params = {

      Fromdate: form.Fromdate,
      Todate: form.Todate,
    }
    alert(JSON.stringify(params));

  }

  const usersSubmit = (e) => {
    e.preventDefault();
    addUsers();
  };

  //model
  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [modal_small1, setmodal_small1] = useState(false)

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }


  const [form, setform] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const [Agent, setAgent] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  // useEffect(() => {
  //   getAgent()
  //   datass()
  // }, [])

  const getAgent = () => {
    var token = datas
    axios
      .post(
        URLS.GetVenture,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.ventureResult)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Agent.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Agent.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Agentid1 = data => {
    sessionStorage.setItem("Ventureid", data._id)
    history.push("/ViewVenture")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    console.log(token)

    axios
      .post(
        URLS.GetVentureSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setAgent(res.data.ventureResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.InActiveVenture + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getAgent()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  // const roles = data.rolesAndPermissions
  // const roles1 = data.user.role

  const Agentid = () => {
    history.push("/ViewBooking")
  }
  const getpopup = () => {
    tog_small()
  }

  const getpopup1 = () => {
    tog_small1()
  }

  const getpopup4 = () => {
    stopStopwatch()
    setmodal_small(false)
  }

  const getpopup5 = () => {
    startStopwatch()
    setmodal_small1(false)
  }
  const [isRunning, setIsRunning] = useState(false)
  const [time, setTime] = useState(0)
  const intervalRef = useRef(null)

  const startStopwatch = () => {
    if (!isRunning) {
      setIsRunning(true)
      intervalRef.current = setInterval(() => {
        setTime(prevTime => prevTime + 10)
      }, 10) // Update time every 10 milliseconds (adjust as needed)
    }
  }

  const stopStopwatch = () => {
    clearInterval(intervalRef.current)
    setIsRunning(false)
    setmodal_small(false)
  }

  const resetStopwatch = () => {
    clearInterval(intervalRef.current)
    setIsRunning(false)
    setTime(0)
  }

  const formatTime = timeInMilliseconds => {
    const minutes = Math.floor(timeInMilliseconds / 60000)
    const seconds = Math.floor((timeInMilliseconds % 60000) / 1000)
    const milliseconds = (timeInMilliseconds % 1000) / 10

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}:${milliseconds.toString().padStart(2, "0")}`
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Allude"
            breadcrumbItem="Online Op's"
          />
          <Row>

            <Col >
             
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                    <div style={{ float: "left" }}>
                    <Input type="date" />
                    </div>
                    </Col>

                    {/* <Col>
                      <Button type='button' style={{ color: 'white', width: '100px', backgroundColor: '#078B8B', fontSize: '15px', }} onClick={() => setShow(!show)}>Filter <i className="fas fa-filter"></i></Button>
                    </Col> */}
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form1.search}
                          onChange={Search}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>

                    </Col>
                  </Row>
                
                    <div className="table-rep-plugin  table-responsive">
                    <Table hover className="table table-bordered mb-4 mt-2">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Booking Id</th>
                          <th>Doc Id</th>
                          <th>Doc Name</th>
<th>User Id</th>
                          {/* <th>User Profile</th> */}
                          <th>Full Name</th>
                          <th>Phone</th>
                          {/* <th>Mail Id</th> */}
                          <th>Date & Time</th>
                          <th>Problem </th>
                          <th>Type </th>
                          <th>Amount </th>
                          <th>Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          <td>1</td>
                          <td>Book1234</td>
                          <th><Link to="/ViewDoctor">#Do1312</Link></th>
                              <th>shanker</th>
                              <th><Link to="/ViewUser">#UI432432</Link></th>
                          {/* <td>
                            <img src={avatar1} alt="" width="50px" />
                          </td> */}
                          <td>Satesh Punna</td>
                          <td>9900887766</td>
                          {/* <td>sateeshpunna@gmail.com</td> */}
                          <td>22-11-2023 - 10:30 AM</td>
                          <td>Fever</td>
                          <td>Video</td>
                          <td> 200</td>
                          {/* <td>Accept</td> */}
                          <td>
                            <Button
                              className="mr-2"
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                Agentid()
                              }}
                              color="info"
                              outline
                            >
                              <i className="fas fa-eye"></i>
                            </Button>
                            {/* <Button
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                getpopup()
                              }}
                              color="danger"
                              outline
                            >
                              <i className="fas fa-times-circle"></i>
                            </Button> */}
                            
                            {/* <Button
                              className="mr-2"
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                getpopup1()
                              }}
                              color="success"
                              outline
                            >
                              <i className="bx bxs-user-check "></i>
                            </Button>
                            <Button
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                getpopup()
                              }}
                              color="danger"
                              outline
                            >
                              <i className="bx bxs-file-doc"></i>
                            </Button> */}

                          </td>
                        </tr>

                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                  {/* </>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div style={{borderBottom:"0px"}} className="modal-header">
              {/* <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Add Prescription
              </h5> */}
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <div className="text-center">
                <i style={{fontSize:"100px"}} className="bx bx-info-circle text-warning" />
                <h5>Do you want reject this booking</h5>
              </div>
             
              <div className="text-center mt-3">
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Yes <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            size="md"
            isOpen={modal_small1}
            toggle={() => {
              tog_small1()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Status
              </h5>
              <button
                onClick={() => {
                  setmodal_small1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label>
                  Employee Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Employee Name "
                  required
                  value={form.employeeName}
                  name="employeeName"
                  onChange={e => {
                    handleChange(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <label>
                  Employee Id <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Employee Id "
                  required
                  value={form.employeeId}
                  name="employeeId"
                  onChange={e => {
                    handleChange(e)
                  }}
                />
              </div>

              <div className="mb-3">
                <label> Status</label> <span className="text-danger">*</span>
                <select
                  value={form.status}
                  name="status"
                  onChange={e => {
                    handleChange(e)
                  }}
                  required
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="Accept">Accept</option>
                  <option value="Deny">Deny</option>
                </select>
              </div>
              {form.status == "Deny" ? (
                <div className="mb-3">
                  <label>
                    Description <span className="text-danger">*</span>
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description "
                    required
                    rows={3}
                    onChange={e => {
                      handleChange(e)
                    }}
                    name="description"
                    value={form.description}
                  />
                </div>
              ) : (
                ""
              )}

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button
                  className="m-1"
                  color="info"
                  onClick={() => {
                    getpopup5()
                  }}
                >
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
