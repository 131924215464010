import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Nav,
  Table,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { baseurl } from "Baseurls"

function Ventures() {
  const [activeTab1, setactiveTab1] = useState("1")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [subdoc, setsubdoc] = useState([])
  const [subdoc1, setsubdoc1] = useState([])
 
  const history = useHistory()
  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const [listPerPage1] = useState(10)
  const [pageNumber1, setPageNumber1] = useState(0)

  useEffect(() => {
    getSubDoc()
    getSubUser()
  }, [])

  const getSubDoc = () => {
    var token = datas
    axios
      .post(
        baseurl + "getalldoctordevices",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc(res.data.doctorDevices)
      })
  }

  const getSubUser = () => {
    var token = datas
    axios
      .post(
        baseurl + "user/getactiveusers",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc1(res.data.users)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = subdoc.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(subdoc.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = subdoc1.slice(pagesVisited1, pagesVisited1 + listPerPage1)
  const pageCount1 = Math.ceil(subdoc1.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    console.log(token)

    axios
      .post(
        baseurl + "getalldoctordevices?searchQuery=" + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setsubdoc(res.data.doctorDevices)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Search1 = e => {
    const token = datas
    axios
      .post(
        baseurl + "user/getactiveusers?searchQuery=" + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setsubdoc1(res.data.users)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const redirectdata = data => {
    sessionStorage.setItem("docid", data.doctorObjId)
    history.push("/ViewDoctor")
  }

  const Agentid1 = data => {
    sessionStorage.setItem("userid", data._id)
    history.push("/ViewUser")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Devices" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <Row>
                        <Col md="12">
                          <div className="mt-2">
                            <Nav pills className="navtab-bg nav-justified">
                              <NavItem className="border border-primary rounded m-1">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab1 === "1",
                                  })}
                                  onClick={() => {
                                    toggle1("1")
                                  }}
                                >
                                  Doctor's
                                </NavLink>
                              </NavItem>
                              <NavItem className="border border-primary rounded m-1">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab1 === "2",
                                  })}
                                  onClick={() => {
                                    toggle1("2")
                                  }}
                                >
                                  User's
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="8" className="mt-2 text-end">
                      {activeTab1 == "1" ? (
                        <div style={{ float: "right" }}>
                          <Input
                            name="search"
                            value={form1.search}
                            onChange={Search}
                            type="search"
                            placeholder="Search..."
                          />
                        </div>
                      ) : (
                        <div style={{ float: "right" }}>
                          <Input
                            name="search"
                            onChange={e => {
                              Search1(e)
                            }}
                            type="search"
                            placeholder="Search..."
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <div className="table-rep-plugin table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Doc Id</th>
                              <th>Doc Name</th>
                              <th>Device Id</th>
                              <th>Web Device Id</th>
                              <th>Device Name</th>
                              <th>Web Device Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                <th className="text-primary">
                                  <a
                                    onClick={() => {
                                      redirectdata(data)
                                    }}
                                  >
                                    # {data.doctorId}
                                  </a>
                                </th>
                                <th>{data.doctorName}</th>
                                <td>{data.deviceId}</td>
                                <td>{data.webDeviceId}</td>
                                <td>{data.deviceName}</td>
                                <td>{data.webDeviceName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </TabPane>

                    <TabPane tabId="2">
                      <div className="table-rep-plugin table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>User Id</th>
                              <th>User Name</th>
                              <th>Device Id</th>
                              <th>Device Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists1.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <th>{(pageNumber1 - 1) * 10 + key + 11}</th>
                                <th className="text-primary">
                                  <a
                                    onClick={() => {
                                      Agentid1(data)
                                    }}
                                  >
                                    # {data.userId}
                                  </a>
                                </th>
                                <th>{data.name}</th>
                                <th>{data.deviceId}</th>
                                <td>{data.deviceName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount1}
                            onPageChange={changePage1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists1.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
