import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Spinner,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import { URLS } from "../../Url";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import { baseurl, imgUrl } from "Baseurls";

function Ventures() {
  const [Agent, setAgent] = useState([])
  const [form, setform] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)
  const [show2, setshow2] = useState(false)
  const [show3, setshow3] = useState(false)
  const toggle = () => setshow3(!show3)
  const history = useHistory()

  const [form1, setform1] = useState([])
  const [form12, setform12] = useState([])

  const handleChange = e => {
    let myUser = { ...form12 }
    myUser[e.target.name] = e.target.value
    setform12(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    getAgent()
    // datass()
  }, [])

  const getAgent = () => {
    var token = datas
    axios
      .post(baseurl + 'user/getblockedusers', {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.users)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Agent.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Agent.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Agentid = (data) => {
    setform12(data)
    toggle()
  }

  const Agentid1 = data => {
    sessionStorage.setItem("userid", data._id)
    history.push("/ViewUser")
  }

  const Search = (e) => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    console.log(token)

    axios
      .post(baseurl + 'user/getblockedusers?searchQuery=' + `${e.target.value}`, {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setAgent(res.data.users)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formeditsubmit = (e) => {
    e.preventDefault()
    var token = datas
    const bodydata ={
      blockedReason:form12.blockedReason,
      status:'false',
    }
    axios
      .put(baseurl + 'user/updateuserstatus/' + form12._id,bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getAgent()
            setshow3()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  // const roles = data.rolesAndPermissions
  // const roles1 = data.user.role

  const [datastate, setDatastate] = useState([])

  let dates = [];

  const handleDateChange = (NewDate) => {
    console.log(NewDate[0]);

    const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
    const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
    dates.push(date1);
    dates.push(date2);
    setDatastate(dates);
  };

  const [formss, setformss] = useState([])
  
  const Viewpopup = (data) => {
    setformss(data)
    setshow3(true)
  }

  const BlockDoctor = (e) => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      status: true,
      blockedReason: "",
    }
    axios
      .put(baseurl + 'user/updateuserstatus/' + formss._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setshow3(false)
            getAgent()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }




  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Blocked Users" />

          {show2 == true ? (
            <Card className="p-4">
              <Form
                onSubmit={(e) => {
                  filterCustomer(e)
                }}
              >
                <Row>
                  <Col md="3">
                    <div className="mb-4">
                      <Label>Date Range</Label>

                      <Flatpickr
                        required
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                        name="date"
                        // value={selectedDate}
                        onChange={(e) => { handleDateChange(e) }}
                      />
                    </div>
                  </Col>


                  <Col md="3">
                    <div className="text-end mt-4">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow2(!show2)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          ) : (
            ""
          )}

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    {/* {roles.ventureAdd === true || roles1 === "superadmin" ? (
                      <> */}
                    <Col md="6">

                      {/* <Button onClick={() => { setshow(!show) }} color="primary">
                        New User <i className="bx bx-plus-circle"></i>
                      </Button> */}

                    </Col>
                    {/* </>
                    ) : (
                      ""
                    )}{" "} */}
                    <Col md="6">

                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form1.search}
                          onChange={Search}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                      {/* <Button style={{ float: "right", marginRight: "10px", marginLeft: "10px" }} onClick={() => { setshow2(!show2) }} color="warning">
                        Filter <i className="bx bx-filter-alt"></i>
                      </Button> */}
                      <div style={{ float: "right", marginRight: "10px", marginLeft: "10px" }}  className="text-end mt-2">
                        <b>Users Count: {Agent.length}</b>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin table-responsive">
                    <Table hover className="table table-bordered mb-4 mt-2">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>User Id</th>
                          <th>Name</th>
                          <th>Image</th>
                          <th>Phone</th>
                          <th>Reason</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th>{(pageNumber - 1) * 10 + key + 11}</th>
                            <td>
                              <a
                                onClick={() => {
                                  Agentid1(data)
                                }} >{data.userId} </a>
                            </td>
                            <td>{data.name}</td>
                            <td>
                              <img src={imgUrl + data.avatar} style={{ width: "70px", height: "70px" }} />
                            </td>
                            <td>{data.phone}</td>
                            <td>{data.blockedReason}</td>
                            <td>{data.status == true ? "Active" : "Blocked"}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  Agentid1(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="warning"
                                title="View"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="fas fa-eye"
                                ></i> View
                              </Button>

                              <Button
                                onClick={() => { Viewpopup(data) }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                                title="Unblock"
                              >
                                <i
                                  className="bx bx-shuffle"
                                ></i> Unblock
                              </Button>


                              {/* <Button
                              onClick={() => {
                                setshow1(!show1)
                              }}
                              size="sm"
                              className="m-1"
                              outline
                              color="success"
                            >
                              <i
                                style={{ fontSize: " 14px" }}
                                className="bx bx-edit"
                              ></i>
                            </Button> */}
                            
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                  {/* </>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={show3} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>User Unblock</ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  BlockDoctor(e)
                }}
              >

                <div className="text-center mt-3 mb-4">
                  <div>
                    <i style={{ fontSize: "100px" }} className="bx bx-info-circle text-warning" />
                    <h3>Do you want to unblock this user.</h3>
                  </div>
                </div>

                <div className="text-center">
                  <Button
                    type="button"
                    onClick={() => {
                      setshow3(!show3)
                    }}
                    style={{ width: "100px" }}
                    color="danger m-1"
                    outline
                  >
                    Cancel <i className="bx bx-x-circle"></i>
                  </Button>
                  <Button style={{ width: "100px" }} type="submit" color="success m-1" outline>
                    Yes <i className="bx bx-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
