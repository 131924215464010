import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Label,
  Input,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { baseurl, imgUrl } from "Baseurls"

function TermsConditions() {
  const [form, setform] = useState([])

  useEffect(() => {
    GetSettings()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetSettings = () => {
    var token = datas
    axios
      .post(
        baseurl + "contact/getcontact",
        // "http://193.203.160.181:5005/v1/alludeapi/admin/contact/getcontact",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.data)
      })
  }

  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [text1, setText1] = useState([])

  const handlechange = e => {
    const myform = { ...text1 }
    myform[e.target.name] = e.target.value
    setText1(myform)
  }

  const [Files, setFiles] = useState("")

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const getpopup1 = () => {
    setText1(form)
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }

  const changstatus = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("content", text1.content)
    dataArray.append("phone", text1.phone)

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }

    axios
      .post(
        baseurl + "contact/updatecontactUs",
        // "http://193.203.160.181:5005/v1/alludeapi/admin/contact/updatecontactUs",

        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setmodal_small(false)
            setFiles({
              image: "",
            })
            GetSettings()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude" breadcrumbItem="Contact Us" />
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white"></CardHeader>
                <CardBody>
                  <div style={{ float: "right" }}></div>
                  <div>
                    <div>
                      <Row>
                        <Col md="6">
                          <h4>Contact Us</h4>
                        </Col>
                        <Col md="6" className="text-end">
                          <Button
                            onClick={() => {
                              getpopup1()
                            }}
                            className=""
                            color="primary"
                            title="Edit Contact Us "
                          >
                            Edit
                          </Button>
                        </Col>
                      </Row>
                      <Table hover className="table table-bordered mb-4 mt-5">
                        <tbody>
                          <tr className="text-center">
                            <th> Image</th>
                            <td>
                              <img
                                src={imgUrl + form.image}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </td>
                          </tr>
                          <tr className="text-center">
                            <th>Content</th>
                            <td>{form.content}</td>
                          </tr>
                          <tr className="text-center">
                            <th>Phone</th>
                            <td>{form.phone}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Contact Us
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <Form
              onSubmit={e => {
                submibooking(e)
              }}
            >
              <div className="modal-body">
                <div className="mb-3">
                  <Label>Content</Label> <span className="text-danger">*</span>
                  <Input
                    onChange={e => {
                      handlechange(e)
                    }}
                    name="content"
                    required
                    type="text"
                    value={text1.content}
                    placeholder="Enter Content"
                  />
                </div>

                <div className="mb-3">
                  <Label>Phone</Label> <span className="text-danger">*</span>
                  <Input
                    onChange={e => {
                      handlechange(e)
                    }}
                    name="phone"
                    required
                    type="text"
                    minLength="10"
                    maxLength="10"
                    pattern="[0-9]+"
                    value={text1.phone}
                    placeholder="Enter Phone"
                  />
                </div>

                <div className="mb-3">
                  <Label>Image</Label> <span className="text-danger">*</span>
                  <Input
                    type="file"
                    className="form-control"
                    name="image"
                    value={Files.image}
                    onChange={changeHandler1}
                  />
                </div>
              </div>

              <hr></hr>
              <div style={{ float: "right" }} className="m-2">
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
              </div>
            </Form>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default TermsConditions
