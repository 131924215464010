import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  FormGroup,
} from "reactstrap"
import classnames from "classnames"
import { ToastContainer, toast } from "react-toastify"
import pback from "../../assets/images/ai2.jpg"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import axios from "axios"
import "react-toastify/dist/ReactToastify.css"
import { URLS } from "../../Url"
import sai from "../../assets/images/sai.jpg"
import { addData, updateData } from "Servicescalls"
import { imgUrl } from "Baseurls"

const UserProfile = () => {
  const [form1, setform1] = useState([])

  const [passwordType, setPasswordType] = useState("password")
  const [passwordType1, setPasswordType1] = useState("password")
  const [passwordType12, setPasswordType12] = useState("password")


  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const [activeTab1, setactiveTab1] = useState("5")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }


  const [admindata, setAdmindata] = useState([])

  const [form, setForm] = useState([])
  const [password, setPassword] = useState({
    password: "",
    newpassword: "",
    confirmpassword: "",
  })

  const handleChange1 = e => {
    const myForm = { ...form }
    myForm[e.target.name] = e.target.value
    setForm(myForm)
  }
  const handleChange = e => {
    const myForm = { ...password }
    myForm[e.target.name] = e.target.value
    setPassword(myForm)
  }

  // get admin
  const getAdmindata = async () => {
    const response = await addData("auth/getProfile")
    var _data = response.data
    console.log(_data)
    setAdmindata(_data.profileResult)
    setForm(_data.profileResult)
  }

    // edit admin

  const editAdmindata = async (e) => {
    e.preventDefault()
    const bodydata = {
      name: form.name,
      email: form.email,
      phone: form.phone,
      address: form.address,
    }
    try {
      const response = await updateData("auth/editprofile", bodydata)
      var _data = response.data
      console.log(_data)
      toast.success(_data.message)
      getAdmindata()
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

      // Edit Password

  const ChangePsw = async (e) => {
    e.preventDefault()
    const bodydata = {
      password: password.password,
      newpassword: password.newpassword,
      confirmpassword: password.confirmpassword,
    }
    try {
      const response = await updateData("auth/changepass", bodydata)
      var _data = response.data
      toast.success(_data.message)
      console.log(response.status)
      getAdmindata()
      cleardata()
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  //Profile pic update

  const [file, setFile]= useState('')

  const fileChange = async (e)=>{
    const files = e.target.files
    setFile(e.target.files)
    const dataArray = new FormData();
    for (let i = 0; i < files.length; i++) {
      dataArray.append("profilePic", files[i]);
    }
    try {
      const response = await updateData("auth/updateprofileImg", dataArray)
      var _data = response.data
      toast.success(_data.message)
      getAdmindata()
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const cleardata = () => {
    setPassword({
      password: "",
      newpassword: "",
      confirmpassword: "",
    })
  }


  useEffect(() => {
    getAdmindata()
  }, [])


  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text")
      return
    }
    setPasswordType1("password")
  }

  const togglePassword12 = () => {
    if (passwordType12 === "password") {
      setPasswordType12("text")
      return
    }
    setPasswordType12("password")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Allude" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="">
                    <Row>
                      <Col md={12}>
                        <div className="text-primary">
                          <h5 className="text-primary"></h5>
                          <p></p>
                        </div>

                        <img
                          style={{ height: "200px", width: "100%" }}
                          src={pback}
                          alt=""
                          className="img-fluid"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col md={2}>
                        <Link to="#" className="auth-logo-light">
                          <div className="avatar-md1 profile-user-wid mb-4">
                            <span className="ml-5">
                              <img
                                src={imgUrl + admindata.profilePic}
                                // src={sai}
                                className="avatar-md1 rounded-circle img-thumbnail"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </span>
                          </div>
                        </Link>
                      </Col>
                      <Col md={4}>
                        <div className="mt-3">
                          <div className="row">
                            <div className="col col-sm-2">
                              <label>Name</label>
                              <br />
                              <label>Email </label>
                            </div>
                            <div className="col  col-sm-10 ">
                              <label>: {admindata.name}</label>
                              <br />
                              <label>: {admindata.email}</label>
                              <br />
                            </div>
                          </div>
                          <Button
                            tag={Label}
                            className="mb-75 me-75 mt-3"
                            size="sm"
                            color="primary"
                          >
                            Upload <i className="fas fa-cloud-upload-alt"></i>
                            <Input
                              name="avatar"
                              type="file"
                              onChange={fileChange}
                              hidden
                              accept="image/*"
                            />
                          </Button>

                          <p></p>
                        </div>
                      </Col> 
                      <Col md={6}>
                        <div className="mt-3">
                          <Nav pills className="navtab-bg nav-justified">
                            <NavItem className="border m-1">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "5",
                                })}
                                onClick={() => {
                                  toggle1("5")
                                }}
                              >
                                My Profile
                              </NavLink>
                            </NavItem>
                            <NavItem className="border m-1">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "6",
                                })}
                                onClick={() => {
                                  toggle1("6")
                                }}
                              >
                                Edit Profile
                              </NavLink>
                            </NavItem>
                            <NavItem className="border m-1">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "7",
                                })}
                                onClick={() => {
                                  toggle1("7")
                                }}
                              >
                                Change Password
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>

                <div className="mb-5">
                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <h5> About</h5>
                           
                            A personal profile is something that gives whoever
                            wants to hire you or needs your services the first
                            impression
                            <br /> that you are the best candidate for this
                            website.These sites take things to the next level.
                            <Row className="mt-4">
                              <Col md={8}>
                                <Row>
                                  <Col md={4}>
                                   
                                    <p>
                                      <b>User Name</b>
                                    </p>
                                    <p>
                                      <b>Email</b>
                                    </p>
                                    <p>
                                      <b>Phone</b>
                                    </p>
                                    <p>
                                      <b>Status</b>
                                    </p>
                                    <p>
                                      <b>Address</b>
                                    </p>
                                  </Col>
                                  <Col md={8}>
                                   
                                    <p>
                                      <b>:</b>
                                      <span> {admindata.name}</span>
                                    </p>
                                    <p>
                                      <b>:</b>
                                      <span> {admindata.email}</span>
                                    </p>
                                    <p>
                                      <b>:</b>
                                      <span> {admindata.phone}</span>
                                    </p>
                                    <p>
                                      <b>:</b>
                                      <span> {admindata.status}</span>
                                    </p>
                                    <p>
                                      <b>:</b>
                                      <span> {admindata.address}</span>
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6}></Col>
                            </Row>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <form
                        onSubmit={e => {
                          editAdmindata(e)
                        }}
                      >
                        <h5>Edit Profile</h5>
                        <Row>
                          
                          <Col md={4} className="mb-3">
                            <Label>
                              User Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="name"
                              placeholder="Enter User Name"
                              type="text"
                              className="form-control"
                              value={form.name}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>

                          <Col md={4} className="mb-3">
                            <Label>
                              Email. <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="email"
                              placeholder="Enter Email"
                              type="email"
                              className="form-control"
                              value={form.email}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>

                          <Col md={4} className="mb-3">
                            <Label>
                              Phone. <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="phone"
                              placeholder="Enter Phone"
                              type="text"
                              minLength="10"
                              maxLength="10"
                              pattern="[0-9]+"
                              className="form-control"
                              value={form.phone}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>
                          <Col md={6}>
                            <Label>
                              Address <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              name="address"
                              placeholder="Enter Address"
                              type="text"
                              className="form-control"
                              value={form.address}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>
                        </Row>

                        <div style={{ float: "right" }}>
                          <Button color="success" type="submit">
                            Submit
                          </Button>
                        </div>
                      </form>
                    </TabPane>
                    <TabPane tabId="7">
                      <form
                        onSubmit={e => {
                          ChangePsw(e)
                        }}
                      >
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <h5>Change Password</h5>

                              <Row className="mt-3">
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Current Password{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div className="input-group">
                                      <input
                                        name="password"
                                        placeholder="Current Password"
                                        className="form-control"
                                        required
                                        type={passwordType}
                                        value={password.password}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                      />
                                      <div className="input-group-btn">
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          onClick={() => {
                                            togglePassword()
                                          }}
                                        >
                                          {passwordType === "password" ? (
                                            <i
                                              className="fa fa-eye-slash"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      New Password{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div className="input-group">
                                      <input
                                        name="newpassword"
                                        placeholder="New Password"
                                        required
                                        type={passwordType1}
                                        className="form-control"
                                        value={password.newpassword}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                      />
                                      <div className="input-group-btn">
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          onClick={() => {
                                            togglePassword1()
                                          }}
                                        >
                                          {passwordType1 === "password" ? (
                                            <i
                                              className="fa fa-eye-slash"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Confirm Password{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div className="input-group">
                                      <input
                                        name="confirmpassword"
                                        placeholder="Confirm Password"
                                        required
                                        type={passwordType12}
                                        className="form-control"
                                        value={password.confirmpassword}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                      />
                                      <div className="input-group-btn">
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          onClick={() => {
                                            togglePassword12()
                                          }}
                                        >
                                          {passwordType12 === "password" ? (
                                            <i
                                              className="fa fa-eye-slash"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardText>
                          </Col>
                        </Row>
                        <div style={{ float: "right" }}>
                          <Button color="success" type="submit">
                            Submit
                          </Button>
                        </div>
                      </form>
                    </TabPane>
                 
                  </TabContent>
                </div>
              </Card>
            </Col>
          </Row>
          {/* </>
          )} */}
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
