import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const Authdetails = localStorage.getItem("authUser")
  const Auth = JSON.parse(Authdetails)
  const access = Auth?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {access?.DashView == true || access?.all == true ? (
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-home-alt"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            <li>
              {access?.roleView == true ||
              access?.staffView == true ||
              access?.all == true ? (
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user-circle"></i>
                  <span>{props.t("Staff Management")}</span>
                </Link>
              ) : (
                ""
              )}
              <ul className="sub-menu">
                {access?.roleView == true || access?.all == true ? (
                  <li>
                    <Link to="/Departments">
                      {props.t("Roles & Permissions")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.staffView == true || access?.all == true ? (
                  <li>
                    <Link to="/Staff">{props.t("Staff")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {/* <li>
                  <Link to="/RolesPremissions">
                    {props.t("")}
                  </Link>
                </li> */}
              </ul>
            </li>

            <li>
              {access?.doctorsView == true ||
              access?.blogsView == true ||
              access?.all == true ? (
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-git-merge"></i>
                  <span>{props.t("Doctors")}</span>
                </Link>
              ) : (
                ""
              )}
              <ul className="sub-menu">
                {/* <li>
                  <Link to="/Specialization">{props.t("Specialization")}</Link>
                </li> */}
                {access?.doctorsView == true || access?.all == true ? (
                  <li>
                    <Link to="/Doctors">{props.t("Doctor List")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.docblock == true || access?.all == true ? (
                  <li>
                    <Link to="/blockeddoctors">
                      {props.t("Blocked Doctors ")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                {access?.blogsView == true || access?.all == true ? (
                  <li>
                    <Link to="/Blog">{props.t("Blog List")}</Link>
                  </li>
                ) : (
                  ""
                )}

                {/* <li>
                  <Link to="/holidays">{props.t("Holidays")}</Link>
                </li> */}
              </ul>
            </li>

            <li>
              {access?.userView == true || access?.all == true ? (
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user-pin"></i>
                  <span>{props.t("User list")}</span>
                </Link>
              ) : (
                ""
              )}
              <ul className="sub-menu">
                {access?.userView == true || access?.all == true ? (
                  <li>
                    <Link to="/User">{props.t("User list")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.opsblock == true || access?.all == true ? (
                  <li>
                    <Link to="/blockedusers">{props.t("Blocked Users")}</Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </li>

            <li>
              {access?.pendingops == true ||
              access?.scheduledopsAdd == true ||
              access?.consultedops == true ||
              access?.cancelops == true ||
              access?.all == true ? (
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Booking")}</span>
                </Link>
              ) : (
                ""
              )}
              <ul className="sub-menu">
                {access?.pendingops == true || access?.all == true ? (
                  <li>
                    <Link to="/pendingops">{props.t("Pending")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.scheduledopsAdd == true || access?.all == true ? (
                  <li>
                    <Link to="/BookingList">{props.t("Scheduled")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.consultedops == true || access?.all == true ? (
                  <li>
                    <Link to="/consulted">{props.t("Consulted")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {/* <li>
                  <Link to="/onlinebooking">{props.t("Online Op's")}</Link>
                </li>
                <li>
                  <Link to="/directbooking">{props.t("Direct Op's")}</Link>
                </li> */}
                {access?.cancelops == true || access?.all == true ? (
                  <li>
                    <Link to="/rejectedbookings">{props.t("Cancelled")}</Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </li>

            <li>
              {access?.SubscriptionView == true ||
              access?.SubscribedAdd == true ||
              access?.Subscribedexp == true ||
              access?.all == true ? (
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-package"></i>
                  <span>{props.t("Subscription")}</span>
                </Link>
              ) : (
                ""
              )}
              <ul className="sub-menu">
                {access?.SubscriptionView == true || access?.all == true ? (
                  <li>
                    <Link to="/Subscription">{props.t("Plans")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.SubscribedAdd == true || access?.all == true ? (
                  <li>
                    <Link to="/subscribed">{props.t("Subscribed")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.Subscribedexp == true || access?.all == true ? (
                  <li>
                    <Link to="/expiringsubscribers">
                      {props.t("Expiring Subscribers")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </li>

            <li>
              {access?.subpayments == true ||
              access?.paytran == true ||
              access?.dcpayments == true ||
              access?.relesepayments == true ||
              access?.wallet == true ||
              access?.all == true ? (
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-rupee"></i>
                  <span>{props.t("Payments")}</span>
                </Link>
              ) : (
                ""
              )}
              <ul className="sub-menu">
                {access?.subpayments == true || access?.all == true ? (
                  <li>
                    <Link to="/userpayments">
                      {props.t("Subscription Payments")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.paytran == true || access?.all == true ? (
                  <li>
                    <Link to="/transactions">
                      {props.t("Booking Transaction's")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.dcpayments == true || access?.all == true ? (
                  <li>
                    <Link to="/doctorpayments">
                      {props.t("Due Doctor Payments")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.relesepayments == true || access?.all == true ? (
                  <li>
                    <Link to="/releasepayments">
                      {props.t("Completed Payments")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.wallet == true || access?.all == true ? (
                  <li>
                    <Link to="/wallet">{props.t("Wallet")}</Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </li>

            {/* <li>
              <Link to="/Categories">
                <i className="bx bxs-dashboard"></i>
                <span>{props.t("Categories")}</span>
              </Link>
            </li>
           
            <li>
              <Link to="/Suggestions">
                <i className="bx bxs-conversation"></i>
                <span>{props.t("Suggestions")}</span>
              </Link>
            </li> */}

            {access?.sendmsg == true || access?.all == true ? (
              <li>
                <Link to="/sendmail">
                  <i className="bx bx-mail-send"></i>
                  <span>{props.t("Send Mail")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {access?.reports == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Report")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/SubscriptionReport">
                      {props.t("Subscription Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/paymentReport">{props.t("Payment Report")}</Link>
                  </li>
                </ul>
              </li>
            ) : (
              ""
            )}

            <li>
              {access?.opsblock == true ||
              access?.docblock == true ||
              access?.releaserequest == true ||
              access?.bannerView == true ||
              access?.SpecializationView == true ||
              access?.language == true ||
              access?.couponView == true ||
              access?.notificationView == true ||
              access?.MedicineView == true ||
              access?.faqView == true ||
              access?.About == true ||
              access?.PrivacyPolicy == true ||
              access?.TermsAndCondition == true ||
              access?.refundpolicy == true ||
              access?.Support == true ||
              access?.tax == true ||
              access?.all == true ? (
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
              ) : (
                ""
              )}
              <ul className="sub-menu">
                {access?.releaserequest == true || access?.all == true ? (
                  <li>
                    <Link to="/releaserequest">
                      {props.t("Release Request")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.bannerView == true || access?.all == true ? (
                  <li>
                    <Link to="/Banners">
                      <span>{props.t("Banners")}</span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.SpecializationView == true || access?.all == true ? (
                  <li>
                    <Link to="/Specialization">
                      {props.t("Specialization (Designation)")}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.language == true || access?.all == true ? (
                  <li>
                    <Link to="/languages">{props.t("Languages ")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.couponView == true || access?.all == true ? (
                  <li>
                    <Link to="/Coupons">
                      <span>{props.t("Coupons")}</span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.notificationView == true || access?.all == true ? (
                  <li>
                    <Link to="/Notifications">
                      <span>{props.t("Notifications")}</span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.MedicineView == true || access?.all == true ? (
                  <li>
                    <Link to="/medicine">
                      <span>{props.t("Medicine")}</span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.dosageview == true || access?.all == true ? (
                  <li>
                    <Link to="/drugdosage">
                      <span>{props.t("Frequency")}</span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                {access?.About == true || access?.all == true ? (
                  <li>
                    <Link to="/Prompts">{props.t("Prompt")}</Link>
                  </li>
                ) : (
                  ""
                )}

                {access?.About == true || access?.all == true ? (
                  <li>
                    <Link to="/Contactus">{props.t("Contact Us")}</Link>
                  </li>
                ) : (
                  ""
                )}

                {/* 
                <li>
                  <Link to="/Country">{props.t("Country")}</Link>
                </li>

                <li>
                  <Link to="/State">{props.t("State")}</Link>
                </li> */}
                {/* 
                <li>
                  <Link to="/District">{props.t("District")}</Link>
                </li>

                <li>
                  <Link to="/City">{props.t("City")}</Link>
                </li> */}
                {access?.faqView == true || access?.all == true ? (
                  <li>
                    <Link to="/Faqs">{props.t("Faqs")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.About == true || access?.all == true ? (
                  <li>
                    <Link to="/About">{props.t("About Us")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.PrivacyPolicy == true || access?.all == true ? (
                  <li>
                    <Link to="/PrivacyPolicy">{props.t("Privacy Policy")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.TermsAndCondition == true || access?.all == true ? (
                  <li>
                    <Link to="/Terms">{props.t("Terms & Conditions")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.refundpolicy == true || access?.all == true ? (
                  <li>
                    <Link to="/RefundPolicy">{props.t("Refund Policy")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.Support == true || access?.all == true ? (
                  <li>
                    <Link to="/support">{props.t("Support")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.tax == true || access?.all == true ? (
                  <li>
                    <Link to="/tax">{props.t("Convenience Fee")}</Link>
                  </li>
                ) : (
                  ""
                )}
                {access?.devicesview == true || access?.all == true ? (
                  <li>
                    <Link to="/devices">{props.t("Devices")}</Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
