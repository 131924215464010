import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Card,
  Modal,
  ModalBody,
  ModalHeader,
  CardBody,
  Container,
  Table,
  Button,
  Form,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { imgUrl, baseurl } from "Baseurls"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

const List = () => {
  const history = useHistory()
  const [show, setShow] = useState([])
  const [show1, setShow1] = useState([])
  const [show3, setshow3] = useState(false)
  const toggle = () => setshow3(!show3)

  const [doctor, setdoctor] = useState([,])
  const [specialisation, setspecialisation] = useState([])
  const [form, setform] = useState({
    name: "",
    email: "",
    countryCode: "+91",
    phone: "",
    qualification: "",
    specialisationId: "",
  })
  const [form1, setform1] = useState([])

  const [form12, setform12] = useState([])

  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }


  const handlechange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handlechange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }
  const handlechange12 = e => {
    let myUser = { ...form12 }
    myUser[e.target.name] = e.target.value
    setform12(myUser)
  }

  useEffect(() => {
    GetAllDoctors()
    GetAllspecialisation()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = doctor.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(doctor.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const AddDoctor = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("email", form.email)
    dataArray.append("countryCode", form.countryCode)
    dataArray.append("phone", form.phone)
    dataArray.append("qualification", form.qualification)
    dataArray.append("specialisationId", form.specialisationId)
    dataArray.append("latitude", coordinateds.lat)
    dataArray.append("longitude", coordinateds.lng)
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("avatar", Files[i])
    }
    axios
      .post(baseurl + "doctor/add_doctor", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllDoctors()
            clearForm()
            setShow(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const EditDoctor = () => {
    var token = datas
    var formid = form1._id
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("email", form1.email)
    dataArray.append("countryCode", form1.countryCode)
    dataArray.append("phone", form1.phone)
    dataArray.append("qualification", form1.qualification)
    dataArray.append("specialisationId", form1.specialisationId)
    dataArray.append("latitude", coordinateds1.lat)
    dataArray.append("longitude", coordinateds1.lng)
    dataArray.append("address", address1)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("avatar", Files1[i])
    }
    axios
      .put(baseurl + "doctor/editdoctor/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllDoctors()
            setShow1(false)
            clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const BlockDoctor = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      status: false,
      blockedReason: form12.blockedReason,
    }
    axios
      .post(baseurl + "doctor/blockunblockdoctor/" + form1._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllDoctors()
            setshow3(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  // const manageDelete = data => {
  //   const confirmBox = window.confirm("Do you really want to Delete?")
  //   if (confirmBox === true) {
  //     DeleteDoctor(data)
  //   }
  // }

  const handleSubmit = e => {
    e.preventDefault()
    AddDoctor()
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    EditDoctor()
  }

  const GetAllDoctors = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctor/getactivedoctors",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.doctors)
      })
  }
  const GetAllspecialisation = () => {
    var token = datas
    axios
      .post(
        baseurl + "specialisation/getAllActiveSpecialisation",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setspecialisation(res.data.specialisations)
      })
  }
  const clearForm1 = () => {
    setFiles1({
      avatar: "",
    })
  }
  const clearForm = () => {
    setform({
      name: "",
      email: "",
      countryCode: "",
      phone: "",
      qualification: "",
      specialisationId: "",
    })
    setFiles({
      avatar: "",
    })
  }

  const getpopup = data => {
    setShow1(true)
    setform1(data)
  }
  const getpopup2 = data => {
    setshow3(true)
    setform1(data)
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        baseurl + "doctor/getactivedoctors?searchQuery=" + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.doctors)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  const [address, setaddress] = useState("")
  const [address1, setaddress1] = useState("")
  const [coordinateds, setcoordinateds] = useState({
    lat: "",
    lng: "",
    address: "",
  })

  const [coordinateds1, setcoordinateds1] = useState({
    lat: parseFloat(form1.latitude) || 0,
    lng: parseFloat(form1.longitude) || 0,
    address: "",
  })

  useEffect(() => {
    setcoordinateds1({
      lat: parseFloat(form1.latitude) || 0,
      lng: parseFloat(form1.longitude) || 0,
      address: "",
    })
  }, [form1])

  console.log(parseFloat(form1.longitude))

  const handleSelects = value => {
    setaddress(value)
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => setcoordinateds(latLng))
  }

  const handleSelects1 = value => {
    setaddress1(value)
    geocodeByAddress(value)
      .then(results => {
        if (results.length > 0) {
          return getLatLng(results[0])
        }
        throw new Error("No results found")
      })

      // .then(results => getLatLng(results[0]))
      .then(latLng => setcoordinateds1(latLng))
      .catch(error => {
        console.error("Error fetching coordinates:", error)
        // Handle the error, e.g., show a message to the user
      })
  }

  console.log(coordinateds)
  console.log(coordinateds1)

  const redirectdata = data => {
    sessionStorage.setItem("docid", data._id)
    history.push("/ViewDoctor")
  }
  const redirectdata2 = data => {
    sessionStorage.setItem("docid", data._id)
    history.push("/timeslots")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctor list" />

          {show == true ? (
            <div>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <h5>Add Doctor</h5>
                    <Row className="mt-3">
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Full Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Full Name"
                            required
                            value={form.name}
                            name="name"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Phone <span className="text-danger">*</span>
                          </Label>

                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <select
                                  value={form.countryCode}
                                  name="countryCode"
                                  onChange={e => {
                                    handlechange(e)
                                  }}
                                  required
                                >
                                  <option value="">Select </option>
                                  <option value="+91">+91 </option>
                                  <option value="+1">+1</option>
                                  <option value="+61">+61 </option>
                                </select>
                              </div>
                            </div>
                            <Input
                              type="number"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Phone"
                              required
                              value={form.phone}
                              name="phone"
                              onChange={e => {
                                handlechange(e)
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Email<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Email"
                            required
                            value={form.email}
                            name="email"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Qualification <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Qualification"
                            required
                            value={form.qualification}
                            name="qualification"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Specialization{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            className="form-select"
                            required
                            value={form.specialisationId}
                            name="specialisationId"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value="">Select</option>
                            {specialisation.map((data, index) => (
                              <option key={index} value={data._id}>
                                {data.specialisation}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Image</Label>
                          <Input
                            type="file"
                            className="form-control"
                            name="avatar"
                            onChange={changeHandler}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Location <span className="text-danger">*</span>
                          </Label>
                          <PlacesAutocomplete
                            value={address}
                            onChange={setaddress}
                            onSelect={handleSelects}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div
                                key={suggestions.description}
                                className="form-group "
                              >
                                <input
                                  required
                                  {...getInputProps({
                                    placeholder: "Search location ...",
                                    className:
                                      "location-search-input form-control",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item"
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        }

                                    return (
                                      // <></>
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="" style={{ float: "right" }}>
                          <button
                            type="button"
                            className="btn btn-danger m-1"
                            onClick={() => setShow(false)}
                          >
                            Cancel <i className="fas fa-check-circle"></i>
                          </button>
                          <button type="submit" className="btn btn-success m-1">
                            Submit <i className="fas fa-check-circle"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          ) : (
            ""
          )}
          {show1 == true ? (
            <div>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit1(e)
                    }}
                  >
                    <h5>Edit Doctor</h5>
                    <Row className="mt-3">
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Full Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Full Name"
                            required
                            value={form1.name}
                            name="name"
                            onChange={e => {
                              handlechange1(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Phone <span className="text-danger">*</span>
                          </Label>

                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <select
                                  value={form1.countryCode}
                                  name="countryCode"
                                  onChange={e => {
                                    handlechange1(e)
                                  }}
                                >
                                  {" "}
                                  <option value="">Select </option>
                                  <option value="+91">+91 </option>
                                  <option value="+1">+1</option>
                                  <option value="+61">+61 </option>
                                </select>
                              </div>
                            </div>
                            <Input
                              type="number"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Phone"
                              required
                              value={form1.phone}
                              name="phone"
                              onChange={e => {
                                handlechange1(e)
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Email<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Email"
                            required
                            value={form1.email}
                            name="email"
                            onChange={e => {
                              handlechange1(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Qualification <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Qualification"
                            required
                            value={form1.qualification}
                            name="qualification"
                            onChange={e => {
                              handlechange1(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Specialization{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            className="form-select"
                            required
                            value={form1.specialisationId}
                            name="specialisationId"
                            onChange={e => {
                              handlechange1(e)
                            }}
                          >
                            <option value="">Select</option>
                            {specialisation.map((data, index) => (
                              <option key={index} value={data._id}>
                                {data.specialisation}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Image</Label>
                          <Input
                            type="file"
                            className="form-control"
                            name="avatar"
                            onChange={changeHandler1}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Location <span className="text-danger">*</span>
                          </Label>
                          <PlacesAutocomplete
                            value={address1}
                            onChange={setaddress1}
                            onSelect={handleSelects1}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div
                                key={suggestions.description}
                                className="form-group "
                              >
                                <input
                                  {...getInputProps({
                                    placeholder: "Search location ...",
                                    className:
                                      "location-search-input form-control",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item"
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        }

                                    return (
                                      // <></>
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="" style={{ float: "right" }}>
                          <button
                            type="button"
                            className="btn btn-danger m-1"
                            onClick={() => setShow1(false)}
                          >
                            Cancel <i className="fas fa-check-circle"></i>
                          </button>
                          <button type="submit" className="btn btn-success m-1">
                            Submit <i className="fas fa-check-circle"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          ) : (
            ""
          )}

          <Card>
            <CardBody>
              {/* <Link to="/AddDoctors"> */}
              <Row>
                <Col md="6">
                  <Button
                    onClick={() => setShow(!show)}
                    color="primary"
                    className="m-2"
                    title="Add Doctor"
                  >
                    Add Doctor
                    <i className="bx bx-plus-circle"></i>
                  </Button>
                </Col>
                <Col md="6">
                  <div style={{ float: "right" }}>
                    <Input
                      type="search"
                      className="form-control"
                      placeholder="Search.."
                      value={search.search}
                      onChange={searchAll}
                      name="search"
                    />
                  </div>
                </Col>
              </Row>
              {/* </Link> */}
              <div className="table-responsive">
                <Table className="table table-bordered mb-2 mt-3">
                  <thead>
                    <tr>
                      <th>SlNo</th>
                      <th>Doctor Id</th>
                      <th>Profile</th>
                      <th>Name </th>
                      <th>Phone </th>
                      <th>Wallet </th>
                      <th>Status </th>
                      <th>Join Date </th>
                      <th style={{ width: "180px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lists.map((data, key) => (
                      <tr key={key}>
                        <td>{(pageNumber - 1) * 10 + key + 11}</td>
                        <td>{data.doctorId}</td>
                        <td>
                          <img
                            src={imgUrl + data.avatar}
                            alt=""
                            width="50px"
                            height="50px"
                          />
                        </td>
                        <td>{data.name}</td>
                        <td>
                          {data.countryCode} {data.phone}
                        </td>

                        <td> {data.wallet}</td>
                        <td>{data.status == true ? "Active" : "Block"}</td>
                        <td>{data.date}</td>
                        <td style={{ width: "180px" }}>
                          <Row>
                            <Col style={{ padding: "0px" }}>
                              <div>
                                <Button
                                  title="Edit"
                                  onClick={() => getpopup(data)}
                                  size="sm"
                                  className="m-1"
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                              </div>
                            </Col>
                            <Col style={{ padding: "0px" }}>
                              <div>
                                <Button
                                  title="View"
                                  size="sm"
                                  onClick={() => {
                                    redirectdata(data)
                                  }}
                                  className="m-1"
                                  color="info"
                                  outline
                                >
                                  <i className="fas fa-eye"></i>
                                </Button>
                              </div>
                            </Col>
                            <Col style={{ padding: "0px" }}>
                              <div>
                                <Button
                                  title="Slots"
                                  size="sm"
                                  onClick={() => {
                                    redirectdata2(data)
                                  }}
                                  className="m-1"
                                  color="warning"
                                  outline
                                >
                                  <i className="bx bx-time-five"></i>
                                </Button>
                              </div>
                            </Col>
                            <Col style={{ padding: "0px" }}>
                              <div>
                                <Button
                                  title="Block"
                                  onClick={() => getpopup2(data)}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="danger"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="bx bx-block"
                                  ></i>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="mt-3" style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    total={lists.length}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>

        <Modal isOpen={show3} toggle={toggle} centered>
          <ModalHeader toggle={toggle}>Doctor's Block</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                BlockDoctor(e)
              }}
            >
              <div className="text-center mt-3 mb-4">
                <div>
                  <i
                    style={{ fontSize: "100px" }}
                    className="bx bx-info-circle text-warning"
                  />
                  <h3>Do you want to block this Doctor.</h3>
                </div>
                <textarea
                  name="blockedReason"
                  required
                  value={form12.blockedReason}
                  onChange={e => {
                    handlechange12(e)
                  }}
                  className="form-control mt-3"
                  placeholder="Enter Reason"
                />
              </div>

              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setshow3(!show3)
                  }}
                  style={{ width: "100px" }}
                  color="danger m-1"
                  outline
                >
                  Cancel <i className="bx bx-x-circle"></i>
                </Button>
                <Button
                  style={{ width: "100px" }}
                  type="submit"
                  color="success m-1"
                  outline
                >
                  Yes <i className="bx bx-check-circle"></i>
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default List
