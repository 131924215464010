import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { baseurl } from "Baseurls"

function AddVendors() {
  const [form, setform] = useState([])
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }


  const [inputList, setInputList] = useState([{ advantage: "" }])

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, {advantage: "" }])
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    Adddealer()
  }

  //Adddelear api integration


  const Adddealer = () => {
    var token = datas

    const bodydata = {
      type:form.type,
      duration:form.duration,
      planName:form.planName,
      price:form.price,
      discount:form.discount,
      advantages:inputList,
    }
    axios
      .post(baseurl + 'subscription/createsubscription', bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            history.push("/Subscription")
            clearForm()
            setInputList({ advantage: "" })
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }


  const clearForm = () => {
    setform({
      type: "",
      duration: "",
      planName: "",
      price: "",
      discount: "",

    })
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude Admin" breadcrumbItem="Add Plan" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
          
                    <Row>
                      <Col >
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Type <span className="text-danger">*</span>
                          </Label>
                          <select className="form-select"
                            required
                            value={form.type}
                            name="type"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value="">Select</option>
                            <option value="User">User</option>
                            <option value="Doctor">Doctor</option>
                          </select>

                        </div>
                      </Col>
                      <Col >
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Duration <span className="text-danger">*</span>
                          </Label>
                          <select className="form-select"
                            required
                            value={form.duration}
                            name="duration"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value="">Select</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="Yearly">Yearly</option>
                          </select>

                        </div>
                      </Col>
                      <Col >
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Plan Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter  Name"
                            required
                            value={form.planName}
                            name="planName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col >
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Price <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter  Price"
                            required
                            value={form.price}
                            name="price"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col >
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Discount (%)<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Discount"
                            required
                            value={form.discount}
                            name="discount"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                 

                <Row className="mb-5">
                  <div className="mt-4">
                    <Label> Advantages</Label>
                    <Row>
                      {inputList.map((x, i) => {
                        return (
                          <>
                            <Row>
                              <div key={i} className="box row">
                                {/* <Col md="6" sm="12" className="mb-1">
                                  <Input
                                    type="text"
                                    name="info"
                                    placeholder="Enter Plan Info"
                                    value={x.info}
                                    onChange={e => handleInputChange(e, i)}
                                  />
                                </Col> */}

                                <Col md="5" sm="12" className="mb-1">
                                  <Input
                                    type="text"
                                    name="advantage"
                                    placeholder="Enter Plan Advantage"
                                    value={x.advantage}
                                    onChange={e => handleInputChange(e, i)}
                                  />
                                </Col>

                                <Col sm="2">
                                  <div className="btn-box">
                                    {inputList.length !== 1 && (
                                      <button
                                        className="mr10 btn btn-outline-danger btn-sm m-1"
                                        type="button"
                                        onClick={() => handleRemoveClick(i)}
                                      >
                                        Remove{" "}
                                        <i className="bx bx-x-circle"></i>
                                      </button>
                                    )}
                                    {inputList.length - 1 === i && (
                                      <button
                                        className="btn btn-sm btn-outline-info m-1"
                                        onClick={handleAddClick}
                                      >
                                        Add{" "}
                                        <i className="bx bx-plus-circle"></i>
                                      </button>
                                    )}
                                  </div>
                                </Col>
                              </div>
                            </Row>
                          </>
                        )
                      })}
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <div className="text-end">
              <Button type="submit" color="success m-1 mb-5">
                Submit <i className="bx bx-check-circle"></i>
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
