import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import { ToastContainer, toast } from "react-toastify"
import { baseurl } from "Baseurls"

function AddVendors() {

  const slotID = sessionStorage.getItem('slotid')
  const doctorID = sessionStorage.getItem('docid')
  const [form, setform] = useState([])
  const history = useHistory()
  const handlechange = (e) => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const AddCallSlot1 = () => {
    var token = datas
    const data = {
      doctorId:doctorID,
      startTime: form.online1fromTime,
      endTime: form.online1toTime,
      duration: form.online1Duration,
      updateToAll: addallslots.allsots == true ? "yes" : "no"

    }
    axios
      .put(baseurl + 'doctorSlot/updateCallSession1/' + slotID, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          console.log(res)
        }
      )
  }
  const AddCallSlot2 = () => {
    var token = datas
    const data = {
      doctorId:doctorID,
      startTime: form.online2fromTime,
      endTime: form.online2toTime,
      duration: form.online2Duration,
      updateToAll: addallslots.allsots == true ? "yes" : "no"

    }
    axios
      .put(baseurl + 'doctorSlot/updateCallSession2/' + slotID, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          console.log(res)
        }
      )
  }

  const AddDirSlot1 = () => {
    var token = datas
    const data = {
      doctorId:doctorID,
      startTime: form.direct1fromTime,
      endTime: form.direct1toTime,
      duration: form.direct1Duration,
      updateToAll: addallslots1.allsots == true ? "yes" : "no"

    }
    axios
      .put(baseurl + 'doctorSlot/updateDirectsession1/' + slotID, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          console.log(res)
        }
      )
  }
  const AddDirSlot2 = () => {
    var token = datas
    const data = {
      doctorId:doctorID,
      startTime: form.direct2fromTime,
      endTime: form.direct2toTime,
      duration: form.direct2Duration,
      updateToAll: addallslots1.allsots == true ? "yes" : "no"

    }
    axios
      .put(baseurl + 'doctorSlot/updateDirectsession2/' + slotID, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          console.log(res)
        }
      )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    AddCallSlot1()
    AddCallSlot2()
    history.push('/timeslots')
  }
  const handleSubmit1 = (e) => {
    e.preventDefault()
    AddDirSlot2()
    AddDirSlot1()
    history.push('/timeslots')
  }

  const [addallslots, setaddallslots] = useState([])
  const [addallslots1, setaddallslots1] = useState([])

  const handleChangeSlot = (e) => {
    let myData = { ...addallslots }
    myData[e.target.name] = e.target.checked;
    setaddallslots(myData)
  }
  const handleChangeSlot1 = (e) => {
    let myData = { ...addallslots1 }
    myData[e.target.name] = e.target.checked;
    setaddallslots1(myData)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Allude Doctor"
            breadcrumbItem="Add Slot"
          />

          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Row>
                    <Col md={12}>
                      <div>
                        <h5>Online Consultation</h5>
                        <Row>
                          <Col md="6">
                            <b>Session 1</b>
                            <Row>
                              <Col md="4" sm="12" className="mb-1">
                                <Label>Start Time</Label>
                                <Input
                                  type="time"
                                  name="online1fromTime"
                                  placeholder="Enter From Time"
                                  value={form.online1fromTime}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>
                              <Col md="4" sm="12" className="mb-1">
                                <Label>End Time</Label>
                                <Input
                                  type="time"
                                  name="online1toTime"
                                  placeholder="Enter From Time"
                                  value={form.online1toTime}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>

                              <Col md="4" sm="12" className="mb-1">
                                <Label>Duration (Minutes)</Label>
                                <Input
                                  type="number"
                                  name="online1Duration"
                                  placeholder="Enter Duration"
                                  value={form.online1Duration}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6">
                            <b>Session 2</b>
                            <Row>
                              <Col md="4" sm="12" className="mb-1">
                                <Label>Start Time</Label>
                                <Input
                                  type="time"
                                  name="online2fromTime"
                                  placeholder="Enter From Time"
                                  value={form.online2fromTime}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>
                              <Col md="4" sm="12" className="mb-1">
                                <Label>End Time</Label>
                                <Input
                                  type="time"
                                  name="online2toTime"
                                  placeholder="Enter From Time"
                                  value={form.online2toTime}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>

                              <Col md="4" sm="12" className="mb-1">
                                <Label>Duration</Label>
                                <Input
                                  type="number"
                                  name="online2Duration"
                                  placeholder="Enter Duration"
                                  value={form.online2Duration}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                  </Row>

                  <Row className="mt-5">
                    <Col md="2">
                      <h5>Select All Days: </h5>
                    </Col>
                    <Col md="2">
                      <div className="form-check form-switch form-switch-md" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizemd"
                          name='allsots'
                          defaultChecked={addallslots.allsots}
                          onChange={(e) => { handleChangeSlot(e) }}
                        />
                      </div>
                    </Col>
                    <Col md="8">
                      <div>
                        <marquee style={{ fontWeight: 400 }} className="blink0">Would you like to click this button one time create all of the days in the week? </marquee>
                      </div>
                    </Col>
                  </Row>

                </Row>
                <div style={{ float: "right" }}>
                  <Button className="m-1 mt-5" color="success" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
          <Form
            onSubmit={e => {
              handleSubmit1(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Row>
                    <Col md={12}>
                      <div>
                        <h5 className="mt-3">Direct Consultation</h5>
                        <Row>
                          <Col md="6">
                            <b>Session 1</b>
                            <Row>
                              <Col md="4" sm="12" className="mb-1">
                                <Label>Start Time</Label>
                                <Input
                                  type="time"
                                  name="direct1fromTime"
                                  placeholder="Enter From Time"
                                  value={form.direct1fromTime}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>
                              <Col md="4" sm="12" className="mb-1">
                                <Label>End Time</Label>
                                <Input
                                  type="time"
                                  name="direct1toTime"
                                  placeholder="Enter From Time"
                                  value={form.direct1toTime}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>

                              <Col md="4" sm="12" className="mb-1">
                                <Label>Duration (Minutes)</Label>
                                <Input
                                  type="number"
                                  name="direct1Duration"
                                  placeholder="Enter To Time"
                                  value={form.direct1Duration}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6">
                            <b>Session 2</b>
                            <Row>
                              <Col md="4" sm="12" className="mb-1">
                                <Label>Start Time</Label>
                                <Input
                                  type="time"
                                  name="direct2fromTime"
                                  placeholder="Enter From Time"
                                  value={form.direct2fromTime}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>
                              <Col md="4" sm="12" className="mb-1">
                                <Label>End Time</Label>
                                <Input
                                  type="time"
                                  name="direct2toTime"
                                  placeholder="Enter From Time"
                                  value={form.direct2toTime}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>

                              <Col md="4" sm="12" className="mb-1">
                                <Label>Duration</Label>
                                <Input
                                  type="number"
                                  name="direct2Duration"
                                  placeholder="Enter To Time"
                                  value={form.direct2Duration}
                                  onChange={(e) => handlechange(e)}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      
                      </div>
                    </Col>

                  </Row>

                  <Row className="mt-5">
                    <Col md="2">
                      <h5>Select All Days: </h5>
                    </Col>
                    <Col md="2">
                      <div className="form-check form-switch form-switch-md" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizemd"
                          name='allsots'
                          defaultChecked={addallslots1.allsots}
                          onChange={(e) => { handleChangeSlot1(e) }}
                        />
                      </div>
                    </Col>
                    <Col md="8">
                      <div>
                        <marquee style={{ fontWeight: 400 }} className="blink0">Would you like to click this button one time create all of the days in the week? </marquee>
                      </div>
                    </Col>
                  </Row>

                </Row>
                <div style={{ float: "right" }}>
                  <Button className="m-1 mt-5" color="success" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default AddVendors