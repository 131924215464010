import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import Select from "react-select"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

function AddVendors() {
  const [address, setaddress] = useState("")

  const [coordinateds, setcoordinateds] = useState({
    lat: "",
    lng: "",
    address: "",
  })

  const handleSelects = async value => {
    setaddress(value)
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => setcoordinateds(latLng))
  }

  const [form, setform] = useState([])
  // console.log(form)
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    setSelectedOptions(details)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    AddDoctors()
  }

  //Adddelear api integration

  const history = useHistory()
  const AddDoctors = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("FirstName", form.FirstName)
    dataArray.append("LastName", form.LastName)
    dataArray.append("phone", form.phone)
    dataArray.append("Email", form.Email)
    dataArray.append("JoinDate", form.JoinDate)
    dataArray.append("Experience", form.Experience)
    dataArray.append("DoctorEmployeeId", form.DoctorEmployeeId)
    dataArray.append("Speciality", form.Speciality)
    dataArray.append("WeatherShowthemornot", form.WeatherShowthemornot)
    dataArray.append("EmailuserName", form.EmailuserName)
    dataArray.append("password", form.password)
    dataArray.append("Confirmpassword", form.Confirmpassword)
    dataArray.append("StateMciRegNo", form.StateMciRegNo)
    dataArray.append("Select", form.Select)
    dataArray.append("Address", form.Address)
    dataArray.append("Description", form.Description)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("trading_image", selectedFiles[i])
    }

    for (let i = 0; i < selectedFiles1.length; i++) {
      dataArray.append("logo", selectedFiles1[i])
    }

    // axios
    //   .post("", dataArray, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   })
    //   .then(
    //     res => {
    //       if (res.status === 200) {
    //         toast(res.data.message)
    //         history.push("/Vendorslist")
    //         clearForm()
    //         setSelectedOptions("")
    //       }
    //     },
    //     error => {
    //       if (error.response && error.response.status === 400) {
    //         toast(error.response.data.message)

    //       }
    //     }
    //   )
    console.log(JSON.stringify(dataArray))
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      FirstName: "",
      LastName: "",
      phone: "",
      Email: "",
      JoinDate: "",
      Experience: "",
      DoctorEmployeeId: "",
      Speciality: "",
      WeatherShowthemornot: "",
      EmailuserName: "",
      password: "",
      Confirmpassword: "",
      StateMciRegNo: "",
      Select: "",
      Address: "",
      Description: ""


    })
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes1(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes1(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [cities, setcities] = useState([])
  // useEffect(() => {
  //   getallCities()
  // }, [])

  const getallCities = () => {
    var token = datas
    axios
      .get(
        "http://103.186.185.77:5021/api/v1/superadmin/city/allactivecities",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcities(res.data.data)

      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Add Doctor" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="7">
                    <Row>


                      <h5
                        className="mb-4"
                        style={{ color: "#9640CF", fontWeight: "bold" }}
                      >
                        General Information
                      </h5>
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Full Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Full Name"
                            required
                            value={form.LastName}
                            name="LastName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Phone <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Phone"
                            required
                            value={form.Phone}
                            name="Phone"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Email<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Email"
                            required
                            value={form.Email}
                            name="Email"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Experience

                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Experience"
                            required
                            value={form.Experience}
                            name="Experience"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Qulifications

                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Qulifications"
                            required
                            value={form.Qulifications}
                            name="Qulifications"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Speciality
                          </Label>

                          <select
                            className="form-select"
                            required
                            value={form.Speciality}
                            name="Speciality"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value=""> Select </option>
                            <option value="Neurology"> Neurology </option>
                            <option value="Neurologist"> Neurologist </option>
                            <option value="Ophthalmology">
                              {" "}
                              Ophthalmology{" "}
                            </option>
                            <option value="Pediatrician"> Pediatrician </option>
                            <option value="Urologist"> Urologist </option>
                            <option value="Dermatologist">
                              {" "}
                              Dermatologist{" "}
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Weather Show them or not
                          </Label>

                          <select
                            className="form-select"
                            required
                            value={form.WeatherShowthemornot}
                            name="WeatherShowthemornot"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value=""> Select </option>
                            <option value="yes"> yes </option>
                            <option value="yes"> No </option>
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            From Time
                          </Label>
                          <Input
                            type="time"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Token"
                            required
                            value={form.token}
                            name="token"
                            onChange={(e) => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            To Time
                          </Label>
                          <Input
                            type="time"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Token"
                            required
                            value={form.token}
                            name="token"
                            onChange={(e) => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                    </Row>

                  </Col>

                  <Col lg="5">
                    <div className="text-center mt-5">
                      <h5 style={{ fontWeight: "bold" }}>Profile</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick "
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} required />
                                <div className="">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, jpeg, gif Image Size -
                            maximum size 2 MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>

                </Row>
              </CardBody>
            </Card>

            <Row className="mt-2">

              <h5
                className="mb-4"
                style={{ color: "#9640CF", fontWeight: "bold" }}
              >
                Account Details
              </h5>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Email/userName
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Email/userName"
                          required
                          value={form.EmailuserName}
                          name="EmailuserName"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          password
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Password"
                          required
                          value={form.password}
                          name="password"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Confirm password
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Confirm password"
                          required
                          value={form.Confirmpassword}
                          name="Confirmpassword"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>


                  </Row>
                </CardBody>
              </Card>

            </Row>


            <Row className="mt-2">

              <Card>
                <CardBody>
                  <Row>
                    <Col lg="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          State / Mci Reg No

                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter State / Mci Reg No "
                          required
                          value={form.StateMciRegNo}
                          name="StateMciRegNo"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>

                    </Col>
                    <Col lg="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          State Registation Certificate

                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter State / Mci Reg No "
                          required
                          value={form.StateMciRegNo}
                          name="StateMciRegNo"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>

                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input3">
                          Select
                        </Label>

                        <select
                          className="form-select"
                          required
                          value={form.cityId}
                          name="cityId"
                          onChange={e => {
                            handlechange(e)
                          }}
                        >
                          <option value=""> Select </option>
                          <option value="Country"> Country </option>
                          {/* <option value="District"> District </option> */}
                          <option value="State"> State </option>
                          {/* <option value="City"> City </option> */}
                        </select>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input3">
                          Location <span className="text-danger">*</span>
                        </Label>
                        <PlacesAutocomplete
                          value={address}
                          onChange={setaddress}
                          onSelect={handleSelects}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div
                              key={suggestions.description}
                              className="form-group "
                            >
                              <input
                                {...getInputProps({
                                  placeholder: "Search location ...",
                                  className: "location-search-input form-control",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item"
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                    : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    }

                                  return (
                                    // <></>
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={suggestion.placeId}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </Col>
                    
                    <Col md={3}>
                      <div className="mb-3">
                        <label>Latitude</label>
                        <input
                          id="name"
                          type="number"
                          className="form-control"
                          placeholder="Enter latitude"
                          name="latittude"
                          value={coordinateds.lat}
                          disabled="disabled"
                          onChange={e => {
                            handlechange(e)
                          }}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <label>Longitude</label>
                        <input
                          id="name"
                          type="number"
                          className="form-control"
                          placeholder="Enter longitude"
                          name="longitude"
                          value={coordinateds.lng}
                          onChange={e => {
                            handlechange(e)
                          }}
                          disabled="disabled"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-4">
                        <Label for="basicpill-firstname-input1">
                          Description
                        </Label>
                        <textarea
                          type="text"
                          // rows="6"
                          className="form-control "
                          id="basicpill-firstname-input1"
                          placeholder="Enter Description"
                          required
                          value={form.Description}
                          name="Description"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row className="mt-2">

                <h5
                  className="mb-4"
                  style={{ color: "#9640CF", fontWeight: "bold" }}
                >
                  Clinic Details
                </h5>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Name"
                            required
                            value={form.Name}
                            name="Name"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Logo
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter "
                            required
                            value={form.Name}
                            name="Name"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Location <span className="text-danger">*</span>
                          </Label>
                          <PlacesAutocomplete
                            value={address}
                            onChange={setaddress}
                            onSelect={handleSelects}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div
                                key={suggestions.description}
                                className="form-group "
                              >
                                <input
                                  {...getInputProps({
                                    placeholder: "Search location ...",
                                    className: "location-search-input form-control",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item"
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      }

                                    return (
                                      // <></>
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>


                    </Row>
                  </CardBody>
                </Card>

              </Row>

              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>



          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
