import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select"
import { URLS } from "../../Url"
import { baseurl, imgUrl } from "Baseurls"


const Notifications = () => {
  const [form, setform] = useState([])


  const [Noti, setNoti] = useState([])
  const [show, setShow] = useState(false)
  const [users, setUsers] = useState([])
  const [doctor, setdoctor] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleChange = (e) => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const getNotifications = () => {
    var token = datas
    axios
      .post(baseurl + "notification/getallnotifications", {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notifications)
      })
  }

  const GetAllDoctors = () => {
    var token = datas
    axios
      .post(baseurl + 'doctor/getactivedoctors', {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.doctors)
      })
  }

  const getUsers = () => {
    var token = datas
    axios
      .post(baseurl + 'user/getallusers', {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setUsers(res.data.users)
      })
  }

  const options = doctor.map(data => ({
    value: data._id,
    label: data.name,
  }))
  const options1 = users.map(data => ({
    value: data._id,
    label: data.name,
  }))

  useEffect(() => {
    getNotifications()
    GetAllDoctors()
    getUsers()
  }, [])


  const [selectedGroup, setselectedGroup] = useState([])
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }
  const [selectedGroup1, setselectedGroup1] = useState([])
  function handleSelectGroup1(selectedGroup) {
    setselectedGroup1(selectedGroup)
  }
  console.log(selectedGroup)
  console.log(selectedGroup1)

  function selectAllOptions() {
    setselectedGroup(options);
  }
  function clearSelection() {
    setselectedGroup([]);
  }
  function selectAllOptions1() {
    setselectedGroup1(options1);
  }
  function clearSelection1() {
    setselectedGroup1([]);
  }


  const [selectedMulti1, setselectedMulti1] = useState([])
  console.log(selectedMulti1)

  function handleMulti1(data) {
    setselectedMulti1(data)
  }

  const options12 = [
    { label: "Select Push Notification", value: "Push_Notification" },
    // { label: "Whats app Notification", value: "Whatsapp" },
    // { label: "SMS Notification", value: "SMS" },
  ]

  const [Files, setFiles] = useState("")
  const changeHandler = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }


  const addnotifi = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", form.title)
    dataArray.append("sendTo", form.sendTo)
    if (form.sendTo == "User") {
      dataArray.append("users", [JSON.stringify(selectedGroup1.map((data) => (data.value)))])
      // dataArray.append("users", JSON.stringify(selectedGroup1))
    } else if (form.sendTo == "Doctor") {
      dataArray.append("users", [JSON.stringify(selectedGroup.map((data) => (data.value)))])
    } else {
      dataArray.append("users", [])
    }

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }
    dataArray.append("notificationType", [JSON.stringify(selectedMulti1.map((data) => (data.value)))])
    dataArray.append("description", form.description)

    axios
      .post(baseurl + 'notification/addnotification', dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getNotifications()
            setShow(false)
            clearForm()
            setselectedMulti1([])
            setselectedGroup([])
            setselectedGroup1([])
            setFiles('')
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const deletenoti = (data) => {
    var token = datas
    var remid = data._id
    axios
      .delete(baseurl + 'notification/deletenotification/' + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getNotifications()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletenoti(data)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addnotifi()
  }

  const clearForm = () => {
    setform({
      title: "",
      description: "",
    })
  }


  const handleformchange = (e) => {
    var token = datas
    axios
      .post(baseurl + "notification/getallnotifications?searchQuery=" + `${e.target.value}`, {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notifications)
      })
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Noti.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Noti.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Notifications" />

          {show == true ? (
            <Card>
              <CardBody>
                <h5>Add Notification</h5>

                <Form
                  onSubmit={e => {
                    handleSubmit(e)
                  }}
                >
                  <Row>
                    <Col md={3}>
                      <div className="mt-3">
                        <Label>Title</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          value={form.title}
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="title"
                          required
                          type="text"
                          placeholder="Enter Title"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mt-3">
                        <Label for="basicpill-firstname-input1">
                          Users & Doctors
                          <span className="text-danger">*</span>
                        </Label>

                        <select
                          value={form.sendTo}
                          name="sendTo"
                          onChange={e => {
                            handleChange(e)
                          }}
                          className="form-select"
                          required
                        >
                          <option value="">Select</option>
                          <option value="All">All</option>
                          <option value="Doctor">Doctors</option>
                          <option value="User">Users</option>
                        </select>
                      </div>
                    </Col>

                    {form.sendTo == "Doctor" ? (
                      <Col md={3}>
                        <div className="mt-3">
                          <Row>
                            <Col>
                              <Label for="basicpill-firstname-input1">
                                Doctors <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            {selectedGroup.length == 0 ? (
                              <Col >
                                <Label type="button" style={{ float: "right" }} onClick={selectAllOptions} >Select all</Label>
                              </Col>
                            ) : (
                              <Col >
                                <Label type="button" style={{ float: "right" }} onClick={clearSelection} >Clear all</Label>
                              </Col>
                            )}

                          </Row>
                          <Select
                            onChange={handleSelectGroup}
                            name="doctors"
                            options={options}
                            value={selectedGroup}
                            isMulti
                            required
                          />

                        </div>
                      </Col>
                    ) : form.sendTo == "User" ? (
                      <Col md={3}>
                        <div className="mt-3">
                          <Row>
                            <Col>
                              <Label for="basicpill-firstname-input1">
                                Users <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            {selectedGroup1.length == 0 ? (
                              <Col >
                                <Label type="button" style={{ float: "right" }} onClick={selectAllOptions1} >Select all</Label>
                              </Col>
                            ) : (
                              <Col >
                                <Label type="button" style={{ float: "right" }} onClick={clearSelection1} >Clear all</Label>
                              </Col>
                            )}

                          </Row>
                          <Select
                            onChange={handleSelectGroup1}
                            name="users"
                            options={options1}
                            value={selectedGroup1}
                            isMulti
                            required
                          />

                        </div>
                      </Col>
                    ) : ""}

                    <Col md={3}>
                      <div className="mt-3">
                        <Label for="basicpill-firstname-input1">
                          Notifications
                          <span className="text-danger">*</span>
                        </Label>

                        <Select
                          name="notificationType"
                          value={selectedMulti1}
                          onChange={handleMulti1}
                          options={options12}
                          required
                          isMulti
                        />
                      </div>
                    </Col>
                    {/* {selectedMulti1 == "456" ? ( */}
                    <Col md={3}>
                      <div className="mt-3">
                        <Label>Image</Label>
                        <Input
                          className="form-control"
                          onChange={changeHandler}
                          type="file"
                          placeholder="Description"
                        />
                      </div>
                    </Col>
                    {/* ) : ""} */}
                    <Col md={3}>
                      <div className="mt-3">
                        <Label>Description</Label>{" "}
                        <span className="text-danger">*</span>
                        <textarea
                          className="form-control"
                          value={form.description}
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="description"
                          required
                          type="text"
                          placeholder="Enter Description"
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="text-end mt-3">
                    <Button disabled={selectedMulti1.length == 0} type="submit" color="success m-1" outline>
                      Submit <i className="bx bx-check-circle"></i>
                    </Button>
                    <Button onClick={() => { setShow(false) }} type="button" color="danger m-1" outline>
                      Cancel <i className="bx bx-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          ) : ""}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Button onClick={() => { setShow(!show) }} color="primary">
                        Add Notification
                      </Button>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          onChange={(e) => { handleformchange(e) }}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr>
                          <th>Sl No</th>
                          <th>Date</th>
                          <th>Title</th>
                          <th>Send To</th>
                          <th>Image</th>
                          <th>Notification Type</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">
                              {(pageNumber - 1) * 10 + key + 11}
                            </th>
                            <td>{data.date}</td>
                            <td>{data.title}</td>
                            <td>{data.sendTo}</td>
                            <td>
                              <img src={imgUrl + data.image} style={{ width: "70px" }} />
                            </td>
                            <td>
                              {data?.notificationType.map((data)=>(data + ","))}
                            </td>
                            <td>{data.description} </td>
                            <td>
                              <Button
                                onClick={() => {
                                  manageDelete(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="danger"
                                title="Delete"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-trash"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Notifications
