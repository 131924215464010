import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { Card, Col, Row, Button, Input, Table, CardBody } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { baseurl } from "Baseurls"

const BlogList = () => {
  const [banner, setbanner] = useState([])

  useEffect(() => {
    GetAllBanners()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        baseurl + "mails/getmail",
        // "http://193.203.160.181:5005/v1/alludeapi/admin/mails/getmail",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.data)
      })
  }

  const history = useHistory()

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        baseurl + "mails/getmail?searchQuery=" + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.data)
      })
  }

  const redirectdata = data => {
    sessionStorage.setItem("Mailid", data._id)
    history.push("/viewmail")
  }

  const DeleteBanner = data => {
    var token = datas
    var remid = { id: data._id }
    axios
      .post(
        baseurl + "mails/deletesingle",
        // "http://193.203.160.181:5005/v1/alludeapi/admin/mails/deletesingle",
        remid,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Allude" breadcrumbItem="Mails" />

            <Card>
              <CardBody>
                <div>
                  <Row className="">
                    <Col xl={6}>
                      <div>
                        <Link to="/sendmailsall">
                          <Button color="primary">
                            Add New Mail
                            <i className="bx bx-plus-circle"></i>
                          </Button>
                        </Link>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={search.search}
                          onChange={searchAll}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover bordered responsive>
                          <thead>
                            <tr>
                              <th>Sl No</th>
                              <th>Date</th>
                              <th>Subject</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key}>
                                <td>{(pageNumber - 1) * 5 + key + 6}</td>
                                <td>{data.logCreatedDate.slice(0, 10)}</td>
                                <td>{data.subject}</td>
                                <td>
                                  <Button
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="warning"
                                    outline
                                    onClick={() => {
                                      redirectdata(data)
                                    }}
                                    title="View"
                                  >
                                    <i className="bx bx-show-alt"></i>
                                  </Button>

                                  <Button
                                    onClick={() => {
                                      manageDelete(data)
                                    }}
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="danger"
                                    outline
                                    title="Delete"
                                  >
                                    <i className="bx bx-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Col sm="12">
                          <div
                            className="d-flex mt-3 mb-1"
                            style={{ float: "right" }}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    </React.Fragment>
  )
}

export default BlogList
