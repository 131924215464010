import PropTypes from "prop-types"
import React, { useState } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo allude color.png"
import Aiimage from "assets/images/latest/aigif.gif"

//Import config
import { facebook, google } from "../../config"

// logo changed
import allude from "../../../src/pages/Authentication/allude.png"

const Login = props => {
  //meta title
  // document.title = "Login"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || "",
      password: "" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  const [passwordType, setPasswordType] = useState("password")

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div style={{ overflow: "hidden" }} className="account-pages">
        {/* <Container> */}
        <Row className="justify-content-center">
          <Col style={{ background: "#001936" }} xl={7}>
            <div>
              <img style={{ height: "100%", width: "100%", marginTop:"250px" }} src={allude} />
            </div>
          </Col>
          <Col xl={5}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="my-auto">
                    <div>
                      <p className="text-muted">
                        <div className="text-center mb-2">
                          <Link
                            to="/"
                            className="d-block auth-logo text-center"
                          >
                            <img src={logo} alt="" className="auth-logo-dark" />
                            <img
                              src={logo}
                              alt=""
                              className="auth-logo-light"
                            />
                          </Link>
                        </div>
                        Sign in to continue to Allude.
                      </p>
                    </div>

                    <div className="mt-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {error ? (
                          <Alert color="danger">
                            <div>
                              <Row>
                                <Col md="12">
                                  {
                                    "Please provide a valid email address or phone number"
                                  }
                                </Col>
                                {/* <Col md="2">
                               <Link to="/access"> <Button className="rounded-pill" color="primary" size="sm">Request</Button></Link>
                              </Col> */}
                              </Row>
                            </div>
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="input-group">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              // type="password"
                              type={passwordType}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}

                            <div className="input-group-btn">
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => {
                                  togglePassword()
                                }}
                              >
                                {passwordType === "password" ? (
                                  <i
                                    className="fa fa-eye-slash"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <i className="fa fa-eye" aria-hidden="true" />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-4 pt-1 d-grid">
                          <button
                            className="btn btn-primary btn-block "
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div>
                      </Form>

                      {/* <Form action="dashboard">
                          <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">
                              Sign in with
                            </h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-facebook"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info"
                                >
                                  <i className="mdi mdi-twitter"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                >
                                  <i className="mdi mdi-google"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Form> */}

                      {/* <div className="mt-4 text-center">
                        <p>
                          Don&apos;t have an account ?
                          <Link
                            to="pages-register-2"
                            className="fw-medium text-primary"
                          >
                            Signup now
                          </Link>
                        </p>
                      </div> */}
                    </div>
                  </div>

                  <div className="mt-2 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} VRAH Telehealth Services
                      Private Limited
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
