import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { imgUrl, baseurl } from "Baseurls"

function TermsConditions() {
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const [activeTab1, setactiveTab1] = useState("1")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  
  useEffect(() => {
    GetSettings()
    GetSettings1()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetSettings = () => {
    var token = datas
    axios
      .post(baseurl + 'doctorCompanyPolicy/get',{}, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.doctorcompanyPolicy.termsAndCondition)
      })
  }

  const GetSettings1 = () => {
    var token = datas
    axios
      .post(baseurl + 'userCompanyPolicy/get',{}, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform1(res.data.usercompanyPolicy.termsAndCondition)
      })
  }

  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [text1, setText1] = useState([])
  const getpopup1 = (forms) => {
    setText1(forms)
    tog_small()
  }

  const [text12, setText12] = useState([])
  const getpopup12 = (forms) => {
    setText12(forms)
    tog_small1()
  }

  const submibooking = (e) => {
    e.preventDefault()
    changstatus()
  }

  const submibooking1 = (e) => {
    e.preventDefault()
    changstatus1()
  }

  const changstatus = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("termsAndCondition", text1)

    axios
      .put(baseurl + 'doctorCompanyPolicy/updatetermsAndCondition', dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setmodal_small(false)
            GetSettings()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const changstatus1 = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("termsAndCondition", text12)

    axios
      .put(baseurl + 'userCompanyPolicy/updatetermsAndCondition', dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setmodal_small1(false)
            GetSettings1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude" breadcrumbItem="Terms and Conditions" />
          <Row>
            <Col md={12}>
            <Row>
                <Col md="4">
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem className="border border-primary rounded m-1">
                      <NavLink
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={classnames({
                          active: activeTab1 === "1",
                        })}
                        onClick={() => {
                          toggle1("1")
                        }}
                      >
                        Doctor's 
                      </NavLink>
                    </NavItem>
                    <NavItem className="border border-primary rounded m-1">
                      <NavLink
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={classnames({
                          active: activeTab1 === "2",
                        })}
                        onClick={() => {
                          toggle1("2")
                        }}
                      >
                        User's
                      </NavLink>
                    </NavItem>

                  </Nav>
                </Col>
              </Row>

              <Card>
                <CardHeader className="bg-white"></CardHeader>

                <CardBody>
                  <div style={{ float: "right" }}>
                  </div>
                  <div>
                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="1">
                    <div>
                    <Row>
                        <Col md="6">
                        <h5>Terms and Conditions</h5>
                        </Col>
                        <Col md="6" className="text-end">
                          <Button onClick={()=>{getpopup1(form)}} title="Edit" className="" color="primary">Edit</Button>
                        </Col>
                      </Row>

                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: form,
                        }}
                      ></div>
                    </div>
                    </TabPane>
                    <TabPane tabId="2">
                    <div>
                    <Row>
                        <Col md="6">
                        <h5>Terms and Conditions</h5>
                        </Col>
                        <Col md="6" className="text-end">
                          <Button onClick={()=>{getpopup12(form1)}} title="Edit" className="" color="primary">Edit</Button>
                        </Col>
                      </Row>

                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: form1,
                        }}
                      ></div>
                    </div>
                    </TabPane>
                    </TabContent>

                
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            size="lg"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
               Edit Terms and Conditions
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <Form
              onSubmit={e => {
                submibooking(e)
              }}
            >
              <div className="modal-body">
                <CKEditor
                  editor={ClassicEditor}
                  id="header"
                  data={text1}
                  onReady={editor => {
                    console.log("Editor is ready to use!", editor)
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setText1(data)
                  }}
                />
              </div>

              <hr></hr>
              <div style={{ float: "right" }} className="m-2">
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
              </div>
            </Form>
          </Modal>

          <Modal
            size="lg"
            isOpen={modal_small1}
            toggle={() => {
              tog_small1()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
               Edit Terms and Conditions
              </h5>
              <button
                onClick={() => {
                  setmodal_small1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <Form
              onSubmit={e => {
                submibooking1(e)
              }}
            >
              <div className="modal-body">
                <CKEditor
                  editor={ClassicEditor}
                  id="header"
                  data={text12}
                  onReady={editor => {
                    console.log("Editor is ready to use!", editor)
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setText12(data)
                  }}
                />
              </div>

              <hr></hr>
              <div style={{ float: "right" }} className="m-2">
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
              </div>
            </Form>
          </Modal>

        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default TermsConditions
