import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button, } from "reactstrap"
import { baseurl } from "Baseurls"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"

function CurrectPlan() {
    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token
    const history = useHistory()

    const [form, setform] = useState([])
    const [form1, setform1] = useState([])
    const subsId = sessionStorage.getItem('subid')
    //Adddelear api integration

    const getSubscriptions = () => {
        var token = datas
        const bodydata = {
            _id: subsId
        }
        axios
            .post(baseurl + 'subscription/getSubscription', bodydata,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setform(res.data.data)
                setform1(res.data.data.advantages)
            })
    }

    useEffect(() => {
        getSubscriptions()
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Allude Admin" breadcrumbItem="View Subscription" />
                    <Row>


                        <Col md={12}>
                            <div style={{ float: 'right' }}>
                                <button onClick={history.goBack} type="button" className="mb-3 btn btn-primary" ><i className="far fa-arrow-alt-circle-left"></i>Back</button>

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="4" md="6">

                        </Col>
                        <Col xl="4" md="6" >
                            <Card className="plan-box">
                                <CardBody className="p-4">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <h5>{form.planName}</h5>
                                            <p className="text-muted">{form.discount} %</p>
                                        </div>
                                        <div className="ms-3">
                                            <i
                                                className={"bx bx-check-circle h1 text-primary"}
                                            />
                                        </div>
                                    </div>
                                    <div className="py-1">
                                        <h2>
                                            {form.price}/
                                            <span className="font-size-13">{form.duration}</span>
                                        </h2>
                                    </div>
                                    {/* <div className="text-center">
                                        <Link className="btn btn-primary btn-sm ">Expired ON 31-2-2024 </Link>
                                    </div> */}
 
                                    <div className="plan-features mt-3">
                                        {form1.map((data, key) => (
                                            <p key={"_feature_" + key}>
                                                <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                                                {data.advantage}
                                            </p>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CurrectPlan
