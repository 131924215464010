import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { imgUrl, baseurl } from "Baseurls"
import Select from "react-select"

const Coupons = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  console.log(datas)

  const [coup, setcoup] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  console.log(form1)
  const [users, setUsers] = useState([])
  const [doctor, setdoctor] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const GetAllDoctors = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctor/getactivedoctors",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.doctors)
      })
  }

  const getUsers = () => {
    var token = datas
    axios
      .post(
        baseurl + "user/getallusers",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setUsers(res.data.users)
      })
  }

  const options = doctor.map(data => ({
    value: data._id,
    label: data.name,
  }))
  const options1 = users.map(data => ({
    value: data._id,
    label: data.name,
  }))

  const [selectedGroup, setselectedGroup] = useState([])
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }
  const [selectedGroup1, setselectedGroup1] = useState([])
  function handleSelectGroup1(selectedGroup) {
    setselectedGroup1(selectedGroup)
  }
  const [selectedGroup2, setselectedGroup2] = useState([])
  function handleSelectGroup2(selectedGroup) {
    setselectedGroup2(selectedGroup)
  }
  const [selectedGroup3, setselectedGroup3] = useState([])
  function handleSelectGroup3(selectedGroup) {
    setselectedGroup3(selectedGroup)
  }

  function selectAllOptions() {
    setselectedGroup(options)
  }
  function clearSelection() {
    setselectedGroup([])
  }
  function selectAllOptions1() {
    setselectedGroup1(options1)
  }
  function clearSelection1() {
    setselectedGroup1([])
  }
  function selectAllOptions2() {
    setselectedGroup2(options1)
  }
  function clearSelection2() {
    setselectedGroup2([])
  }
  function selectAllOptions3() {
    setselectedGroup3(options1)
  }
  function clearSelection3() {
    setselectedGroup3([])
  }

  const getAllbcoupons = () => {
    var token = datas
    axios
      .post(
        baseurl + "coupon/getAll",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcoup(res.data.cpns)
      })
  }

  const addcoupons = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("type", form.type)
    dataArray.append("coins", form.coins)
    dataArray.append("couponType", form.couponType)
    dataArray.append("title", form.title)
    dataArray.append("couponCode", form.couponCode)
    dataArray.append("limitForSameUser", form.limitForSameUser)
    {
      form.couponType == "FreeSubscription"
        ? dataArray.append("couponCodeType", "free")
        : dataArray.append("couponCodeType", form.couponCodeType)
    }
    dataArray.append("price", form.price)
    dataArray.append("minimunPurchase", form.minimunPurchase)
    dataArray.append("maximumDiscount", form.maximumDiscount)
    dataArray.append("fromDate", form.fromDate)
    dataArray.append("expireDate", form.expireDate)
    // dataArray.append("doctors", selectedGroup)
    dataArray.append("users", JSON.stringify(selectedGroup1))

    axios
      .post(baseurl + "coupon/addcpn", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getAllbcoupons()
            setshow(false)
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const getpopup = data => {
    setform1(data)
    setselectedGroup2(data.doctors)
    setselectedGroup3(data.users)
    setshow1(true)
  }

  const editcoupon = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("type", form1.type)
    dataArray.append("coins", form1.coins)
    dataArray.append("couponType", form1.couponType)
    dataArray.append("title", form1.title)
    dataArray.append("couponCode", form1.couponCode)
    dataArray.append("limitForSameUser", form1.limitForSameUser)
    dataArray.append("couponCodeType", form1.couponCodeType)
    dataArray.append("price", form1.price)
    dataArray.append("minimunPurchase", form1.minimunPurchase)
    dataArray.append("maximumDiscount", form1.maximumDiscount)
    dataArray.append("fromDate", form1.fromDate)
    dataArray.append("expireDate", form1.expireDate)
    dataArray.append("doctors", selectedGroup2)
    dataArray.append("users", JSON.stringify(selectedGroup3))

    axios
      .put(baseurl + "coupon/editcpn/" + form1._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getAllbcoupons()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit = e => {
    e.preventDefault()
    addcoupons()
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    editcoupon()
  }

  const deletebenners = data => {
    var token = datas
    axios
      .delete(baseurl + "coupon/delete/" + data._id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getAllbcoupons()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = coup.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(coup.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  useEffect(() => {
    getAllbcoupons()
    GetAllDoctors()
    getUsers()
  }, [])

  const clearForm = () => {
    setform({
      type: "",
      couponType: "",
      title: "",
      couponCode: "",
      limitForSameUser: "",
      couponCodeType: "",
      price: "",
      fromDate: "",
      expireDate: "",
      status: "",
      coins: "",
    })
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        baseurl + "coupon/getAll?searchQuery=" + e.target.value,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcoup(res.data.cpns)
      })
  }

  const [dds, setdds] = useState([])

  const handleChangedates = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds(tomorrow.toISOString().split("T")[0])
  }

  const [dds1, setdds1] = useState([])

  const handleChangedates1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds1(tomorrow.toISOString().split("T")[0])
  }
  // var gets = localStorage.getItem("authUser")
  // var data = JSON.parse(gets)
  // var dats = data.rolesAndPermit
  // var dats1 = data.user.role

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude" breadcrumbItem="Coupons" />
          <Row>
            {show == true ? (
              <Col md={12}>
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Add Coupons</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        handleSubmit(e)
                      }}
                    >
                      <Row>
                        {" "}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Type
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="type"
                              value={form.type}
                              onChange={e => {
                                handleChange(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="Doctor">Doctor</option>
                              <option value="User">User</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Coupon Type
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="couponType"
                              value={form.couponType}
                              onChange={e => {
                                handleChange(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="Default">Default</option>
                              <option value="FirstSubscription">
                                First Subscription
                              </option>
                              {/* <option value="FreeSubscription">Free Subscription</option> */}
                              <option value="CustomerWise">
                                Customer Wise
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Title <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Title"
                              required
                              name="title"
                              value={form.title}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Coupon Code <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input3"
                              placeholder="Enter Coupon Code"
                              required
                              name="couponCode"
                              value={form.couponCode}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        {form.couponType == "FirstSubscription" ? (
                          ""
                        ) : (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Limit For Same User{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Ex : 10"
                                required
                                name="limitForSameUser"
                                value={form.limitForSameUser}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                        )}
                        {form.couponType == "FreeSubscription" ? (
                          ""
                        ) : (
                          <>
                            <Col md={3}>
                              {" "}
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Coupon Code Type
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="couponCodeType"
                                  value={form.couponCodeType}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  required
                                  className="form-select"
                                >
                                  <option value="">Select</option>
                                  <option value="price">Price</option>
                                  <option value="percentage">Percentage</option>
                                </select>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                  Value{" "}
                                  {form.couponCodeType == "percentage"
                                    ? "%"
                                    : ""}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="basicpill-firstname-input2"
                                  placeholder="Enter Price "
                                  required
                                  name="price"
                                  value={form.price}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                />
                              </div>
                            </Col>
                          </>
                        )}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              required
                              name="fromDate"
                              min={new Date().toISOString().split("T")[0]}
                              value={form.fromDate}
                              onChange={e => {
                                handleChangedates(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Expire Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter  Version"
                              required
                              name="expireDate"
                              min={dds}
                              value={form.expireDate}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        {form.couponType == "CustomerWise" ? (
                          <>
                            {form.type == "Doctor" ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <Row>
                                    <Col>
                                      <Label for="basicpill-firstname-input1">
                                        Doctors{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                    </Col>
                                    {selectedGroup.length == 0 ? (
                                      <Col>
                                        <Label
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={selectAllOptions}
                                        >
                                          Select all
                                        </Label>
                                      </Col>
                                    ) : (
                                      <Col>
                                        <Label
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={clearSelection}
                                        >
                                          Clear all
                                        </Label>
                                      </Col>
                                    )}
                                  </Row>
                                  <Select
                                    onChange={handleSelectGroup}
                                    name="doctors"
                                    options={options}
                                    value={selectedGroup}
                                    isMulti
                                    required
                                  />
                                </div>
                              </Col>
                            ) : (
                              <Col md={3}>
                                <div className="mb-3">
                                  <Row>
                                    <Col>
                                      <Label for="basicpill-firstname-input1">
                                        Users{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                    </Col>
                                    {selectedGroup1.length == 0 ? (
                                      <Col>
                                        <Label
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={selectAllOptions1}
                                        >
                                          Select all
                                        </Label>
                                      </Col>
                                    ) : (
                                      <Col>
                                        <Label
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={clearSelection1}
                                        >
                                          Clear all
                                        </Label>
                                      </Col>
                                    )}
                                  </Row>
                                  <Select
                                    onChange={handleSelectGroup1}
                                    name="users"
                                    options={options1}
                                    value={selectedGroup1}
                                    isMulti
                                    required
                                  />
                                </div>
                              </Col>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {form.type == "User" ? (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Coins <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Coins"
                                required
                                name="coins"
                                value={form.coins}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>

                      <div style={{ float: "right" }}>
                        <Button
                          onClick={() => {
                            setshow(false)
                          }}
                          className="m-1"
                          color="danger"
                          type="button"
                        >
                          Cancel <i className="fas fa-check-circle"></i>
                        </Button>
                        <Button className="m-1" color="success" type="submit">
                          Submit <i className="fas fa-check-circle"></i>
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}

            {show1 == true ? (
              <Col md={12}>
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Edit Coupons</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        handleSubmit1(e)
                      }}
                    >
                      <Row>
                        {" "}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Type
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="type"
                              value={form1.type}
                              onChange={e => {
                                handleChange1(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="Doctor">Doctor</option>
                              <option value="User">User</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Coupon Type
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="couponType"
                              value={form1.couponType}
                              onChange={e => {
                                handleChange1(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="Default">Default</option>
                              <option value="FirstSubscription">
                                First Subscription
                              </option>
                              {/* <option value="FreeSubscription">Free Subscription</option> */}
                              <option value="CustomerWise">
                                Customer Wise
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Title <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Title"
                              required
                              name="title"
                              value={form1.title}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Coupon Code <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input3"
                              placeholder="Enter Coupon Code"
                              required
                              name="couponCode"
                              value={form1.couponCode}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>
                        {form.couponType == "FirstSubscription" ? (
                          ""
                        ) : (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Limit For Same User{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Ex : 10"
                                required
                                name="limitForSameUser"
                                value={form1.limitForSameUser}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                              />
                            </div>
                          </Col>
                        )}
                        {form1.couponType == "FreeSubscription" ? (
                          ""
                        ) : (
                          <>
                            <Col md={3}>
                              {" "}
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Coupon Code Type
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="couponCodeType"
                                  value={form1.couponCodeType}
                                  onChange={e => {
                                    handleChange1(e)
                                  }}
                                  required
                                  className="form-select"
                                >
                                  <option value="">Select</option>
                                  <option value="price">Price</option>
                                  <option value="percentage">Percentage</option>
                                </select>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                  Value{" "}
                                  {form.couponCodeType == "percentage"
                                    ? "%"
                                    : ""}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="basicpill-firstname-input2"
                                  placeholder="Enter Price "
                                  required
                                  name="price"
                                  value={form1.price}
                                  onChange={e => {
                                    handleChange1(e)
                                  }}
                                />
                              </div>
                            </Col>
                          </>
                        )}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              required
                              name="fromDate"
                              min={new Date().toISOString().split("T")[0]}
                              value={form1.fromDate}
                              onChange={e => {
                                handleChangedates1(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Expire Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter  Version"
                              required
                              name="expireDate"
                              min={dds}
                              value={form1.expireDate}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>
                        {form1.couponType == "CustomerWise" ? (
                          <>
                            {form1.type == "Doctor" ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <Row>
                                    <Col>
                                      <Label for="basicpill-firstname-input1">
                                        Doctors{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                    </Col>
                                    {selectedGroup2.length == 0 ? (
                                      <Col>
                                        <Label
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={selectAllOptions2}
                                        >
                                          Select all
                                        </Label>
                                      </Col>
                                    ) : (
                                      <Col>
                                        <Label
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={clearSelection2}
                                        >
                                          Clear all
                                        </Label>
                                      </Col>
                                    )}
                                  </Row>
                                  <Select
                                    onChange={handleSelectGroup2}
                                    name="doctors"
                                    options={options}
                                    value={selectedGroup2}
                                    isMulti
                                    required
                                  />
                                </div>
                              </Col>
                            ) : (
                              <Col md={3}>
                                <div className="mb-3">
                                  <Row>
                                    <Col>
                                      <Label for="basicpill-firstname-input1">
                                        Users{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                    </Col>
                                    {selectedGroup3.length == 0 ? (
                                      <Col>
                                        <Label
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={selectAllOptions3}
                                        >
                                          Select all
                                        </Label>
                                      </Col>
                                    ) : (
                                      <Col>
                                        <Label
                                          type="button"
                                          style={{ float: "right" }}
                                          onClick={clearSelection3}
                                        >
                                          Clear all
                                        </Label>
                                      </Col>
                                    )}
                                  </Row>
                                  <Select
                                    onChange={handleSelectGroup3}
                                    name="users"
                                    options={options1}
                                    value={selectedGroup3}
                                    isMulti
                                    required
                                  />
                                </div>
                              </Col>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {form1.type == "User" ? (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Coins <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Coins"
                                required
                                name="coins"
                                value={form1.coins}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>

                      <div style={{ float: "right" }}>
                        <Button
                          onClick={() => {
                            setshow1(false)
                          }}
                          className="m-1"
                          color="danger"
                          type="button"
                        >
                          Cancel <i className="fas fa-check-circle"></i>
                        </Button>
                        <Button className="m-1" color="success" type="submit">
                          Submit <i className="fas fa-check-circle"></i>
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}

            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Button
                        color="primary"
                        title="Add Coupon"
                        onClick={() => {
                          setshow(!show)
                        }}
                      >
                        Add Coupon
                      </Button>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-4 mt-2">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Type</th>
                            <th>Title</th>
                            <th>Coupon Codes</th>
                            <th>Coupon Type</th>
                            <th>Send To</th>
                            <th>Coupon Value</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Limit</th>
                            <th>Status</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 10 + key + 11}</th>
                              <td>{data.type}</td>
                              <td>{data.title}</td>
                              <td>{data.couponCode}</td>
                              <td>{data.couponCodeType}</td>
                              <td>
                                {data?.users.map(data => data.label + ", ")}
                              </td>
                              <td>
                                {data.couponCodeType == "price"
                                  ? " " + data.price
                                  : data.price + " %"}
                              </td>
                              <td>{data.fromDate}</td>
                              <td>{data.expireDate}</td>
                              <td>{data.limitForSameUser}</td>
                              <td>
                                {data.status == true ? "Active" : "Expired"}
                              </td>

                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="success"
                                  outline
                                  title="Edit"
                                  // onClick={() => { setshow(true) }}
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>

                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                  title="Delete"
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Coupons
