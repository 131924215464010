import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Col,
  Row,
  TabContent,
  TabPane,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Container,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
//import images
import small from "../../assets/images/ds1.jpg"
import small2 from "../../assets/images/ds1.jpg"
import sai from "../../assets/images/sai.jpg"


const BlogList = () => {
  const [activeTab, toggleTab] = useState("1")
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Allude" breadcrumbItem="Blog List" />
            <Row>
              <Col xl={9} lg={9}>
                <Card>
                  <TabContent className="p-4" activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div>
                        <Container>
                        <Row className="justify-content-center">
                          <Col xl={9}>
                            <div>
                              <div>
                                <Row>
                                    <Col style={{padding:"0px"}} lg="2" >
                                    <div className="text-center">
                                    <img src={sai} style={{width:"50px", height:"50px"}} className="rounded-circle" />
                                    </div>
                                    </Col>
                                    <Col className="mt-1" style={{padding:"0px"}} lg="6">
                                    <b> Sateesh (MMBS) </b>
                                    <p className="text-muted">Endocrinology</p>
                                    </Col>
                                    <Col className="text-end mt-3" lg="4">
                                    <p className="text-muted">10 days Ago</p>
                                    </Col>
                                </Row>

                                <div className="position-relative mb-3">
                                  <img
                                    src={small}
                                    alt=""
                                    style={{width:"100%"}}
                                    className="img-thumbnail"
                                  />
                                </div>
                                <Row>
                                  <Col md="6">
                                  <h5>   Early Detection of Diseases </h5>
                                  </Col>
                                  <Col style={{float:"right"}} md="6">
                                  <Link style={{float:"right"}} to="#" className="text-muted">
                                      <i style={{fontSize:"17px"}} className="bx bx-heart align-middle text-danger me-1"></i>{" "}
                                      (100)Likes
                                    </Link>
                                  </Col>
                                </Row>
                               
                                <p className="mt-2">
                                  Regular checkups can detect diseases and
                                  conditions at an early stage when they are
                                  often more manageable or treatable. Conditions
                                  like hypertension, diabetes, and certain
                                  cancers can be asymptomatic in their early
                                  stages, making regular screenings crucial.
                                </p>

                                <div>
                                  <Link to="/Viewblog" className="text-primary">
                                    Read more{" "}
                                    <i className="mdi mdi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>

                              <hr className="my-3" />

                              <div>
                              
                              <Row>
                                    <Col style={{padding:"0px"}} lg="2" >
                                    <div className="text-center">
                                    <img src={sai} style={{width:"50px", height:"50px"}} className="rounded-circle" />
                                    </div>
                                    </Col>
                                    <Col className="mt-1" style={{padding:"0px"}} lg="6">
                                    <b> Sateesh (MMBS) </b>
                                    <p className="text-muted">Endocrinology</p>
                                    </Col>
                                    <Col className="text-end mt-3" lg="4">
                                    <p className="text-muted">10 days Ago</p>
                                    </Col>
                                </Row>

                                <div className="position-relative mb-3">
                                  <img
                                    src={small}
                                    alt=""
                                    style={{width:"100%"}}
                                    className="img-thumbnail"
                                  />
                                </div>
                                <Row>
                                  <Col md="6">
                                  <h5>   Early Detection of Diseases </h5>
                                  </Col>
                                  <Col style={{float:"right"}} md="6">
                                  <Link style={{float:"right"}} to="#" className="text-muted">
                                      <i style={{fontSize:"17px"}} className="bx bx-heart align-middle text-danger me-1"></i>{" "}
                                      (100)Likes
                                    </Link>
                                  </Col>
                                </Row>
                               
                                <p className="mt-2">
                                  Regular checkups can detect diseases and
                                  conditions at an early stage when they are
                                  often more manageable or treatable. Conditions
                                  like hypertension, diabetes, and certain
                                  cancers can be asymptomatic in their early
                                  stages, making regular screenings crucial.
                                </p>

                                <div>
                                  <Link to="/Viewblog" className="text-primary">
                                    Read more{" "}
                                    <i className="mdi mdi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>

                              <hr className="my-3" />

                              <div className="text-center">
                                <ul className="pagination justify-content-center pagination-rounded">
                                  <li className="page-item disabled">
                                    <Link to="#" className="page-link">
                                      <i className="mdi mdi-chevron-left"></i>
                                    </Link>
                                  </li>
                                  <li className="page-item">
                                    <Link to="#" className="page-link">
                                      1
                                    </Link>
                                  </li>
                                  <li className="page-item active">
                                    <Link to="#" className="page-link">
                                      2
                                    </Link>
                                  </li>
                                  <li className="page-item">
                                    <Link to="#" className="page-link">
                                      3
                                    </Link>
                                  </li>
                                  <li className="page-item">
                                    <Link to="#" className="page-link">
                                      ...
                                    </Link>
                                  </li>
                                  <li className="page-item">
                                    <Link to="#" className="page-link">
                                      10
                                    </Link>
                                  </li>
                                  <li className="page-item">
                                    <Link to="#" className="page-link">
                                      <i className="mdi mdi-chevron-right"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        </Container>
                      </div>
                    </TabPane>
                  </TabContent>
                </Card>
              </Col>

              <Col xl={3} lg={4}>
                <Card>
                  <CardBody className="p-4">
                    <div className="search-box">
                      <p className="text-muted">Search</p>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control rounded bg-light border-light"
                          placeholder="Search..."
                        />
                        <i className="mdi mdi-magnify search-icon"></i>
                      </div>
                    </div>

                    <hr className="my-4" />

                    <div>
                      <p className="text-muted">Categories</p>

                      <ul className="list-unstyled font-weight-medium">
                        <li>
                          <Link to="#" className="text-muted py-2 d-block">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Endocrinology
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="text-muted py-2 d-block">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Pulmonology{" "}
                            <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">
                              04
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="text-muted py-2 d-block">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Rheumatology
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="text-muted py-2 d-block">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Geriatrics
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="text-muted py-2 d-block">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Cardiology
                            <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">
                              12
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                  
                   
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

export default BlogList
