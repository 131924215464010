import React, { useState, useEffect } from "react"
import { Card, Col, Row, Button, CardBody } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { imgUrl, baseurl } from "Baseurls"

const BlogList = () => {
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const blogids = sessionStorage.getItem("Mailid")
  const [form, setform] = useState([])

  const Get = () => {
    var token = datas
    const bodydata = {
      id: blogids,
    }
    axios
      .post(
        baseurl + "mails/getmailsingle",
        // "http://193.203.160.181:5005/v1/alludeapi/admin/mails/getmailsingle",
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.data[0])
      })
  }

  useEffect(() => {
    Get()
  }, [])

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Allude" breadcrumbItem="View Mails" />
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <div>
                  <Row>
                    <Col md="2">
                      <h5 className="mb-3"> Subject: </h5>
                      <h5 className="mb-3">Users File: </h5>
                      <h5 className="mb-3">Message: </h5>
                    </Col>
                    <Col md="10">
                      <h5 className="mb-3">{form.subject}</h5>
                      <h5 className="text-primary mb-3">
                        <a target="blank" href={imgUrl + form.file}>
                          View <i className="bx bx-show-alt" />
                        </a>
                      </h5>
                      <div>
                        <div
                          className="text-muted font-size-14"
                          dangerouslySetInnerHTML={{
                            __html: form.message,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

export default BlogList
