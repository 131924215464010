import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardText,
  CardTitle,
  Modal,
  Tooltip,
  Button,
  Label,
  Input,
} from "reactstrap"
import axios from "axios"
import { URLS } from "../../Url"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import ReactApexChart from "react-apexcharts"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import ReactPaginate from "react-paginate"
// import img1 from "../../assets/images/ds1.jpg"
// import sai from "../../assets/images/sai.jpg"
import { Link, useHistory } from "react-router-dom"
import { baseurl, imgUrl } from "Baseurls"
import { ToastContainer, toast } from "react-toastify"
import spinner from "../../assets/images/loader.gif"

const Dashboard = props => {
  const history = useHistory()
  useEffect(() => {
    GetDashboarddata()
    GetDashboardgraph()
    GetMaintenance()
  }, [])
  const [show, setShow] = useState(true)
  console.log(show)
  const [Bookings, setBookings] = useState([])
  const [Bookingcards, setBookingcards] = useState([])
  const [letusers, setletusers] = useState([])
  const [letdocs, setletdocs] = useState([])
  const [doctorGraph, setdoctorGraph] = useState([])
  const [userGraph, setuserGraph] = useState([])
  const [bookingGraph, setbookingGraph] = useState([])
  const [maintenance, setmaintenance] = useState([])
  const [modalspin, setmodalspin] = useState(true)

  const [Topbar, setTopbar] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = data => {
    tog_small()
  }

  const GetDashboarddata = () => {
    var token = datas
    axios
      .post(
        baseurl + "getdashboardcards",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setBookingcards(res.data.cards)
        setBookings(res.data.bookingCard)
        setletusers(res.data.usersLatest)
        setletdocs(res.data.doctorLatest)
        setTopbar(res.data)
        setmodalspin(false)
      })
  }

  const GetDashboardgraph = () => {
    var token = datas
    axios
      .post(
        baseurl + "getdashboardgraphs",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctorGraph(res.data.doctorGraph)
        setuserGraph(res.data.userGraph)
        setbookingGraph(res.data.bookingGraph)
      })
  }

  const GetMaintenance = () => {
    var token = datas
    axios
      .post(
        baseurl + "maintenance/getmaintenance",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setmaintenance(res.data.maintenance)
      })
  }

  const handleChange = e => {
    const myData = { ...maintenance }
    myData[e.target.name] = e.target.checked
    setmaintenance(myData)
    var token = datas
    const bodydata = {
      underMaintenance: e.target.checked,
    }
    axios
      .put(baseurl + "maintenance/editmaintenance", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetMaintenance()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const reports = [
    {
      title: "Total",
      redirectpath: "/Doctors",
      redirectpath1: "/User",
      type: "Doctors",
      type1: "User",
      iconClass: "fas fa-chalkboard-teacher",
      description: Bookingcards?.total?.doctorTotalCount,
      description1: Bookingcards?.total?.usersTotalCount,
    },
    {
      title: "Subscribed",
      redirectpath: "/subscribed",
      redirectpath1: "/subscribed",
      type: "Doctors",
      type1: "User",
      iconClass: "fas fa-user-lock",
      description: Bookingcards?.subscribed?.doctorSubscribers,
      description1: Bookingcards?.subscribed?.userSubscribes,
    },
    {
      title: "Expiry",
      redirectpath: "/expiringsubscribers",
      redirectpath1: "/expiringsubscribers",
      type: "Doctors",
      type1: "User",
      iconClass: "fas fa-user-clock",
      description: Bookingcards?.expiry?.doctorExpiry,
      description1: Bookingcards?.expiry?.userExpiry,
    },
    {
      title: "Inactive",
      redirectpath: "/blockeddoctors",
      redirectpath1: "/blockedusers",
      type: "Doctors",
      type1: "User",
      iconClass: "fas fa-user-alt-slash",
      description: Bookingcards?.inactive?.doctorInactiveCount,
      description1: Bookingcards?.inactive?.usersInactiveCount,
    },
  ]
  const reports1 = [
    {
      title: "Total Booking's",
      iconClass: "bx bx-id-card",
      description: Bookings?.totalBookings,
    },
    {
      title: "Today Booking's",
      iconClass: "bx bx-pie-chart-alt-2",
      description: Bookings?.todaysBookings,
    },
    {
      title: "Consulted Booking's",
      iconClass: "bx bx-calendar-check",
      description: Bookings?.completedBookings,
    },
    {
      title: "Cancelled Booking's",
      iconClass: "bx bx-calendar-x",
      description: Bookings?.rejectedBookings,
    },
  ]

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggles = () => setTooltipOpen(!tooltipOpen)
  const [tooltipOpen1, setTooltipOpen1] = useState(false)
  const toggles1 = () => setTooltipOpen1(!tooltipOpen1)

  const series = [
    {
      name: "Subscribed",
      data: doctorGraph?.subscribed,
    },
    {
      name: "Expiry",
      data: doctorGraph?.expiry,
    },
    {
      name: "Inactive",
      data: doctorGraph?.inactive,
    },
  ]

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Doctor's",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return +val + "Doctor's"
        },
      },
    },
  }

  const series1 = [
    {
      name: "Subscribed",
      data: userGraph.subscribed,
    },
    {
      name: "Expiry",
      data: userGraph.expiry,
    },
    {
      name: "Inactive",
      data: userGraph.inactive,
    },
  ]

  const options1 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "User's",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  const series2 = [
    {
      name: "Total Booking's",
      data: bookingGraph?.total,
    },
    {
      name: "Consulted Booking's",
      data: bookingGraph?.completed,
    },
    {
      name: "Cancelled Booking's",
      data: bookingGraph?.rejected,
    },
  ]

  const options2 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Booking's",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  const redirectbooking = () => {
    history.push("/consulted")
  }

  const redirectdata = data => {
    sessionStorage.setItem("docid", data._id)
    history.push("/ViewDoctor")
  }

  const redirectdata1 = report => {
    sessionStorage.setItem("dashtype", report.type)
    history.push(report.redirectpath)
  }
  const redirectdata12 = report => {
    sessionStorage.setItem("dashtype", report.type1)
    history.push(report.redirectpath1)
  }

  const Agentid1 = data => {
    sessionStorage.setItem("userid", data._id)
    history.push("/ViewUser")
  }

  const Authdetails = localStorage.getItem("authUser")
  const Auth = JSON.parse(Authdetails)
  const access = Auth?.rolesAndPermission[0]
  console.log(access)

  return (
    <React.Fragment>
      <div className="page-content">
        {modalspin == true ? (
          <div className="text-center mt-5">
            <img src={spinner} style={{ width: "350px" }} />
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title={props.t("Dashboards")}
              breadcrumbItem={props.t("Dashboard")}
            />
            {access?.DashView == true || access?.all == true ? (
              <>
                <Row>
                  <Col xl="12">
                    <Row>
                      {reports.map((report, key) => (
                        <Col md="3" key={"_col_" + key}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div className="text-center">
                                    <i
                                      className={
                                        report.iconClass +
                                        " font-size-20 border border-primary text-primary mb-1 p-2 rounded-circle"
                                      }
                                    ></i>

                                    <h5>{report.title}</h5>
                                  </div>
                                  <Row className="mt-3">
                                    <Col>
                                      <div className="text-center">
                                        <span
                                          style={{
                                            fontSize: "10px",
                                            color: "black",
                                          }}
                                          className=" fw-medium"
                                        >
                                          Doctor's Count <br />{" "}
                                        </span>
                                        <a
                                          type="button"
                                          onClick={() => {
                                            redirectdata1(report)
                                          }}
                                        >
                                          {" "}
                                          <span
                                            className=""
                                            style={{
                                              fontSize: "18px",
                                              color: "black",
                                            }}
                                          >
                                            {report.description}
                                          </span>
                                        </a>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="text-center">
                                        <span
                                          style={{
                                            fontSize: "10px",
                                            color: "black",
                                          }}
                                          className=" fw-medium"
                                        >
                                          {" "}
                                          User's Count
                                          <br />{" "}
                                        </span>
                                        <a
                                          type="button"
                                          onClick={() => {
                                            redirectdata12(report)
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "18px",
                                              color: "black",
                                            }}
                                          >
                                            {report.description1}
                                          </span>
                                          <br />
                                        </a>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>

                <Row>
                  {reports1.map((report, key) => (
                    <Col md="3" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p
                                className="fw-medium"
                                style={{
                                  color: "black",
                                }}
                              >
                                {report.title}
                              </p>
                              <h4
                                className="mb-0"
                                style={{
                                  color: "black",
                                }}
                              >
                                {report.description}
                              </h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={report.iconClass + " font-size-24"}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Booking's</h4>
                      </div>
                      <ReactApexChart
                        options={options2}
                        series={series2}
                        type="bar"
                        height={350}
                      />
                    </CardBody>
                  </Card>
                </Col>

                <Row>
                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <h5>
                          <b>Latest Doctor's</b>
                        </h5>
                        <div className="table-responsive">
                          <Table className="table table-bordered mb-2 mt-3">
                            <thead>
                              <tr>
                                <th>SlNo</th>
                                <th>Doctor Id</th>
                                <th>Image</th>
                                <th>Name </th>
                                <th>Phone </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {letdocs.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{key + 1}</td>
                                  <td>
                                    <a
                                      className="text-primary"
                                      onClick={() => {
                                        redirectdata(data)
                                      }}
                                    >
                                      #{data.doctorId}{" "}
                                    </a>
                                  </td>
                                  <td>
                                    <img
                                      src={imgUrl + data.avatar}
                                      width={50}
                                      height={50}
                                    />
                                  </td>
                                  <td>{data.name}</td>
                                  <td>
                                    {data.countryCode} {data.phone}
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() => {
                                        redirectdata(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="info"
                                      title="View"
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="fas fa-eye"
                                      ></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <h5>
                          <b>Latest User's </b>
                        </h5>
                        <div className="table-responsive">
                          <Table
                            hover
                            className="table table-bordered mb-2 mt-3"
                          >
                            <thead>
                              <tr className="text-center">
                                <th>SlNo</th>
                                <th>User Id</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {letusers.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{key + 1}</td>
                                  <td>
                                    <a
                                      className="text-primary"
                                      onClick={() => {
                                        Agentid1(data)
                                      }}
                                    >
                                      #{data.userId}{" "}
                                    </a>
                                  </td>
                                  <td>
                                    <img
                                      src={imgUrl + data.avatar}
                                      width={50}
                                      height={50}
                                    />
                                  </td>
                                  <td>{data.name}</td>
                                  <td>
                                    {data.countryCode} {data.phone}
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() => {
                                        Agentid1(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="info"
                                      title="View"
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="fas fa-eye"
                                      ></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Card>
                  <CardBody>
                    <Row>
                      <Col md="3 ">
                        <h5>Maintenance mode: </h5>
                      </Col>
                      <Col md="2">
                        <div className="form-check form-switch form-switch-md">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            name="underMaintenance"
                            defaultChecked={maintenance.underMaintenance}
                            // value={maintenance.underMaintenance}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="7">
                        <div>
                          <marquee
                            style={{ fontWeight: 400 }}
                            className="blink0"
                          >
                            Inactive inside our programme are the Doctor's
                            Panel, Doctor App, and User App.
                          </marquee>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            ) : (
              <div className="mt-5 pt-5">
                <h1 className="text-center text-muted">
                  This page is not accessible to you.
                </h1>
              </div>
            )}
          </Container>
        )}

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Reject
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div>
                <Label>Reason</Label>
                <textarea
                  required
                  className="form-control"
                  placeholder="Enter Reason"
                />
              </div>
            </div>
            <div className="mt-3" style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small(false)
                }}
                color="danger"
                type="button"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="primary" type="submit">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  events: PropTypes.array,
  onGetEvents: PropTypes.func,
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}
// Calender.propTypes = {
//   events: PropTypes.array,
//   categories: PropTypes.array,
//   className: PropTypes.string,
//   onGetEvents: PropTypes.func,
//   onAddNewEvent: PropTypes.func,
//   onUpdateEvent: PropTypes.func,
//   onDeleteEvent: PropTypes.func,
//   onGetCategories: PropTypes.func,
// };

export default withTranslation()(Dashboard)
