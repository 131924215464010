import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  CardFooter,
  Form,
  Nav,
  Table,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import adobephotoshop from "../../assets/images/users/avatar-7.jpg"
import ls from '../../assets/images/med.png'
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import axios from "axios";
import { baseurl, imgUrl } from "Baseurls";
import ReactPaginate from "react-paginate"

function DoctorView() {
  const history = useHistory()
  const [Agent, setAgent] = useState([])
  const [bookings, setbookings] = useState([])
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [activeTab1, setactiveTab1] = useState("1")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }
  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  useEffect(() => {
    getAgent()
    getBookings()
    // datass()
  }, [])

  const userId = sessionStorage.getItem('userid')
  const getAgent = () => {
    var token = datas
    const bodydata = {
      id: userId
    }
    axios
      .post(baseurl + 'user/getuserbyid', bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.user)

      })

  }
  const getBookings = () => {
    var token = datas
    const bodydata123 = {
      userId: userId
    }
    axios
      .post(baseurl + 'adminbooking/getbookingbyuserid', bodydata123,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbookings(res.data.data)
      })
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = bookings.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(bookings.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const [viewdataSuggestion, setviewdataSuggestion] = useState([])
  console.log(viewdataSuggestion)
  const [viewdatamedicine, setviewdatamedicine] = useState([])
  const getPoppup = (data) => {
    var token = datas
    const bodydata = {
      bookingId: data._id
    }
    axios
      .post(baseurl + 'adminbooking/getprescriptionbybookingid', bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setviewdataSuggestion(res?.data?.prescription)
      })
    tog_small()
  }
  const getPoppup1 = (data) => {
    var token = datas
    const bodydata = {
      bookingId: data._id
    }
    axios
      .post(baseurl + 'adminbooking/getprescriptionbybookingid', bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setviewdatamedicine(res?.data?.prescription?.medicine)
      })
    tog_small1()
  }

  return (
    <div>
      {" "}
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Allude" breadcrumbItem="User Details" />
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>{" "}
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="12">
                        <div className="text-primary p-3 mb-5">
                          {/* <h5 className="text-primary">Welcome Back !</h5>
                <p>CA Marketing Dashboard</p> */}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="12">
                        <div className="profile-user-wid">
                          <div className="text-center">
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={imgUrl + Agent.avatar}
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <h5 className="font-size-15 text-center mt-1">
                          # {Agent.userId}
                        </h5>
                      </Col>
                      <ul className="list-unstyled mt-2">
                        <li>
                          <div className="d-flex">
                            <i className="bx bx-user-circle text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Name</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.name}</p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-phone text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Phone</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.countryCode} {Agent.phone}</p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-mail-send text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Email</h6>
                              <p className="text-muted fs-14 mb-0">
                                {Agent.email}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-error-circle text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Gender / Age </h6>
                              <p className="text-muted fs-14 text-break mb-0">
                                {Agent.gender} / {Agent.age}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-map text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Location</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.address}</p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-user text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Height / Weight</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.height} / {Agent.weight}</p>
                            </div>
                          </div>
                        </li>
                        {/* <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-devices text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Device Name</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.deviceName}</p>
                            </div>
                          </div>
                        </li> */}
                        {/* <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-duplicate text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Blood pressure</h6>
                              <p className="text-muted fs-14 mb-0">90mmHg</p>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md="8">
                <Card>
                  <CardBody>
                    <Row>
                      <Col><h5>Consultations</h5></Col>
                      <Col className="text-end"><h5 className="text-danger">Wallet:  {Agent.wallet}</h5></Col>
                    </Row>
                    <Table responsive bordered hover >
                      <thead>
                        <tr>
                          <th>SlNo</th>
                          <th>Date & Time</th>
                          <th>Booking Id</th>
                          <th>Doc Name</th>
                          <th>Type</th>
                          <th>Problem</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.bookingDate}, <br/> {data.time}</td>
                            <td>{data.bookingNo}</td>
                            <td>{data.doctorName}</td>
                            <td>{data.consultationType}</td>
                            <td>{data.problem}</td>
                            <td>{data.status}</td>
                            <td>
                              <Button
                                className="m-1"
                                color="success"
                                size="sm"
                                disabled={data.status != "Completed"}
                                outline
                                onClick={() => { getPoppup(data) }}
                              >
                                <i style={{ fontSize: "17px" }} className="fas fa-eye"></i>
                              </Button>
                              {/* <Button
                                className="m-1"
                                color="warning"
                                size="sm"
                                outline
                                onClick={() => { getPoppup1(data) }}
                              >
                                <i style={{ fontSize: "17px" }} className="bx bx-joystick-alt"></i>
                              </Button> */}
                              {/* </Link> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </CardBody>

                </Card>
              </Col>
            </Row>

            <Modal
              size="xl"
              isOpen={modal_small}
              toggle={() => {
                tog_small()
              }}
              centered
            >
              <div className="modal-header">
                <h5>Prescription Details </h5>
                <button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <iframe src={imgUrl + viewdataSuggestion.prescription} style={{width:"100%", height:"400px"}} />
                </div>
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>

                </div>
              </div>
            </Modal>

            <Modal
              size="md"
              isOpen={modal_small1}
              toggle={() => {
                tog_small1()
              }}
              centered
            >
              <div className="modal-header">
                <h5>Medicines List</h5>
                <button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Table hover className="table table-bordered">
                  <thead>
                    <tr className="text-center">
                      <th>S.No</th>
                      <th>Medicine name</th>
                      <th>Times</th>
                      <th>Dosage</th>
                      <th>Duration</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {viewdatamedicine.length == 0 ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No Data...
                        </td>
                      </tr>
                    ) : (
                      <>
                        {viewdatamedicine.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{key + 1}</td>
                            <td>{data.medicineName}</td>
                            <td>{data.timesPerDay}</td>
                            <td>{data.dosage}</td>
                            <td>{data.duration}</td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </Table>

                <div className="mt-3" style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small1(false)
                    }}
                    color="danger"
                    type="button"
                    className="m-1"
                  >
                    Cancel <i className="bx bx-x-circle"></i>
                  </Button>

                </div>
              </div>
            </Modal>

          </Container>{" "}
        </div>
      </React.Fragment>
    </div>
  )
}

export default DoctorView
