import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { format } from "date-fns"
import axios from "axios"
import { baseurl } from "Baseurls"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { useHistory } from "react-router-dom"
function Payment() {
  const history = useHistory()
  const [subdoc, setsubdoc] = useState([])
  const [form2, setform2] = useState([])
  const [show2, setshow2] = useState(false)

  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const handlechange = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getSubDoc()
  }, [])

  const getSubDoc = () => {
    var token = datas
    axios
      .post(
        baseurl + "payments/getdoctorunpaidwallets",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc(res.data.wallet)
      })
  }
  const handlechange1 = e => {
    var token = datas
    axios
      .post(
        baseurl +
          "payments/getdoctorunpaidwallets?searchQuery=" +
          `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc(res.data.wallet)
      })
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const pagesVisited = pageNumber * listPerPage
  const lists = subdoc.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(subdoc.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const redirectdata = data => {
    sessionStorage.setItem("docid", data.doctorId)
    history.push("/ViewDoctor")
  }

  const getdata = data => {
    setform2(data)
    setmodal_small(true)
  }
  const updateAmount = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      transactionId: form2.transactionId,
      description: form2.description,
    }

    axios
      .put(
        baseurl + "payments/updatewalletrequetstatus/" + form2._id,
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getSubDoc()
            setmodal_small(false)
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const clearForm = () => {
    setform2({
      description: "",
      transactionId: "",
    })
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Allude" breadcrumbItem="Due Doctor Payments" />
            {show2 == true ? (
              <Card className="p-4">
                <Form
                  onSubmit={e => {
                    filterCustomer(e)
                  }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-4">
                        <Label>Date Range</Label>

                        <Flatpickr
                          required
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                          name="date"
                          // value={selectedDate}
                          onChange={e => {
                            handleDateChange(e)
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="text-end mt-4">
                        <Button type="submit" color="success m-1" outline>
                          Submit <i className="bx bx-check-circle"></i>
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            setshow2(!show2)
                          }}
                          color="danger m-1"
                          outline
                        >
                          Cancel <i className="bx bx-x-circle"></i>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
            ) : (
              ""
            )}

            <Card>
              <CardBody>
                <Row>
                  <Col md="9">
                    {/* <Button type='button' color='primary'  >withdraw request  <i className="bx bx-wallet-alt"></i></Button> */}
                    {/* <Button style={{ marginLeft: "10px" }} onClick={() => { setshow2(!show2) }} color="warning">
                                            Filter <i className="bx bx-filter-alt"></i>
                                        </Button> */}
                  </Col>
                  <Col md="3" className="text-end">
                    <Input
                      name="search"
                      onChange={e => {
                        handlechange1(e)
                      }}
                      type="search"
                      placeholder="Search..."
                    />
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-bordered mb-2 mt-3">
                    <thead>
                      <tr>
                        <th>SlNo</th>
                        <th>Date</th>
                        <th>Doctor Name </th>
                        <th>Amount</th>
                        <th>Message</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lists.length == 0 ? (
                        <tr className="text-center">
                          <td colSpan="7">No Data...</td>
                        </tr>
                      ) : (
                        <>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <td>{(pageNumber - 1) * 10 + key + 11}</td>
                              <td>{data.date}</td>
                              <td>
                                <a
                                  onClick={() => {
                                    redirectdata(data)
                                  }}
                                  className="text-primary"
                                >
                                  {data.doctorName}{" "}
                                </a>
                              </td>
                              <td> {data.amount}</td>
                              <td>{data.doctordescription}</td>
                              <th>
                                <Button
                                  size="sm"
                                  outline
                                  color="warning"
                                  className="rounded-pill"
                                  title="Processing"
                                >
                                  Processing
                                </Button>
                              </th>
                              <th>
                                <Button
                                  onClick={() => {
                                    getdata(data)
                                  }}
                                  size="sm"
                                  outline
                                  color="success"
                                  className="m-1"
                                  title="Payment"
                                >
                                  <i className="bx bx-check-circle" /> Payment
                                </Button>
                              </th>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex mt-3 mb-1" style={{ float: "right" }}>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"pagination"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                      total={lists.length}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            <Modal
              size="md"
              isOpen={modal_small}
              toggle={() => {
                tog_small()
              }}
              centered
            >
              <div className="modal-header">
                <h5>Transaction</h5>
                <button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={e => {
                    updateAmount(e)
                  }}
                >
                  <div>
                    <div className="mb-3">
                      <Label>
                        Transaction Id <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        name="transactionId"
                        value={form2.transactionId}
                        onChange={e => {
                          handlechange(e)
                        }}
                        required
                        className="form-control"
                        placeholder="Enter Transaction Id"
                      />
                    </div>
                    <div>
                      <Label>Message </Label>
                      <textarea
                        name="description"
                        value={form2.description}
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-control"
                        placeholder="Enter Message"
                      />
                    </div>
                  </div>
                  <div className="mt-3" style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        setmodal_small(false)
                      }}
                      color="danger"
                      type="button"
                    >
                      Cancel <i className="fas fa-times-circle"></i>
                    </Button>
                    <Button className="m-1" color="success" type="submit">
                      Submit <i className="fas fa-check-circle"></i>
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>

            <Modal
              size="md"
              isOpen={modal_small1}
              toggle={() => {
                tog_small1()
              }}
              centered
            >
              <div className="modal-header">
                <h5>Transaction Details</h5>
                <button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <Row>
                    <Col md="4">
                      <div>
                        <b>Request Id </b>
                      </div>
                      <div>
                        <b>Amount </b>
                      </div>
                      <div>
                        <b>Message </b>
                      </div>
                    </Col>
                    <Col md="8">
                      <div>: #RI65465</div>
                      <div>: 200</div>
                      <div>: I need 200 rupees</div>
                    </Col>
                  </Row>
                  <h5 className="my-2 border-bottom">Admin replay</h5>
                  <Row>
                    <Col md="4">
                      <div>
                        <b>Transaction Id </b>
                      </div>
                      <div>
                        <b>Amount </b>
                      </div>
                      <div>
                        <b>Mode </b>
                      </div>
                      <div>
                        <b>Message </b>
                      </div>
                    </Col>
                    <Col md="8">
                      <div>: 2er31e31r315132ere</div>
                      <div>: 200</div>
                      <div>: Online</div>
                      <div>: Update your payments once check</div>
                    </Col>
                  </Row>
                </div>

                <div className="mt-3" style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small1(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                </div>
              </div>
            </Modal>
          </Container>
          <ToastContainer />
        </div>
      </React.Fragment>
    </div>
  )
}

export default Payment
