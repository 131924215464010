import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Button,
    Nav,
    Table,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    TabContent,
    TabPane,
    Spinner,
    Label,
} from "reactstrap"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { baseurl } from "Baseurls"


function Ventures() {

    const [show3, setshow3] = useState(false)
    const toggle = () => setshow3(!show3)

    const [activeTab1, setactiveTab1] = useState("1")
    const [show, setShow] = useState(false)
    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    const toggle1 = tab => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab)
        }
    }

    const [subdoc, setsubdoc] = useState([])
    const [subuser, setsubuser] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const [form1, setform1] = useState([])

    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token


    useEffect(() => {
        getSubDoc()
        getSubuser()
    }, [])

    const getSubDoc = () => {
        var token = datas
        axios
            .post(baseurl + 'payments/getdoctorsubscribepayments', {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setsubdoc(res.data.payments)
            })
    }
    const getSubuser = () => {
        var token = datas
        axios
            .post(baseurl + 'payments/getusersubscribepayments', {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setsubuser(res.data.payments)
            })
    }

    const Search1 = (e) => {
        var token = datas
        axios
            .post(baseurl + 'payments/getusersubscribepayments?searchQuery=' + `${e.target.value}`, {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setsubuser(res.data.payments)
            })
    }
    const Search = (e) => {
        var token = datas
        axios
            .post(baseurl + 'payments/getdoctorsubscribepayments?searchQuery='+ `${e.target.value}`, {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setsubdoc(res.data.payments)
            })
    }

    const [listPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0)
    const pagesVisited = pageNumber * listPerPage
    const lists = subdoc.slice(pagesVisited, pagesVisited + listPerPage)
    const pageCount = Math.ceil(subdoc.length / listPerPage)
    const changePage = ({ selected }) => {
        setPageNumber(selected)
    }

    const [listPerPage1] = useState(10)
    const [pageNumber1, setPageNumber1] = useState(0)
    const pagesVisited1 = pageNumber1 * listPerPage1
    const lists1 = subuser.slice(pagesVisited1, pagesVisited1 + listPerPage1)
    const pageCount1 = Math.ceil(subuser.length / listPerPage1)
    const changePage1 = ({ selected }) => {
        setPageNumber1(selected)
    }
    const redirectdata = (data) => {
        sessionStorage.setItem('docid', data.subscriberId)
        history.push('/ViewDoctor')
    }
    const redirectdata1 = (data) => {
        sessionStorage.setItem('subid', data.planId)
        history.push('/ViewSubscription')
    }
    const redirectdata2 = (data) => {
        sessionStorage.setItem("userid", data.subscriberId)
        history.push("/ViewUser")
    }
    const redirectdata21 = (data) => {
        sessionStorage.setItem('subid', data.planId)
        history.push('/ViewSubscription')
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Allude" breadcrumbItem="Subscription Payments" />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <Row>
                                                <Col md="12">
                                                    <div className="mt-2">
                                                        <Nav pills className="navtab-bg nav-justified">
                                                            <NavItem className="border border-primary rounded m-1">
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: activeTab1 === "1",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggle1("1")
                                                                    }}
                                                                >
                                                                    Doctor's
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className="border border-primary rounded m-1">
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: activeTab1 === "2",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggle1("2")
                                                                    }}
                                                                >
                                                                    User's
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="6" className="mt-2 text-end">
                                            {/* <div className="text-end mt-2">
                                                <b>Total Wallet Amount:  1000</b>
                                            </div> */}
                                        </Col>
                                        <Col md="2" className="mt-2 text-end">
                                            <div style={{ float: "right" }}>
                                                {activeTab1 === "2" ? (
                                                    <Input
                                                        name="search"
                                                        onChange={(e)=>{Search1(e)}}
                                                        type="search"
                                                        placeholder="Search..."
                                                    />
                                                ) : (
                                                    <Input
                                                        name="search"
                                                        onChange={(e)=>{Search(e)}}
                                                        type="search"
                                                        placeholder="Search..."
                                                    />
                                                )}

                                            </div>
                                        </Col>
                                    </Row>

                                    <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                        <TabPane tabId="1">
                                            <div className="table-rep-plugin table-responsive">
                                                <Table hover className="table table-bordered mb-4">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th>SlNo</th>
                                                            <th>Date</th>
                                                            <th>Doc Id</th>
                                                            <th>Doc Name</th>
                                                            <th>Subscription Name</th>
                                                            <th>Duration</th>
                                                            <th>Transactions Id</th>
                                                            <th>Amount</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lists.map((data, key) => (
                                                            <tr key={key} className="text-center">
                                                                <td>{(pageNumber - 1) * 10 + key + 11}</td>
                                                                <th>{data.date}</th>
                                                                <th><a className="text-primary" onClick={() => { redirectdata(data) }} >#{data.subscriberNo} </a></th>
                                                                <th>{data.subscriberName}</th>
                                                                <th><a className="text-primary" onClick={() => { redirectdata1(data) }} >{data.planName} </a></th>
                                                                <td>{data.duration}</td>
                                                                <td>{data.transactionId}</td>
                                                                <td> {data.total}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <div
                                                    className="d-flex mt-3 mb-1"
                                                    style={{ float: "right" }}
                                                >
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={pageCount}
                                                        onPageChange={changePage}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"previousBttn"}
                                                        nextLinkClassName={"nextBttn"}
                                                        disabledClassName={"disabled"}
                                                        activeClassName={"active"}
                                                        total={lists.length}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <div className="table-rep-plugin table-responsive">
                                                <Table hover className="table table-bordered mb-4">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th>SlNo</th>
                                                            <th>Date</th>
                                                            <th>User Id</th>
                                                            <th>User Name</th>
                                                            <th>Subscription Name</th>
                                                            <th>Duration</th>
                                                            <th>Transactions Id</th>
                                                            <th>Amount</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lists1.map((data, key) => (
                                                            <tr key={key} className="text-center">
                                                                <td>{(pageNumber1 - 1) * 10 + key + 11}</td>
                                                                <th>{data.date}</th>
                                                                <th><a className="text-primary" onClick={() => { redirectdata2(data) }} >#{data.subscriberNo} </a></th>
                                                                <th>{data.subscriberName}</th>
                                                                <th><a className="text-primary" onClick={() => { redirectdata21(data) }} >{data.planName} </a></th>
                                                                <td>{data.duration}</td>
                                                                <td>{data.transactionId}</td>
                                                                <td> {data.total}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <div
                                                    className="d-flex mt-3 mb-1"
                                                    style={{ float: "right" }}
                                                >
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={pageCount1}
                                                        onPageChange={changePage1}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"previousBttn"}
                                                        nextLinkClassName={"nextBttn"}
                                                        disabledClassName={"disabled"}
                                                        activeClassName={"active"}
                                                        total={lists1.length}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer />
                </div>
                <Modal isOpen={show3} toggle={toggle} centered>
                    <ModalHeader toggle={toggle}>Send Notification</ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                formeditsubmit(e)
                            }}
                        >
                            <div className="">
                                <div className="mb-3">
                                    <Label>Title <span className="text-danger">*</span></Label>
                                    <Input placeholder="Enter Title" />
                                </div>
                                <div className="mb-3">
                                    <Label>Message <span className="text-danger">*</span></Label>
                                    <textarea className="form-control" placeholder="Enter Message" />
                                </div>
                            </div>


                            <div className="text-end">
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setshow3(!show3)
                                    }}
                                    style={{ width: "100px" }}
                                    color="danger m-1"
                                    outline
                                >
                                    Cancel <i className="bx bx-x-circle"></i>
                                </Button>
                                <Button style={{ width: "100px" }} type="submit" color="success m-1" outline>
                                    Submit <i className="bx bx-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>

            </div>
        </React.Fragment>
    )
}

export default Ventures
