import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import Dropzone from "react-dropzone"
import Addcarsxl from "../../assets/images/medition.csv"
import { saveAs } from "file-saver"
import { imgUrl, baseurl } from "Baseurls"

const Banner = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [banner, setbanner] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  // const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const AddBanner = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("brandName", form.brandName)
    // dataArray.append("drugName", form.drugName)
    dataArray.append("composition", form.composition)

    axios
      .post(baseurl + "medicine/addmedicine", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllBanners()
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const AddBluckMedi = e => {
    e.preventDefault()
    var token = datas
    const dataArray = new FormData()
    for (let i = 0; i < files.length; i++) {
      dataArray.append("medicinefile", files[i])
    }

    axios
      .post(baseurl + "medicine/addbulkmedicine", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllBanners()
            setmodal_small1(false)
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const EditBanner = () => {
    var token = datas
    var formid = form1._id
    const dataArray = new FormData()
    dataArray.append("brandName", form1.brandName)
    // dataArray.append("drugName", form1.drugName)
    dataArray.append("composition", form1.composition)
    dataArray.append("status", form1.status)

    axios
      .put(baseurl + "medicine/editmedicine/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllBanners()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(baseurl + "medicine/delete/" + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddBanner()
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        baseurl + "medicine/getALL",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.medicines)
      })
  }

  const clearForm = () => {
    setform({
      brandName: "",
      // drugName: "",
      composition: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        baseurl + "medicine/getALL?searchQuery=" + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.medicines)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [modal_small1, setmodal_small1] = useState(false)

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [files, setFiles] = useState([])

  function handleDrop(acceptedFiles) {
    const validatedFiles = acceptedFiles.filter(file => validateFile(file))
    setFiles(validatedFiles)
  }

  function validateFile(file) {
    const allowedExtensions = ["csv", "exel"]
    const maxSize = 10 * 1024 * 1024 // 10MB

    if (!allowedExtensions.includes(file.name.split(".").pop())) {
      toast.error("Invalid file type. Only Csv, Exel are allowed.")
      return false
    }

    if (file.size > maxSize) {
      toast.error("File is too big. Maximum size allowed is 10MB.")
      return false
    }

    return true
  }

  const downloadImage = () => {
    saveAs(Addcarsxl)
  }

  const handleDelete = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete all data?"
    )

    if (userConfirmed) {
      var token = datas

      try {
        const response = await axios.delete(
          "https://api.alludeapp.com/v1/alludeapi/admin/medicine/deleteall",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        if (response.status === 200) {
          toast.success(response.data.message)
          GetAllBanners()
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message)
        } else {
          toast.error("Error deleting data: " + error.message)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude" breadcrumbItem="Medicine" />

          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <CardTitle>Add Medicine</CardTitle>
                    </Col>
                    <Col className="text-end" md="6">
                      <Button
                        outline
                        size="sm"
                        className="m-1"
                        color="primary"
                        type="submit"
                        onClick={() => {
                          tog_small1()
                        }}
                        title="Bulk Upload"
                      >
                        <i className="bx bx-file" /> Bulk Upload
                      </Button>
                    </Col>
                  </Row>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Brand Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Brand Name"
                        required
                        name="brandName"
                        value={form.brandName}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Composition <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Composition"
                        required
                        name="composition"
                        value={form.composition}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>

                    <div style={{ float: "right" }}>
                      <Button color="success" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col md={8}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <CardTitle>Medicine List</CardTitle>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div>
                        <Button
                          className="btn btn-danger"
                          onClick={handleDelete}
                        >
                          Delete all
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-4 mt-3">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Brand Name</th>
                            {/* <th>Drug</th> */}
                            <th>Composition</th>
                            <th>Status</th>

                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>{data.brandName}</td>
                              {/* <td>{data.drugName}</td> */}
                              <td>{data.composition}</td>
                              <td>{data.status}</td>

                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="success"
                                  outline
                                  title="Edit"
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  title="Delete"
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Medicine
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Brand Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Brand Name"
                  required
                  name="brandName"
                  value={form1.brandName}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>

              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Composition <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Composition"
                  required
                  name="composition"
                  value={form1.composition}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Status <span className="text-danger">*</span>
                </Label>
                <select
                  required
                  name="status"
                  value={form1.status}
                  onChange={e => {
                    handleChange1(e)
                  }}
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inctive</option>
                </select>
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <form
            onSubmit={e => {
              AddBluckMedi(e)
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Upload Medicines
              </h5>
              <button
                onClick={() => {
                  setmodal_small1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Col md={12}>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        {...getRootProps()}
                        className="dz-message needsclick mt-2"
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {files.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <a to="#" className="text-muted font-weight-bold">
                                {f.name}
                              </a>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>
              </Col>

              <div className="p-3 mb-1 bg-warning text-dark mt-3">
                <i className="bx bx-bell" style={{ fontSize: "14px" }}></i>
                Tip: Templates need to conform to our specifications. Click{" "}
                <a className="m-1" onClick={downloadImage}>
                  <u>here</u>
                </a>
                to download a template to fill in.
              </div>
            </div>
            <hr></hr>

            <Col md={12}>
              <div className="mb-3" style={{ float: "right" }}>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Col>
          </form>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Banner
