import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Nav,
  Table,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  TabContent,
  TabPane,
  Label,
} from "reactstrap"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { baseurl } from "Baseurls"

function Ventures() {
  const redirctdashboard = sessionStorage.getItem("dashtype")
  const [activeTab1, setactiveTab1] = useState(
    redirctdashboard == "User" ? "2" : "1"
  )

  const [show3, setshow3] = useState(false)
  const [show2, setshow2] = useState(false)
  const toggle = () => {
    setshow3(!show3)
  }
  const toggle12 = () => {
    setshow2(!show2)
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [subdoc, setsubdoc] = useState([])
  const [subdoc1, setsubdoc1] = useState([])

  const history = useHistory()
  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const [listPerPage1] = useState(10)
  const [pageNumber1, setPageNumber1] = useState(0)

  useEffect(() => {
    getSubDoc()
    getSubUser()
  }, [])

  const getSubDoc = () => {
    var token = datas
    axios
      .post(
        baseurl + "subscribeAdmin/getAllExpiry",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc(res.data.subscribeResult)
      })
  }

  const getSubUser = () => {
    var token = datas
    axios
      .post(
        baseurl + "subscribeAdmin/getalluserexpiry",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubdoc1(res.data.subscribeResult)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = subdoc.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(subdoc.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = subdoc1.slice(pagesVisited1, pagesVisited1 + listPerPage1)
  const pageCount1 = Math.ceil(subdoc1.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    console.log(token)

    axios
      .post(
        baseurl +
          "subscribeAdmin/getAllExpiry?searchquery=" +
          `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setsubdoc(res.data.subscribeResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }
  const Search1 = e => {
    const token = datas

    const myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)

    axios
      .post(
        baseurl +
          "subscribeAdmin/getalluserexpiry?searchquery=" +
          `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setsubdoc1(res.data.subscribeResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const redirectdata1 = data => {
    sessionStorage.setItem("subid", data._id)
    history.push("/viewsub")
  }
  const redirectdata = data => {
    sessionStorage.setItem("docid", data.subscriberId)
    history.push("/ViewDoctor")
  }

  const Agentid1 = data => {
    sessionStorage.setItem("userid", data.subscriberId)
    history.push("/ViewUser")
  }

  // send message

  const [message, setMessage] = useState([])
  const [exdata, setexdata] = useState([])

  const getPopup = data => {
    setshow2(true)
    setexdata(data)
  }
  const getPopup1 = data => {
    setshow3(true)
    setexdata(data)
  }

  const messageChange = e => {
    const mydata = { ...message }
    mydata[e.target.name] = e.target.value
    setMessage(mydata)
  }

  const sendNottoUser = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      notificationType: message.notificationType,
      title: message.title,
      descirption: message.descirption,
      id: exdata._id,
    }
    axios
      .post(baseurl + "subscribeAdmin/sendusernotification", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getSubDoc()
            getSubUser()
            setshow2(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const sendNottoDoc = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      notificationType: message.notificationType,
      title: message.title,
      descirption: message.descirption,
      id: exdata._id,
    }
    axios
      .post(baseurl + "subscribeAdmin/senddoctornotification", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getSubDoc()
            getSubUser()
            setshow3(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Expiring Subscribers" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <Row>
                        <Col md="12">
                          <div className="mt-2">
                            <Nav pills className="navtab-bg nav-justified">
                              <NavItem className="border border-primary rounded m-1">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab1 === "1",
                                  })}
                                  onClick={() => {
                                    toggle1("1")
                                  }}
                                >
                                  Doctor's
                                </NavLink>
                              </NavItem>
                              <NavItem className="border border-primary rounded m-1">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab1 === "2",
                                  })}
                                  onClick={() => {
                                    toggle1("2")
                                  }}
                                >
                                  User's
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col md="12" className="mt-2 text-end mb-3">
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={Search}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Date</th>
                              <th>Ex Date</th>
                              <th>Doc Id</th>
                              <th>Doc Name</th>
                              <th>Plan Name</th>
                              <th>Price</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                <th>{data.startDate}</th>
                                <th>{data.endDate}</th>
                                <th>
                                  <a
                                    onClick={() => {
                                      redirectdata(data)
                                    }}
                                  >
                                    # {data.subscriberNo}
                                  </a>
                                </th>
                                <th>{data.subscriberName}</th>
                                <td>{data.planName}</td>
                                <td> {data.price}</td>
                                <td>
                                  {data.status == true ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    className="m-1"
                                    color="warning"
                                    outline
                                    onClick={() => {
                                      redirectdata1(data)
                                    }}
                                    title="View"
                                  >
                                    <i className="fas fa-eye"></i> View
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      getPopup1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="success"
                                    title="Send"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="bx bx-message-rounded-dots"
                                    ></i>
                                    Send
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    
                    <TabPane tabId="2">
                      <Row>
                        <Col md="12" className="mt-2 text-end mb-3">
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form2.search}
                              onChange={Search1}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Date</th>
                              <th>Expiry Date</th>
                              <th>User Id</th>
                              <th>User Name</th>
                              <th>Plan Name</th>
                              <th>Price</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists1.map((data1, index) => (
                              <tr className="text-center" key={index}>
                                <th>{(pageNumber1 - 1) * 10 + index + 11}</th>
                                <th>{data1.startDate}</th>
                                <th>{data1.endDate}</th>
                                <th>
                                  <a
                                    onClick={() => {
                                      Agentid1(data1)
                                    }}
                                  >
                                    # {data1.subscriberNo}
                                  </a>
                                </th>
                                <th>{data1.subscriberName}</th>
                                <td>{data1.planName}</td>
                                <td> {data1.price}</td>
                                <td>
                                  {data1.status == true ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    className="m-1"
                                    color="warning"
                                    outline
                                    onClick={() => {
                                      redirectdata1(data1)
                                    }}
                                    title="View"
                                  >
                                    <i className="fas fa-eye"></i> View
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      getPopup(data1)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="success"
                                    title="Send"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="bx bx-message-rounded-dots"
                                    ></i>
                                    Send
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount1}
                            onPageChange={changePage1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists1.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
        <Modal isOpen={show3} toggle={toggle} centered>
          <ModalHeader toggle={toggle}>Send Notification</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                sendNottoDoc(e)
              }}
            >
              <div className="">
                <div className="mb-3">
                  <Label>
                    Type <span className="text-danger">*</span>
                  </Label>
                  <select
                    className="form-select"
                    required
                    name="notificationType"
                    value={message.notificationType}
                    onChange={e => {
                      messageChange(e)
                    }}
                  >
                    <option value="">Select</option>
                    <option value="Push_Notification">App Notification</option>
                    <option value="Whatsapp">Whatsapp</option>
                    <option value="SMS">SMS</option>
                  </select>
                </div>
                {message.notificationType == "Push_Notification" ? (
                  <>
                    <div className="mb-3">
                      <Label>
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="title"
                        required
                        value={message.title}
                        onChange={e => {
                          messageChange(e)
                        }}
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="mb-3">
                      <Label>
                        Message <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        name="descirption"
                        value={message.descirption}
                        required
                        onChange={e => {
                          messageChange(e)
                        }}
                        className="form-control"
                        placeholder="Enter Message"
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="text-end">
                <Button
                  type="button"
                  onClick={() => {
                    setshow3(false)
                  }}
                  style={{ width: "100px" }}
                  color="danger m-1"
                  outline
                >
                  Cancel <i className="bx bx-x-circle"></i>
                </Button>
                <Button
                  style={{ width: "100px" }}
                  type="submit"
                  color="success m-1"
                  outline
                >
                  Submit <i className="bx bx-check-circle"></i>
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>

        <Modal isOpen={show2} toggle={toggle12} centered>
          <ModalHeader toggle={toggle12}>Send Notification</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                sendNottoUser(e)
              }}
            >
              <div className="">
                <div className="mb-3">
                  <Label>
                    Type <span className="text-danger">*</span>
                  </Label>
                  <select
                    className="form-select"
                    required
                    name="notificationType"
                    value={message.notificationType}
                    onChange={e => {
                      messageChange(e)
                    }}
                  >
                    <option value="">Select</option>
                    <option value="Push_Notification">App Notification</option>
                    <option value="Whatsapp">Whatsapp</option>
                    <option value="SMS">SMS</option>
                  </select>
                </div>
                {message.notificationType == "Push_Notification" ? (
                  <>
                    <div className="mb-3">
                      <Label>
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="title"
                        value={message.title}
                        onChange={e => {
                          messageChange(e)
                        }}
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="mb-3">
                      <Label>
                        Message <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        name="descirption"
                        value={message.descirption}
                        onChange={e => {
                          messageChange(e)
                        }}
                        className="form-control"
                        placeholder="Enter Message"
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="text-end">
                <Button
                  type="button"
                  onClick={() => {
                    setshow2(false)
                  }}
                  style={{ width: "100px" }}
                  color="danger m-1"
                  outline
                >
                  Cancel <i className="bx bx-x-circle"></i>
                </Button>
                <Button
                  style={{ width: "100px" }}
                  type="submit"
                  color="success m-1"
                  outline
                >
                  Submit <i className="bx bx-check-circle"></i>
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Ventures
