import React,{useState, useEffect} from "react"
import { Link } from "react-router-dom"
import { Container, Card, CardBody, Col, Row, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { baseurl, imgUrl } from "Baseurls"


const BlogDetails = () => {
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const blogids = sessionStorage.getItem('blogid')
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const Get = () => {
    var token = datas
    const bodydata = {
      _id: blogids
    }
    axios
      .post(baseurl + 'blog/getByid', bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.blogData)
        setform1(res.data.doctor)
      })
  }

  useEffect(() => {
    Get()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Details" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          {/* <div className="text-center">
                           
                            <h4>Early Detection of Disease</h4>
                            <p className="text-muted mb-4">
                              <i className="mdi mdi-calendar me-1"></i> 10 Apr,
                              2020
                            </p>
                          </div>

                          <hr />
                          <div className="text-center">
                            <Row>
                              <Col sm={4}>
                                <div>
                                  <p className="text-muted mb-2">Categories</p>
                                  <h5 className="font-size-15">Blog</h5>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Date</p>
                                  <h5 className="font-size-15">10 Apr, 2020</h5>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Post by</p>
                                  <h5 className="font-size-15">
                                    Gilbert Smith
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr /> */}

                          <Row>
                            <Col lg="1" >
                              <img src={imgUrl + form1.avatar} style={{ width: "50px", height: "50px" }} className="rounded-circle border border-primary" />
                            </Col>
                            <Col lg="3">
                              <h5> {form1.name} </h5>
                              <p className="text-muted"> {form1.specialisation} </p>
                            </Col>
                            <Col className="text-end mt-3" lg="8">
                              <p className="text-muted">{form.date +','+ form.time }</p>
                            </Col>
                          </Row>

                          <div className="mt-2">
                            <img
                              src={imgUrl + form.image}
                              alt=""
                              style={{ width: "100%", height:"500px" }}
                              className="img-thumbnail mx-auto d-block"
                            />
                          </div>
                          <Row className="mt-3">
                            <Col md="6">
                              <h5>{form.heading}</h5>
                              <b>{form.specialisation}</b>
                            </Col>
                            <Col style={{ float: "right" }} md="6">
                              <Link style={{ float: "right" }} to="#" className="text-muted">
                                <i style={{ fontSize: "17px" }} className="bx bx-heart align-middle text-danger me-1"></i>{" "}
                                ({form.likesCount})Likes
                              </Link>
                            </Col>
                          </Row>


                          <div className="mt-2">
                            <div className="text-muted font-size-14"
                              dangerouslySetInnerHTML={{
                                __html: form.description,
                              }}
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogDetails
